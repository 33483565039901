import { useEffect, useState } from "react";
import { motion } from "framer-motion";

import { DefiniteLoader } from "../DefiniteLoader";
import { Modal } from "../modal/Modal";

import { intl } from "./PreselectorLoader.Intl";
interface PreselectorLoaderProps {
  open?: boolean;
  stage: number;
}

export const PreselectorLoader = ({
  open = false,
  stage,
}: PreselectorLoaderProps) => {
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    if (!open) {
      setSeconds(0);
    }

    const interval = setInterval(() => {
      setSeconds((prev) => prev + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [open]);

  if (!open) {
    return null;
  }

  const d1 = seconds % 4 === 0;
  const d2 = (seconds + 1) % 4 === 0;
  const d3 = (seconds + 2) % 4 === 0;
  const d4 = (seconds + 3) % 4 === 0;

  return (
    <Modal
      name="preselector-submitting"
      open={open}
      size="sm"
      closeable={false}
    >
      <motion.div
        className="p-8 m-4 text-white shadow bg-primary"
        animate={{
          borderTopLeftRadius: d4 ? "2rem" : "12rem",
          borderTopRightRadius: d3 ? "2rem" : "12rem",
          borderBottomRightRadius: d2 ? "2rem" : "12rem",
          borderBottomLeftRadius: d1 ? "2rem" : "12rem",
          transition: {
            type: "spring",
            stiffness: 100,
            duration: 0.5,
          },
        }}
      >
        <DefiniteLoader
          stages={[
            { label: "", progress: 0 },
            { label: intl.SORTING_INGREDIENTS, progress: [10, 20] },
            { label: intl.CALCULATING_CALORIES_0, progress: [20, 30] },
            { label: intl.CALCULATING_CALORIES_1, progress: [30, 40] },
            { label: intl.PUTTING_TOGETHER_MENU_0, progress: [40, 55] },
            { label: intl.PUTTING_TOGETHER_MENU_1, progress: [55, 100] },
            { label: "", progress: 100 },
          ]}
          current={stage}
          className="text-sm"
          inverted
        />
      </motion.div>
    </Modal>
  );
};
