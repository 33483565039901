import styled, { css } from "styled-components";
import { variables, media } from "../global-styles";
import { customBreakpoints } from "../constants/breakpoints";

import getWidth from "../helpers/getWidth";

const GridItem = styled.div.attrs({
  role: "listitem",
})`
  display: inline-block;
  width: 100%;
  padding-left: ${variables.gutter4Mobile};
  vertical-align: top;
  box-sizing: border-box;
  position: relative;

  // @media${customBreakpoints.onlySmallScreen} {
  //   padding-left: ${variables.gutter4SmallMobile}
  // }

  ${media.teen`
    padding-left: ${variables.gutter4};
  `};

  /*
   * Item widths
   */

  /* Base width */
  ${(props) =>
    props.base &&
    css`
      width: ${getWidth(props.base)};
    `};

  /* Infant width */
  ${(props) =>
    props.infant &&
    css`
      ${media.infant`
        width: ${getWidth(props.infant)};
      `};
    `};

  /* Baby width */
  ${(props) =>
    props.baby &&
    css`
      ${media.baby`
        width: ${getWidth(props.baby)};
      `};
    `};

  /* Child width */
  ${(props) =>
    props.child &&
    css`
      ${media.child`
        width: ${getWidth(props.child)};
      `};
    `};

  /* Teen width */
  ${(props) =>
    props.teen &&
    css`
      ${media.teen`
        width: ${getWidth(props.teen)};
      `};
    `};

  /* Adult width */
  ${(props) =>
    props.adult &&
    css`
      ${media.adult`
        width: ${getWidth(props.adult)};
      `};
    `};

  /* Elder width */
  ${(props) =>
    props.elder &&
    css`
      ${media.elder`
        width: ${getWidth(props.elder)};
      `};
    `};

  /*
   * Item push settings
   */

  /* Base push */
  ${(props) =>
    props.basePush &&
    css`
      margin-left: ${getWidth(props.basePush)};
    `};

  /* Infant push */
  ${(props) =>
    props.infantPush &&
    css`
      ${media.teen`
        margin-left: ${getWidth(props.infantPush)};
      `};
    `};

  /* Baby push */
  ${(props) =>
    props.babyPush &&
    css`
      ${media.teen`
        margin-left: ${getWidth(props.babyPush)};
      `};
    `};

  /* Child push */
  ${(props) =>
    props.childPush &&
    css`
      ${media.teen`
        margin-left: ${getWidth(props.childPush)};
      `};
    `};

  /* Teen push */
  ${(props) =>
    props.teenPush &&
    css`
      ${media.teen`
        margin-left: ${getWidth(props.teenPush)};
      `};
    `};

  /* Adult push */
  ${(props) =>
    props.adultPush &&
    css`
      ${media.adult`
        margin-left: ${getWidth(props.adultPush)};
      `};
    `};

  /* Elder push */
  ${(props) =>
    props.elderPush &&
    css`
      ${media.elder`
        margin-left: ${getWidth(props.elderPush)};
      `};
    `};

  /* Enable stickiness */
  ${(props) =>
    props.sticky &&
    css`
      position: sticky;
      top: 0px;
      backface-visibility: hidden;
      z-index: 3;
    `};

  /* Shorter left padding */
  ${(props) =>
    props.noLeftPadding &&
    css`
      padding-left: 0 !important;
    `};

  /* Shorter left padding */
  ${(props) =>
    props.shortLeftPadding &&
    css`
      padding-left: 10px !important;
    `};

  ${(props) =>
    props.bottomPadding &&
    css`
      padding-bottom: ${variables.gutter4Mobile};

      // @media${customBreakpoints.onlySmallScreen} {
      //   padding-bottom: ${variables.gutter4SmallMobile}
      // }

      ${media.teen`
      padding-bottom: ${variables.gutter4};
    `};
    `};

  ${(props) =>
    props.bottomPaddingNarrow &&
    css`
      padding-bottom: ${variables.gutter1Mobile};

      ${media.teen`
        padding-bottom: ${variables.gutter1};
      `};
    `};

  // This will not follow the normal breakpoints, so do not over use!
  ${(props) =>
    props.custom &&
    props.custom.map(
      (c) => css`
        @media (min-width: ${c.min}) and (max-width: ${c.max}) {
          width: ${getWidth(c.size)};
        }
      `,
    )};
`;

export default GridItem;
