import { PREFERENCE_IDS, PREFERENCE_TYPE_IDS } from "@chef/constants";
import { isEqualStrings } from "@chef/utils/equal";

interface IIsSomethingPreference {
  preferenceTypeId: string;
  preferenceId?: string;
  isAllergen?: boolean;
}
export const isConceptPreference = (preference: IIsSomethingPreference) => {
  return isEqualStrings(
    preference.preferenceTypeId,
    PREFERENCE_TYPE_IDS.CONCEPT,
  );
};

export const isTastePreference = (preference: IIsSomethingPreference) => {
  return isEqualStrings(preference.preferenceTypeId, PREFERENCE_TYPE_IDS.TASTE);
};

// This will be used to cover edge-cases on Singel-preference in Onesub
export const isSingelPreference = (preference: IIsSomethingPreference) => {
  if (!preference || !preference.preferenceId) {
    return false;
  }
  return isEqualStrings(preference.preferenceId, PREFERENCE_IDS.SINGEL_ONESUB);
};

export const isPreferencesAllergen = (
  preferenceIds: string[],
  preferences: IIsSomethingPreference[],
) => {
  const allergenIds = preferences
    .filter((p) => p.isAllergen)
    .map((p) => p.preferenceId);

  return preferenceIds.some((id) => allergenIds.includes(id));
};
