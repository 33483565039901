import styled, { css } from "styled-components";
import media from "../../../helpers/media";

const Title = styled.span`
  ${(props) =>
    props.standAloneCard &&
    css`
      font-size: ${(props) => props.theme.typography.fontSizeSmall};
      line-height: 17px;

      ${media.teen`
        font-size: inherit;
        line-height: inherit;
    `};

      span {
        font-size: ${(props) => props.theme.typography.fontSizeSmall};
        line-height: 17px;

        ${media.teen`
        font-size: inherit;
        line-height: inherit;
      `};
      }
    `};

  ${(props) =>
    props.noStandaloneAction &&
    css`
      text-align: left;
    `}
`;

export default Title;
