import styled, { css } from "styled-components";
import { variables, media } from "../global-styles";
import { pulsate } from "../helpers/animations";

const Image = styled.img`
  max-width: 100%;

  /* Full-width version */
  ${(props) =>
    props.full &&
    css`
      width: 100%;
      height: 100%;
    `};

  /* Small-icon version */
  ${(props) =>
    props.size &&
    css`
      width: ${props.size};
      height: ${props.size};
    `};

  /* Set min height */
  ${(props) =>
    props.minHeight &&
    css`
      min-height: ${props.minHeight};
    `};

  /* Small-icon version */
  ${(props) =>
    props.smallIcon &&
    css`
      width: 19px;
      height: 19px;
    `};

  /* Icon version */
  ${(props) =>
    props.icon &&
    css`
      width: 29px;
      height: 29px;
    `};

  /* Icon version */
  ${(props) =>
    props.mediumIcon &&
    css`
      width: 45px;
      height: 45px;
    `};

  /* Large Icon version */
  ${(props) =>
    props.largeIcon &&
    css`
      width: 60px;
      height: 60px;

      ${media.teen`
        margin-right: ${variables.gutter1};
      `};
    `};

  /* Large Icon version */
  ${(props) =>
    props.chefImage &&
    css`
      width: auto;
      height: 100%;
    `};

  ${(props) =>
    props.xl &&
    css`
      width: 100px;
      height: 80px;

      ${media.teen`
        margin-right: ${variables.gutter1};
      `};
    `};

  ${(props) =>
    props.portraitLarge &&
    css`
      ${media.teen`
        object-fit: cover;
        height: 26vw;
      `};
    `};

  ${(props) =>
    props.portraitSmall &&
    css`
      ${media.teen`
        object-fit: cover;
        height: 18vw;
      `};
    `};

  ${(props) =>
    props.cover &&
    css`
      object-fit: cover;
      object-position: center;
    `};

  ${(props) =>
    props.showGradient &&
    css`
      overflow: hidden;
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        width: 100%;
        background: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.65) 99%,
          rgba(0, 0, 0, 0.65) 100%
        );
        height: 50%;
      }
    `};
  ${(props) =>
    props.fullSVG &&
    css`
      width: 100%;
      height: auto;
    `};

  /* Animated version */
  ${(props) =>
    props.animated &&
    css`
        animation ${pulsate} 2s ease-in-out infinite both;
      `};

  ${(props) =>
    props.doubleMaxWidth &&
    css`
      max-width: 200%;
    `};

  ${(props) =>
    props.borderRadius50 &&
    css`
      border-radius: 50%;
    `};

  ${(props) =>
    props.objectFit &&
    css`
      object-fit: ${props.objectFit};
    `};

  ${(props) =>
    props.noMaxwidth &&
    css`
      max-width: none;
    `};

  ${(props) =>
    props.borderRadiusTop &&
    css`
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
    `};

  ${(props) =>
    props.borderRadius &&
    css`
      border-radius: ${variables.borderRadius};
    `};
`;

export default Image;
