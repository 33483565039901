import styled, { css } from "styled-components";

import { media } from "../../../global-styles";

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: ${(props) => props.theme.spacing.eight};
  background: ${(props) => props.theme.colors.light};

  ${media.teen`
      padding: ${(props) => props.theme.spacing.sixteen};
    `};

  ${(props) =>
    props.size === "highlighted" &&
    css`
      ${media.teen`
        width: 408px;
        height: fit-content;
        position: absolute;
        align-self: flex-end; 
        margin-left: -${(props) => props.theme.spacing.thirtyTwo};
        margin-top: -${(props) => props.theme.spacing.thirtyTwo};;
        padding: ${(props) => props.theme.spacing.twentyFour};
        z-index: 1;
      `};
      ${media.adult`
        margin-left: -${(props) => props.theme.spacing.forty};
        margin-top: -${(props) => props.theme.spacing.forty};
      `};
      ${media.elder`
        margin-left: -${(props) => props.theme.spacing.fiftySix};
        margin-top: -${(props) => props.theme.spacing.fiftySix};
      `};
    `};
`;

export default Footer;
