/**
 * @description Polls a function until it resolves or the timeout is reached
 * @param fn Function to be executed
 * @param interval Time between each execution
 * @param timeoutOrCondition Time before the function is rejected, or a condition to reject if true
 * @returns Promise that resolves when the function is successful or rejects when the timeout is reached
 */
export const poll = async <T>(
  fn: () => Promise<T>,
  interval: number,
  timeoutOrCondition:
    | number
    | ((rejectionMessage: string, retries: number) => boolean),
): Promise<T> => {
  let nRetries = 0;
  return new Promise((resolve, reject) => {
    const start = Date.now();
    const intervalId = setInterval(async () => {
      fn()
        .then((result) => {
          clearInterval(intervalId);
          resolve(result);
        })
        .catch((error: Error) => {
          if (typeof timeoutOrCondition === "number") {
            if (Date.now() - start > timeoutOrCondition) {
              clearInterval(intervalId);
              reject(error);
            }
          } else {
            if (timeoutOrCondition(error.message, nRetries++)) {
              clearInterval(intervalId);
              reject(error);
            }
          }
        });
    }, interval);
  });
};
