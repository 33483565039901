import { createApi } from "@reduxjs/toolkit/query/react";
import { query } from "../graphql/baseApi";

type Primitive = string | number | boolean | null;
type Traits = Record<string, Primitive>;

interface IGetTraitsResponse {
  traits: Traits;
  cursor: {
    url: string;
    has_more: boolean;
    next: string;
  };
}

interface IGetTraitResponse {
  trait: Primitive | Record<string, Primitive> | Primitive[];
}

export const personasApi = createApi({
  reducerPath: "personas",
  baseQuery: query,
  endpoints: (builder) => ({
    getTraits: builder.query<IGetTraitsResponse, void>({
      query: () => {
        return "/personas/traits";
      },
    }),
    getTrait: builder.query<IGetTraitResponse, string>({
      queryFn: async (trait, api) => {
        const { data } = (await api.dispatch(
          personasApi.endpoints.getTraits.initiate(),
        )) as { data?: IGetTraitsResponse };

        if (!data) {
          return { data: { trait: null } };
        }

        return { data: { trait: data.traits[trait] } };
      },
    }),
  }),
});

export const { useGetTraitsQuery, useGetTraitQuery } = personasApi;
