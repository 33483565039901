import { BRAND_NAME, TAXONOMY_IDS, TAXONOMY_TYPES } from "@chef/constants";
import { removeDuplicatesByKey } from "@chef/utils/helpers/removeDuplicates";
import { isEqualStrings } from "@chef/utils/equal";
import {
  OrderQuery,
  PickAndMixQuery,
  RecipeAndStepsQuery,
  RecipesByIdsQuery,
  RecipesByProductIdListQuery,
} from "../graphql/generated";

export const getRecipesFromPickAndMix = (args: {
  pickAndMix: PickAndMixQuery["pickAndMix"];
  productId: string;
}) => {
  const { pickAndMix, productId } = args;

  return (
    pickAndMix.find((pam) => isEqualStrings(pam.productId, productId))
      ?.recipes || null
  );
};

export const getRecipeFromPickAndMix = (args: {
  pickAndMix: PickAndMixQuery["pickAndMix"];
  productId: string;
  variationId: string;
}) => {
  const { pickAndMix, productId, variationId } = args;

  for (const pnm of pickAndMix) {
    if (!isEqualStrings(pnm.productId, productId)) {
      continue;
    }

    for (const recipe of pnm.recipes) {
      for (const portion of recipe.portions) {
        if (!isEqualStrings(portion.variationId, variationId)) {
          continue;
        }

        return recipe;
      }
    }
  }

  return null;
};

export const generateTags = (
  item:
    | PickAndMixQuery["pickAndMix"][number]["recipes"][number]
    | RecipeAndStepsQuery["recipeAndSteps"]
    | RecipesByProductIdListQuery["recipesByProductIdList"][number]["products"][number]["recipes"][number]
    | RecipesByIdsQuery["recipesByIds"][number]
    | OrderQuery["order"]["orderLines"][number]["recipes"][number],
) => {
  const {
    taxonomies = [],
    cookingTimeMin,
    cookingTimeMax,
    shelfLife: shelfLifeDays,
  } = item;

  const marketingTax = taxonomies.find(
    (t) => t.type === TAXONOMY_TYPES.MARKETING,
  );

  const campaignTax = taxonomies.find(
    (t) => t.type === TAXONOMY_TYPES.CAMPAIGN,
  );

  const specialFood = removeDuplicatesByKey(
    taxonomies
      .filter(
        (t) =>
          t.type === TAXONOMY_TYPES.SPECIALFOOD ||
          (t.type === TAXONOMY_TYPES.CATEGORY &&
            [
              TAXONOMY_IDS.GLUTENFREE,
              TAXONOMY_IDS.GLUTENFREE_OLD,
              TAXONOMY_IDS.LACTOSEFREE,
              TAXONOMY_IDS.LACTOSEFREE_OLD,
            ].includes(+t.taxonomyId as any)),
      )
      .map(({ name, taxonomyId }) => ({
        id: taxonomyId,
        text: name,
      })),
    "text",
  );

  const cookingTime =
    cookingTimeMax === cookingTimeMin
      ? {
          id: "cookingTime",
          text: cookingTimeMin,
        }
      : {
          id: "cookingTime",
          text: `${cookingTimeMin}-${cookingTimeMax}`,
        };

  const marketing = {
    id: marketingTax && marketingTax.name,
    text: marketingTax && marketingTax.name,
  };

  const campaign = {
    id: campaignTax && campaignTax.name,
    text: campaignTax && campaignTax.name,
  };

  let shelfLife: { id: string; text: string } | null = null;

  // Only show shelfLife on RetNemt
  if (BRAND_NAME === "RN") {
    shelfLife = {
      id: "shelfLife",
      text: intl.SHELF_LIFE(shelfLifeDays),
    };
  }

  return { cookingTime, marketing, campaign, specialFood, shelfLife };
};

const intl = {
  AMK: {
    SHELF_LIFE: (days: number) => `${days} dagers holdbarhet`,
  },
  GL: {
    SHELF_LIFE: (days: number) => `${days} dagers holdbarhet`,
  },
  LMK: {
    SHELF_LIFE: (days: number) => `${days} dagars hållbarhet`,
  },
  RN: {
    SHELF_LIFE: (days: number) => `${days} dages holdbarhed`,
  },
}[BRAND_NAME];
