import { useInterval } from "@mantine/hooks";
import { useEffect, useState } from "react";

import { clamp } from "@chef/utils/clamp";

import { CircularProgress } from "@chef/components";

interface Stage {
  label: React.ReactNode;
  progress: number | [number, number];
}

interface DefiniteLoaderProps {
  stages: Stage[];
  current: number;
  children?: React.ReactNode;
  inverted?: boolean;
  className?: string;
}

export const DefiniteLoader = ({
  stages,
  current,
  children,
  inverted,
  className,
}: DefiniteLoaderProps) => {
  const [progress, setProgress] = useState(0);

  const _current = clamp(current, 0, stages.length - 1);

  const currentStage = stages[_current];

  const interval = useInterval(() => {
    setProgress((amount) => {
      let next = 0;
      if (amount >= 0 && amount <= 20) {
        next = 15;
      } else if (amount >= 20 && amount <= 50) {
        next = 6;
      } else if (amount >= 50 && amount <= 80) {
        next = 3;
      } else if (amount >= 80 && amount <= 99) {
        next = 0.5;
      }

      return amount + next;
    });
  }, 250);

  useEffect(() => {
    setProgress(0);

    interval.start();

    return () => {
      interval.stop();
    };
  }, [current]);

  let p = 0;

  if (typeof currentStage.progress === "number") {
    p = currentStage.progress;
  } else {
    const [start, end] = currentStage.progress;

    p = start + (end - start) * (progress / 100);
  }

  return (
    <div className={className}>
      <CircularProgress
        progress={{
          current: p,
        }}
        inverted={inverted}
      >
        {!children && <div className="text-center">{currentStage.label}</div>}
      </CircularProgress>
      {children && <div className="text-center">{currentStage.label}</div>}
    </div>
  );
};
