import styled from "styled-components";

const NotificationDot = styled.span`
  position: absolute;
  top: 5px;
  right: 25px;
  display: flex;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  font-size: 11px;
  justify-content: center;
  color: white;
  background: ${(props) => props.theme.colors.warning};
`;

export default NotificationDot;
