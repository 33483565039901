import styled, { css } from "styled-components";

const CreditCard = styled.span`
  letter-spacing: 2px;
  display: inline-block;

  ${(props) =>
    props.border &&
    css`
      border: solid 2px ${props.theme.colors.semiDark};
      padding: 10px;
    `};
`;

export default CreditCard;
