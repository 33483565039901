import styled, { css } from "styled-components";
import media from "../helpers/media";

const Tag = styled.div`
  font-size: ${(props) => props.theme.typography.fontSizeGlobalMin};
  display: inline-block;
  color: ${(props) => props.theme.colors.text};
  background-color: ${(props) => props.theme.colors.lightBeige};
  padding: 0 17px;
  line-height: 36px;
  border: 2px solid ${(props) => props.theme.colors.lightBeige};
  border-radius: 2px;
  font-family: ${(props) => props.theme.typography.fontFamily};
  white-space: nowrap;

  ${(props) =>
    props.imageChip &&
    css`
      height: 30px;
      width: fit-content;
      line-height: 26px;
      display: flex;
      align-items: center;
      font-size: ${(props) => props.theme.typography.fontSizeTiny};
      background-color: ${(props) => props.theme.colors.light};
      padding: 0 8px;
      border: 0;
    `};

  /* Active variant */
  ${(props) =>
    props.active &&
    css`
      border-bottom: 2px solid ${props.theme.colors.primary};
    `};

  /* Inactive variant */
  ${(props) =>
    props.inactive &&
    css`
      // color: ${props.theme.colors.darkBeige};
      color: ${props.theme.colors.dark};
    `};
  ${(props) =>
    props.selectedColor &&
    css`
      background-color: ${props.theme.colors.primary};
      border: 2px solid ${props.theme.colors.primary};
      color: ${props.theme.colors.light};
    `};
  /* Small variant */
  ${(props) =>
    props.small &&
    css`
      height: 20px;
      width: max-content;
      font-size: ${(props) => props.theme.typography.fontSizeMini};
      display: flex;
      align-items: center;
      padding: 0 4px;
      // line-height: ${(props) => props.theme.typography.fontSizeMini};
      border-radius: 2px;
      font-family: ${(props) => props.theme.typography.fontFamily};
      white-space: nowrap;
      border: 0;

      p {
        font-size: ${(props) => props.theme.typography.fontSizeMini};
        line-height: ${(props) => props.theme.typography.fontSizeMini};
      }
    `};

  /* medium variant */
  ${(props) =>
    props.medium &&
    css`
      border-radius: 1px;
      padding: 0 7px;
      display: inline-flex;
      align-items: center;
      height: 30px;
    `};

  /* large variant */
  ${(props) =>
    props.large &&
    css`
      border-radius: 1px;
      padding: 0 7px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      height: 50px;

      ${media.teen`
        height: 60px;
        font-size: 20px;
      `};
    `};

  /* larger variant for mealcard and preptime chips */
  ${(props) =>
    props.modalLarge &&
    css`
      height: 24px;
      font-size: ${(props) => props.theme.typography.fontSizeTiny};
      line-height: 15px;
      padding: 0 8px;
      border-radius: 1px;
    `};

  /* Special variant */
  ${(props) =>
    props.special &&
    css`
      background: ${props.theme.colors.sennep};
      border-color: ${props.theme.colors.sennep};
    `};

  /* Disabled variant */
  ${(props) =>
    props.disabled &&
    css`
      color: ${props.theme.colors.black};
      background: none;
      border: solid 2px ${props.theme.colors.darkBeige};
    `};

  /* Set width */
  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `};

  ${(props) =>
    props.semiDark &&
    css`
      background-color: ${props.theme.colors.semiDark};
      border: none;
      color: ${props.theme.colors.light};
    `};

  ${(props) =>
    props.prepTime &&
    css`
      color: ${(props) => props.theme.colors.text};
      background-color: ${(props) => props.theme.colors.fiber};
    `};

  ${(props) =>
    props.category &&
    css`
      color: ${(props) => props.theme.colors.light};
      background-color: ${(props) => props.theme.colors.special};
    `};

  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `};

  /*  Background colors*/
  ${(props) => {
    if (props.tagColor) {
      switch (props.tagColor) {
        case "primary":
          return css`
            background-color: ${props.theme.colors.primary};
            border: solid 2px ${props.theme.colors.primary};
            color: ${(props) => props.theme.colors.light};
          `;
        case "secondary":
          return css`
            background-color: ${props.theme.colors.secondary};
            border: solid 2px ${props.theme.colors.secondary};
          `;
        case "sennep":
          return css`
            background-color: ${props.theme.colors.sennep};
            border: solid 2px ${props.theme.colors.sennep};
          `;
        case "light":
          return css`
            background-color: ${props.theme.colors.light};
            border: solid 2px ${props.theme.colors.light};
          `;
        case "special":
          return css`
            background-color: ${props.theme.colors.special};
            border: solid 2px ${props.theme.colors.special};
            color: ${(props) => props.theme.colors.light};
          `;
        case "christmas":
          return css`
            background-color: ${props.theme.colors.primaryDark};
            border: solid 2px ${props.theme.colors.primaryDark};
            color: ${(props) => props.theme.colors.light};
            img {
              filter: invert(1);
            }
          `;
        default:
          break;
      }
    }
    return "";
  }};
`;

const ButtonTag = Tag.withComponent("button");

export { Tag, ButtonTag };
