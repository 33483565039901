import { useController, UseControllerProps } from "react-hook-form";
import { useDispatch } from "react-redux";

import { Help } from "@chef/icons/small";
import { isConceptPreference } from "@chef/helpers";
import { getAttribute } from "@chef/state-management/helpers";
import { Checkbox } from "@chef/components";
import {
  setPreselectedRecipesModal,
  type Preferences,
} from "@chef/state-management";

interface PreferenceSelectListProps<T extends object>
  extends UseControllerProps<T> {
  preferences: Preferences[];
  className?: string;
  disabled?: boolean;
  isFixed?: boolean;
}

export const PreferenceSelectList = <T extends object>({
  control,
  preferences,
  name,
  className,
  disabled,
  isFixed,
}: PreferenceSelectListProps<T>) => {
  const { field } = useController({
    control,
    name,
  });

  const dispatch = useDispatch();

  const values = field.value as string[];

  const onChangePreferences = (preferenceId: string) => {
    let updatedValues;

    if (isFixed) {
      updatedValues = values.includes(preferenceId) ? [] : [preferenceId];
    } else {
      updatedValues = values.includes(preferenceId)
        ? values.filter((id) => id !== preferenceId)
        : [...values, preferenceId];
    }

    field.onChange(updatedValues);
  };

  const sortedPreferences = [...preferences].sort((a, b) => {
    const aPriority = +(getAttribute("sorting_priority", a) || 0);
    const bPriority = +(getAttribute("sorting_priority", b) || 0);

    return aPriority - bPriority;
  });

  return (
    <div className={className}>
      {sortedPreferences.map((p) => {
        const isSelected = values.includes(p.preferenceId);
        const isConcept = isConceptPreference(p);

        return (
          <>
            <div key={p.preferenceId}>
              <Checkbox
                variant="selector"
                state={name === "tastePreferenceIds" ? "crossed" : "checked"}
                onChange={() => onChangePreferences(p.preferenceId)}
                key={p.preferenceId}
                type="checkbox"
                value={p.preferenceId}
                disabled={disabled}
                checked={isSelected}
                id={`${p.preferenceTypeName}-${p.preferenceName}`}
              >
                <div className="flex items-center justify-between text-left">
                  <div>
                    <strong>{p.preferenceName}</strong>
                  </div>
                  {!isSelected && isConcept && (
                    <button
                      onClick={() => {
                        dispatch(
                          setPreselectedRecipesModal({
                            open: true,
                            preferenceId: [p.preferenceId],
                          }),
                        );
                      }}
                      type="button"
                      className="flex items-center justify-center w-10 h-10"
                    >
                      <Help
                        width={16}
                        height={16}
                        className="text-information"
                      />
                    </button>
                  )}
                </div>
              </Checkbox>
            </div>
          </>
        );
      })}
    </div>
  );
};
