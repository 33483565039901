import styled from "styled-components";

import { media } from "../../../global-styles";
import { imageSizes } from "../../../helpers/mixin";

const Image = styled.img`
  width: 100%;
  height: 266px;
  object-fit: cover;
  object-position: center bottom;

  ${media.teen`
    height: 540px;
  `};

  ${imageSizes};
`;

export default Image;
