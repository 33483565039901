export const ACCOUNT_STATUSES = {
  ACTIVE: 10,
  PAUSED: 20,
  PENDING: 30,
  BAD_PAYER: 31,
  POSTAL_AREA_CLOSED: 32,
  FRAUD: 33,
  LOCKED: 34,
  MISSING_KEY: 35,
  DELETED: 40,
} as const;

export type AccountStatus =
  typeof ACCOUNT_STATUSES[keyof typeof ACCOUNT_STATUSES];

export const CONSENT_IDS = {
  LOYALTY: "17fdbdb6-925a-499e-ae4f-1fb7ac983e1f",
  SMS: "17c0bd82-c810-464d-805e-3207c7e5ed77",
  TERMS_AND_CONDITIONS: "046f0c4f-1f63-44e4-a338-35c1a592819d",
  DATA_RETENTION: "867de632-f379-4679-821f-7e7ae941c70f",
  TELEMARKETING: "e60efe24-7295-4c3f-aeb0-8147a9e4c2eb",
  ELECTRONIC: "da7a384c-f8da-438e-b6b8-8f9a267f3a41",
  BI_ANNUALLY_ELECTRONIC: "40475459-2628-4ca2-b92c-a055526aeda2",
  PROFILING: {
    AMK: "9c312cf6-5bbc-4f05-97fb-b66371ac069c",
    GL: "29252e1e-42fa-4c85-a9c3-6cb7a1e1c3a0",
    LMK: "259CC930-33B3-4F65-854C-FE35C44F50DC",
    RN: "615C709C-65E4-4C56-BE56-51029D4B1341",
  },
  DATA_PROCESSING: {
    AMK: "873F5B19-C491-42AE-A34E-E877FB38DCC7",
    GL: "86ED0967-2F72-4FF0-8F59-94F79F8C8DA6",
    LMK: "EF325E4E-787B-4FFE-9F12-859B82DBD326",
    RN: "F4212F1B-1B20-46E8-8C1B-4CAFBDD091BD",
  },
  ALLERGEN_STORING: {
    AMK: "9570DBD4-FF1B-4D5C-A898-878EDDCE0E99",
    GL: "DE385A5C-25C7-4D11-B47C-A6E5E4A7F712",
    LMK: "45915DD7-F267-4312-B2A9-2A176AEC88D1",
    RN: "89854EE0-EF9A-45E2-A088-941F78BFA943",
  },
} as const;
