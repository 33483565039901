import React, { useState } from "react";
import clsx from "clsx";

import { v4 as uuidv4 } from "uuid";

import { Minus, Plus } from "@chef/icons/large";

import {
  Avatar,
  ButtonCircle,
  Button,
  Card,
  Currency,
  Disclosure,
  Hr,
} from "../components";

const intl = {
  no: {
    MAX_AMOUNT_SELECTED: "Maks antall valgt",
    SOLD_OUT: "Utsolgt",
    ALLERGY_DISCLAIMER:
      "Allergener er ment som veiledende informasjon og tar utgangspunkt i ingrediensene og ikke «spor av». Du må selv sjekke innholdet på varene du mottar i matkassen.",
    BASIS_INGREDIENT_ALLERGY_DISCLAIMER:
      "Basisvarene kan inneholde øvrige allergener",
    ADD_BUTTON_TEXT: "Legg til",
    SHOW_ALL_INGREDIENTS: "Vis alle ingredienser",
  },
  se: {
    MAX_AMOUNT_SELECTED: "Maximalt antal valt",
    SOLD_OUT: "Utsåld",
    ALLERGY_DISCLAIMER:
      'Allergener är avsedda som vägledande information och baseras på ingredienserna och inte på "spår av". Du måste själv kontrollera innehållet i varorna du får i kassen.',
    BASIS_INGREDIENT_ALLERGY_DISCLAIMER:
      "Basvarorna kan innehålla andra allergener",
    ADD_BUTTON_TEXT: "Lägg till",
    SHOW_ALL_INGREDIENTS: "Visa alla ingredienser",
  },
  dk: {
    MAX_AMOUNT_SELECTED: "Maks antal valgt",
    SOLD_OUT: "Udsolgt",
    ALLERGY_DISCLAIMER:
      'Allergener er beregnet som vejledende information og er baseret på ingredienserne og ikke "spor af". Du skal selv kontrollere indholdet af de varer, du modtager i kassen.',
    BASIS_INGREDIENT_ALLERGY_DISCLAIMER:
      "Basisvarerne kan indeholde andre allergener",
    ADD_BUTTON_TEXT: "Tilføje",
    SHOW_ALL_INGREDIENTS: "Vis alle ingredienser",
  },
};

const IngredientsGrid = ({ language, ingredients, className }) => {
  const [expanded, setExpanded] = useState(false);

  const { SHOW_ALL_INGREDIENTS } = intl[language];

  const INITIAL_INGREDIENTS_LIMIT = 9;

  const handleExpand = (e) => {
    e.stopPropagation();
    setExpanded(true);
  };

  return (
    <div
      className={clsx("grid gap-2 md:gap-3 xl:gap-4 grid-cols-3", className)}
    >
      {ingredients
        .slice(0, expanded ? ingredients.length : INITIAL_INGREDIENTS_LIMIT)
        .map(({ Image, name, portionAmount }) => (
          <div key={name}>
            <div className="flex justify-center p-2 border-2 border-solid rounded-sm border-grey-2">
              <Image className="w-full h-full" />
            </div>

            <div className="mt-1 text-sm line-clamp-2">
              <span className="hyphenate">{name}</span>
            </div>
            <div className="text-xs text-grey-1 whitespace-nowrap">
              {portionAmount}
            </div>
          </div>
        ))}
      <div
        className={clsx(
          "col-span-3",
          (expanded || ingredients.length <= INITIAL_INGREDIENTS_LIMIT) &&
            "hidden",
        )}
      >
        <Button aria-expanded={expanded} onClick={handleExpand} tiny full>
          {SHOW_ALL_INGREDIENTS}
        </Button>
      </div>
    </div>
  );
};

const IngredientsList = ({ ingredients, className }) => {
  return (
    <ul className={className}>
      {ingredients.map(({ Image, name, portionAmount }) => (
        <li
          key={`${name}-${uuidv4()}`}
          className="grid items-center grid-cols-6"
        >
          <div className="flex items-center col-span-4">
            <div className="min-w-max">
              <Image className="w-10 h-10" />
            </div>

            <span className="ml-2 text-sm break-all">{name}</span>
          </div>

          <div className="col-span-2">{portionAmount}</div>
        </li>
      ))}
    </ul>
  );
};
IngredientsList.propTypes = IngredientsGrid.propTypes;

const MealCardDetailed = ({
  language = "no",
  className,
  title,
  quantity,
  maxQuantity,
  onCardClick,
  onAddMeal,
  onRemoveMeal,
  price,
  tags,
  Image,
  mealShortDescription,
  mealSize = "",
  mealDescription,
  allergies,
  ingredients,
}) => {
  const {
    MAX_AMOUNT_SELECTED,
    ALLERGY_DISCLAIMER,
    BASIS_INGREDIENT_ALLERGY_DISCLAIMER,
    ADD_BUTTON_TEXT,
    SOLD_OUT,
  } = intl[language];

  const onAddMealCallback = (event) => {
    event.stopPropagation();
    if (onAddMeal) {
      onAddMeal(event);
    }
  };

  const onRemoveMealCallback = (event) => {
    event.stopPropagation();
    if (onRemoveMeal) {
      onRemoveMeal(event);
    }
  };

  return (
    <Card
      noPadding
      className={clsx(
        "p-4 md:p-10 h-full flex flex-col justify-between",
        className,
      )}
      onClick={onCardClick}
      {...(onCardClick
        ? { role: "button", tabIndex: 0, "aria-haspopup": "true" }
        : {})}
    >
      <div>
        <div className="flex justify-between">
          <div>
            <strong className="block mb-1 mr-4 text-lg md:text-xl">
              {title}
            </strong>
            <strong>
              <Currency language={language}>{+price}</Currency>
            </strong>
          </div>

          <div className="flex flex-col items-end">
            <div className="flex items-center h-12">
              {quantity === 0 ? (
                <Button
                  IconLeft={Plus}
                  onClick={onAddMealCallback}
                  disabled={maxQuantity === 0}
                  outlined
                >
                  {ADD_BUTTON_TEXT}
                </Button>
              ) : (
                <>
                  <ButtonCircle
                    className="h-9 w-9 md:h-10 md:w-10"
                    Icon={Minus}
                    onClick={onRemoveMealCallback}
                    disabled={quantity === 0}
                  />
                  <span className="px-2">{quantity}</span>
                  <ButtonCircle
                    className="h-9 w-9 md:h-10 md:w-10"
                    Icon={Plus}
                    disabled={quantity >= maxQuantity}
                    onClick={onAddMealCallback}
                    primary
                  />
                </>
              )}
            </div>

            <div
              className={clsx(
                "text-xs text-grey-1 mt-1",
                quantity < maxQuantity && "hidden",
              )}
            >
              {maxQuantity === 0 ? SOLD_OUT : MAX_AMOUNT_SELECTED}
            </div>
          </div>
        </div>

        <div
          className={clsx(
            "flex gap-2 mt-1 mb-4 md:mb-6",
            quantity < maxQuantity && "mt-4",
          )}
        >
          {tags}
        </div>

        <div className="grid grid-cols-1 lg:gap-4 lg:grid-cols-2">
          <div className="flex flex-col">
            <div className="mt-4 md:mt-0">
              <Image className="rounded-sm" />
            </div>

            <div className="mt-4 md:mt-2">{mealShortDescription}</div>
            <div className="text-sm text-grey-1">{mealSize}</div>
            <p className="order-first mt-2 text-sm md:order-none">
              {mealDescription}
            </p>
          </div>

          <div className="hidden lg:block">
            <IngredientsGrid language={language} ingredients={ingredients} />
          </div>
        </div>

        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <div className="block lg:hidden" onClick={(e) => e.stopPropagation()}>
          <Hr className="mt-6 mb-6" />
          <Disclosure Title={() => <strong>Hva får du med i kassa?</strong>}>
            <IngredientsList ingredients={ingredients} />
          </Disclosure>
          <Hr className="mt-6" />
        </div>
      </div>

      <div>
        <span className="block mt-6 mb-2">Allergener</span>
        <ul className="flex flex-wrap gap-6">
          {allergies.map((allergy) => (
            <li key={allergy} className="flex items-center">
              <Avatar text={allergy} className="mr-2" />

              <span>{allergy}</span>
            </li>
          ))}
        </ul>

        <p className="block my-2 text-xs text-grey-1">{ALLERGY_DISCLAIMER}</p>

        <p className="block text-sm">{BASIS_INGREDIENT_ALLERGY_DISCLAIMER}</p>
      </div>
    </Card>
  );
};

export default MealCardDetailed;
