import styled, { css } from "styled-components";

import { media } from "../global-styles";

const Flex = styled.div`
  display: flex;
  flex-direction: row;

  /* Full-width version */
  ${(props) =>
    props.full &&
    css`
      width: 100%;
    `};

  ${(props) =>
    props.fullHeight &&
    css`
      height: 100%;
    `};

  /* Align items settings */
  ${(props) =>
    props.alignItems &&
    css`
      align-items: ${props.alignItems};
    `};

  /* Justify content settings */
  ${(props) =>
    props.justifyContent &&
    css`
      justify-content: ${props.justifyContent};
    `};

  /* Justify content settings */
  ${(props) =>
    props.column &&
    css`
      flex-direction: column;
    `};

  /* Wrap settings */
  ${(props) =>
    props.wrap &&
    css`
      flex-wrap: wrap;
    `};

  ${(props) =>
    props.flex &&
    css`
      flex: 0;
    `};

  /* Use spacing from the theme, should be uiHelper */
  ${(props) =>
    props.padding &&
    css`
      padding: ${(props) =>
        props.theme.spacing[
          Object.keys(props.theme.spacing).filter(
            (space) => space === props.padding,
          )[0]
        ] || 0};
    `};

  ${(props) =>
    props.responsive &&
    css`
      flex-direction: column;
      ${media.teen`
        flex-direction: row;
      `};
    `};

  ${(props) =>
    props.inline &&
    css`
      display: inline-flex;
    `};
`;

export default Flex;
