import Link from "next/link";
import { useEffect, useState } from "react";
import { Button, Card, Input } from "@chef/components";
import { WEEKDAY, BRAND_PATHS } from "@chef/constants";
import { formatTimeInterval } from "@chef/helpers";
import { ChevronRight, Time } from "@chef/icons/small";
import {
  TimeblocksQuery,
  useLazyTimeblocksQuery,
} from "@chef/state-management";
import { isEmptyArray } from "@chef/utils/array";
import { intl } from "./DeliveryChecker.Intl";
import { ContactForm } from "./ContactForm";

interface DeliveryCheckerProps {
  showContactForm?: boolean;
  showCTA?: boolean;
}

interface ResultsProps {
  postalCode?: string;
  timeblocksData?: TimeblocksQuery["timeblocksData"];
  isLoading?: boolean;
  showContactForm?: boolean;
  showCTA?: boolean;
}

const groupTimeblocksDataByWeekday = (
  timeblocksData: TimeblocksQuery["timeblocksData"],
) => {
  if (!timeblocksData) {
    return [];
  }

  return timeblocksData.timeblocks.reduce((acc, timeblock) => {
    const weekday = timeblock.weekday;

    if (!acc[weekday]) {
      acc[weekday] = [];
    }

    acc[weekday].push(timeblock);

    return acc;
  }, [] as TimeblocksQuery["timeblocksData"]["timeblocks"][]);
};

const Results = ({
  showContactForm,
  showCTA,
  postalCode,
  timeblocksData,
  isLoading,
}: ResultsProps) => {
  if (!postalCode || isLoading) {
    return null;
  }

  if (!timeblocksData || isEmptyArray(timeblocksData.timeblocks)) {
    return (
      <Card className="flex flex-col gap-4 bg-errorBG" noShadow transparent>
        <h2>
          <strong>{intl.SORRY}</strong>
        </h2>

        <p>{intl.WE_CANNOT_DELIVER_TO(postalCode)}.</p>

        {showContactForm && (
          <>
            <hr className="mt-6 mb-4" />
            <ContactForm postalCode={postalCode} />
          </>
        )}
      </Card>
    );
  }

  return (
    <Card
      className="flex flex-col gap-8 px-4 py-6 md:px-10 md:py-10 bg-grey-3"
      noShadow
      transparent
      noPadding
    >
      <h2>
        <strong>{intl.OF_COURSE_WE_DELIVER_TO(timeblocksData.city)}</strong>
      </h2>

      <dl className="flex flex-col gap-6">
        {groupTimeblocksDataByWeekday(timeblocksData).map(
          (timeblocks, weekday) => (
            <div key={weekday} className="flex items-center gap-4">
              <Time className="w-5 h-5" />
              <div className="flex flex-col">
                <dl>
                  <strong>{intl.WEEKDAYS[weekday as WEEKDAY]}</strong>
                </dl>
                <dd>
                  {timeblocks
                    .map((timeblock) =>
                      formatTimeInterval(
                        timeblock.from,
                        timeblock.to,
                        "h",
                        " - ",
                      ),
                    )
                    .join(", eller ")}
                </dd>
              </div>
            </div>
          ),
        )}
      </dl>

      {showCTA && (
        <div className="flex justify-center">
          <Link href={BRAND_PATHS.SIGNUP_HREF} legacyBehavior>
            <Button IconRight={ChevronRight} primary>
              {intl.GET_STARTED}
            </Button>
          </Link>
        </div>
      )}
    </Card>
  );
};

export const DeliveryChecker = ({
  showContactForm = true,
  showCTA = true,
}: DeliveryCheckerProps) => {
  const [postalCode, setPostalCode] = useState("");

  const [timeblocksQuery, { data, originalArgs, isFetching }] =
    useLazyTimeblocksQuery();

  useEffect(() => {
    if (postalCode.length === intl.POSTAL_CODE_LENGTH) {
      timeblocksQuery({ postalCode });
    }
  }, [postalCode, timeblocksQuery]);

  return (
    <div>
      <Input
        name="postalCode"
        type="text"
        inputMode="numeric"
        label={intl.INPUT_YOUR_POSTAL_CODE}
        value={postalCode}
        onChange={(e) => setPostalCode(e.target.value)}
        disabled={isFetching}
        helperText={isFetching ? intl.CHECKING_POSTAL_CODE : undefined}
        maxLength={intl.POSTAL_CODE_LENGTH}
        className="mb-4"
      />
      <Results
        postalCode={originalArgs?.postalCode}
        timeblocksData={data?.timeblocksData}
        isLoading={isFetching}
        showContactForm={showContactForm}
        showCTA={showCTA}
      />
    </div>
  );
};
