import { ActionCreatorWithoutPayload } from "@reduxjs/toolkit";
import { api, tags } from "../../graphql/api";

import {
  IBillingAgreementArgs,
  ICreateAddressArgs,
  ICreatedAddress,
  IDeviation,
  IDeviationArgs,
  IPersonalizeDeviationArgs,
  UpdateAddressArgs,
  IUpdateContactPreferencesArgs,
  ICreateOrUpdateSchedulerArgs,
  ISubmitCouponArgs,
  IAddOrUpdateExternalPartnerArgs,
  IAddOrUpdateExternalPartner,
  UpdateBillingAgreementAddonsArgs,
  ISubmitGiftcardArgs,
  ICreateOrUpdateMultipleSchedulersArgs,
} from "./types";

// used as a substitute for tags on slices
const endpointsToCallOnUpdateBilling: ActionCreatorWithoutPayload<string>[] =
  [];
export const addResetEndpointOnUpdateBilling = (
  endpoint: ActionCreatorWithoutPayload<string>,
) => {
  endpointsToCallOnUpdateBilling.push(endpoint);
};

const endpointsToCallOnUpdateCalendar: ActionCreatorWithoutPayload<string>[] =
  [];
export const addResetEndpointOnUpdateCalendar = (
  endpoint: ActionCreatorWithoutPayload<string>,
) => {
  endpointsToCallOnUpdateBilling.push(endpoint);
};

export const billingApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createOrUpdateDeviation: builder.mutation<IDeviation, IDeviationArgs>({
      query: (args) => ({
        url: "/billing/createOrUpdateDeviation",
        method: "POST",
        body: { weekNr: `${args.Week}${args.Year}`, ...args },
      }),
      invalidatesTags: [tags.calendar],
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        await queryFulfilled;

        for (const endpoint of endpointsToCallOnUpdateCalendar) {
          dispatch(endpoint());
        }
      },
    }),

    createOrUpdateScheduler: builder.mutation<
      void,
      ICreateOrUpdateSchedulerArgs
    >({
      query: (args) => ({
        url: "/billing/createOrUpdateScheduler",
        method: "POST",
        body: args,
      }),
      invalidatesTags: [tags.calendar],
    }),

    createOrUpdateMultipleSchedulers: builder.mutation<
      void,
      ICreateOrUpdateMultipleSchedulersArgs
    >({
      query: ({ schedulers }) => ({
        url: "/billing/createOrUpdateMultipleSchedulers",
        method: "POST",
        body: {
          schedulers,
        },
      }),
      invalidatesTags: [tags.calendar],
    }),

    updateBillingAgreement: builder.mutation<null, IBillingAgreementArgs>({
      query: (args) => {
        const {
          _suppressNotification: _,
          _suppressEndpointReload: __,
          ...rest
        } = args;

        return {
          url: "/billing/updateBillingAgreement",
          method: "POST",
          body: { ...rest },
        };
      },
      invalidatesTags: [tags.billing],
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        if (args._suppressEndpointReload) {
          return;
        }
        await queryFulfilled;

        for (const endpoint of endpointsToCallOnUpdateBilling) {
          dispatch(endpoint());
        }
      },
    }),

    deleteBillingAgreement: builder.mutation<null, null>({
      query: () => ({
        url: "/billing/deleteBillingAgreement",
        method: "DELETE",
      }),
    }),

    generatePersonalizedDeviations: builder.mutation<
      {
        CorrelationId: string;
      },
      IPersonalizeDeviationArgs
    >({
      query: (args) => ({
        url: "/billing/generatePersonalizedDeviations",
        method: "POST",
        body: args,
      }),
    }),

    createAddress: builder.mutation<ICreatedAddress, ICreateAddressArgs>({
      query: (args) => ({
        url: "/billing/createAddress",
        method: "POST",
        body: args,
      }),
      invalidatesTags: [tags.address],
    }),

    updateAddress: builder.mutation<null, UpdateAddressArgs>({
      query: (args) => ({
        url: "/billing/updateAddress",
        method: "POST",
        body: args,
      }),
      invalidatesTags: [tags.address],
    }),

    updateContactPreferences: builder.mutation<
      null,
      IUpdateContactPreferencesArgs
    >({
      query: (args) => ({
        url: "/billing/updateContactPreferences",
        method: "POST",
        body: args,
      }),
      invalidatesTags: [tags.contactPreferences],
    }),

    submitCoupon: builder.mutation<void, ISubmitCouponArgs>({
      query: (args) => ({
        url: "/billing/submitCoupon",
        method: "POST",
        body: args,
      }),
      invalidatesTags: [tags.discount],
    }),

    submitGiftcard: builder.mutation<void, ISubmitGiftcardArgs>({
      query: (args) => ({
        url: "/billing/submitGiftcard",
        method: "POST",
        body: args,
      }),
      invalidatesTags: [tags.giftcard],
    }),

    addOrUpdateExternalPartner: builder.mutation<
      IAddOrUpdateExternalPartner,
      IAddOrUpdateExternalPartnerArgs
    >({
      query: (args) => ({
        url: "/billing/addOrUpdateExternalPartner",
        method: "POST",
        body: args,
      }),
      invalidatesTags: [tags.externalPartner],
    }),

    updateBillingAgreementAddons: builder.mutation<
      null,
      UpdateBillingAgreementAddonsArgs[]
    >({
      query: (args) => ({
        url: "/billing/updateBillingAgreementAddons",
        method: "POST",
        body: args,
      }),
      invalidatesTags: [tags.billing],
    }),
    preselectorJobStatus: builder.query<any, { correlationId: string }>({
      query: ({ correlationId }) => ({
        url: `/preselector/job/${correlationId}/status`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
  }),
});

export const {
  useCreateOrUpdateDeviationMutation,
  useUpdateBillingAgreementMutation,
  useDeleteBillingAgreementMutation,
  useGeneratePersonalizedDeviationsMutation,
  useCreateAddressMutation,
  useUpdateAddressMutation,
  useUpdateContactPreferencesMutation,
  useCreateOrUpdateSchedulerMutation,
  useCreateOrUpdateMultipleSchedulersMutation,
  useSubmitCouponMutation,
  useSubmitGiftcardMutation,
  useAddOrUpdateExternalPartnerMutation,
  useUpdateBillingAgreementAddonsMutation,
  useLazyPreselectorJobStatusQuery,
} = billingApi;

export * from "./types";
export * from "./utils";

export * from "./session";
