/**
 * Baseline number.
 */

const variables = {
  siteWidth: "1280px",

  /**
   * gutters (Horizontal)
   */

  gutter1SmallMobile: "8px",
  gutter2SmallMobile: "16px",
  gutter3SmallMobile: "20px",
  gutter4SmallMobile: "15px",

  gutter1Mobile: "8px",
  gutter2Mobile: "16px",
  gutter3Mobile: "20px",
  gutter4Mobile: "16px",

  gutter1: "8px",
  gutter2: "16px",
  gutter3: "30px",
  gutter4: "40px",

  /**
   * spacing (Vertical)
   */

  spacing1Mobile: "8px",
  spacing2Mobile: "16px",
  spacing3Mobile: "20px",
  spacing4Mobile: "40px",
  spacing5Mobile: "60px",
  spacingSectionMobile: "40px",

  spacing1: "8px",
  spacing2: "16px",
  spacing3: "32px",
  spacing4: "40px",
  spacing5: "80px",
  spacingSection: "150px",

  /**
   * Various
   */
  buttonPadding: "0.7em",
  borderWidth: "2px",
  boxShadow:
    "0px 4px 12px rgba(53, 55, 58, 0.08), 0px 2px 2px rgba(0, 0, 0, 0.08)",
  borderRadius: "2px",
  headerHeightMobile: "5rem",
  headerHeight: "5.8rem",

  backgroundColor: "#FAFAF9",

  overlayDefaultZindex: "6",
  overlayButtonDefaultZindex: "7",
  overlayUnderTabZindex: "3",
  overlayButtonUnderTabZindex: "4",

  colors: {
    gray10: "rgba(216, 216, 216, 0.1)",
  },
  typography: {
    fontFamily: "Brown",
  },
  spacing: {
    0: "0px",
    1: "4px",
    2: "8px",
    3: "12px",
    4: "16px",
    5: "24px",
    6: "32px",
    7: "40px",
    8: "56px",
  },

  screen: {
    small: `(min-width: 320px)`,
    medium: `(min-width: 768px)`,
    large: `(min-width: 1024px)`,
    xLarge: `(min-width: 1440px)`,
    smallMax: `(max-width: 319px)`,
    mediumMax: `(max-width: 767px)`,
    largeMax: `(max-width: 1023px)`,
    xLargeMax: `(max-width: 1439px)`,
  },

  fontSize: {
    h1: {
      desktop: "48px",
      mobile: "32px",
    },
    h2: {
      desktop: "32px",
      mobile: "24px",
    },
    h3: {
      desktop: "24px",
      mobile: "18px",
    },
    caption: "10px",
    small: "12px",
    regular: "14px",
    large: "16px",
    larger: "18px",
  },
};

export default variables;
