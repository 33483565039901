import { AppProps } from "next/app";
import { ThemeProvider } from "styled-components";
import "core-js/stable/array/flat-map";
import "core-js/stable/array/flat";
import "@formatjs/intl-numberformat/polyfill";
import "@formatjs/intl-numberformat/locale-data/no";
import "intersection-observer";
import "global.css";
import { PropsWithChildren, useEffect } from "react";

import { Notifications } from "@chef/feature-notifications";
import { Datadog } from "@chef/feature-datadog";
import { DebugProvider } from "@chef/feature-debug";
import { MobileAppProvider } from "@chef/feature-mobile-app";
import { DiscountsProvider } from "@chef/state-management/components";
import { TrackingProvider } from "@chef/feature-tracking";
import { storeWrapper } from "@chef/state-management";
import { RecipeModal } from "@chef/feature-recipe";
import { useOutlineListener } from "@chef/hooks";
import PreviewProvider from "@chef/feature-sanity/PreviewProvider";
import { gtFix } from "@chef/utils/patch";

import TabBar from "../components/TabBar";
import themes from "../constants/themes";
import GlobalStyle from "../global-styles";
import Loader from "../components/Loader";

gtFix();

const Providers = ({ children }: PropsWithChildren) => {
  return (
    <MobileAppProvider>
      <TrackingProvider>
        <DiscountsProvider>
          <ThemeProvider theme={themes["default"]}>
            <Datadog />
            <Notifications position="top-center" />
            <DebugProvider>{children}</DebugProvider>
          </ThemeProvider>
        </DiscountsProvider>
      </TrackingProvider>
    </MobileAppProvider>
  );
};

const appItems = [
  {
    text: "Middager",
    shortName: "Middager",
    path: "/app/min-matkasse",
    icon: "middag",
    iconSize: {
      width: "19.97px",
      height: "19.68px",
    },
  },
  {
    text: "Leveringskalender",
    shortName: "Kalender",
    path: "/app/leveringer",
    icon: "kalender",
    iconSize: {
      width: "19.97px",
      height: "18.9px",
    },
  },
  {
    text: "Min Konto",
    shortName: "Konto",
    path: "/app/min-konto",
    specialHeaderPath: "/app/min-konto/abonnement",
    icon: "konto",
    iconSize: {
      width: "25.47px",
      height: "19.7px",
    },
  },
  {
    text: "Verv en venn",
    shortName: "Verv",
    path: "/app/min-konto/vervenvenn",
    icon: "inviter",
    iconSize: {
      width: "21.97px",
      height: "19.7px",
    },
  },
];

function GodtlevertApp({ pageProps, Component, router }: AppProps) {
  useOutlineListener();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      localStorage.removeItem("token");
    }
  }, []);

  if (router.pathname.startsWith("/studio")) {
    return <Component {...pageProps} />;
  }

  return (
    <PreviewProvider draftMode={pageProps.draftMode}>
      <Providers>
        <div className="flex flex-col min-h-screen">
          <Component {...pageProps} />
          <TabBar items={appItems} />
          <RecipeModal Loader={Loader} />

          <GlobalStyle />
        </div>
      </Providers>
    </PreviewProvider>
  );
}

export default storeWrapper.withRedux(GodtlevertApp);
