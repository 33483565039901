import clsx from "clsx";

import { Button, ButtonCircle } from "@chef/components";
import { useTrack } from "@chef/feature-tracking";
import { showNotification } from "@chef/state-management";
import { Share } from "@chef/icons/small";
import { BRAND_NAME } from "@chef/constants";

import { intl } from "./ShareButton.Intl";

type ShareButtonProps = (
  | {
      href?: never;
      text?: never;
      onClick: () => Promise<{ href: string; text?: string }>;
    }
  | {
      onClick?: never;
      href: string;
      text?: string;
    }
) & {
  isLoading?: boolean;
  variant?: "descriptive" | "default";
};

export const ShareButton = ({
  variant = "default",
  isLoading,
  href,
  text,
  onClick,
}: ShareButtonProps) => {
  const track = useTrack();

  const handleShare = async (href: string, text?: string) => {
    {
      try {
        const title = text ?? href;
        if (navigator.canShare?.({ title, text, url: href })) {
          await navigator.share({ title, text, url: href });

          track("linkShared", {
            href: href,
            affiliation: "Frontend process",
            method: "context-share",
            title,
          });
        } else {
          await navigator.clipboard.writeText(href);

          track("linkShared", {
            href: href,
            affiliation: "Frontend process",
            method: "copy-to-clipboard",
            title,
          });

          showNotification({
            type: "success",
            message: intl.LINK_COPIED,
          });
        }
      } catch (error) {
        console.error("Error copying link", error);
      }
    }
  };

  if (variant === "default") {
    return (
      <ButtonCircle
        Icon={() => <Share className="w-5 h-5 mb-1 stroke-2" />}
        onClick={async () => {
          if (href) {
            handleShare(href, text);
          } else if (onClick) {
            const { href, text } = await onClick();
            handleShare(href, text);
          }
        }}
        loading={isLoading}
        className="bg-white hover:bg-white"
        outlined
      />
    );
  }

  return (
    <Button
      onClick={async () => {
        if (href) {
          handleShare(href, text);
        } else if (onClick) {
          const { href, text } = await onClick();
          handleShare(href, text);
        }
      }}
      loading={isLoading}
      className="bg-white hover:bg-white whitespace-nowrap"
      small
      outlined
    >
      <span className="self-auto mr-2 ">{intl.SHARE}</span>
      <Share
        className={clsx(
          ["GL", "AMK"].includes(BRAND_NAME) ? "mb-1.5" : "mb-1.25",
          "w-5 h-5  stroke-2",
        )}
      />
    </Button>
  );
};
