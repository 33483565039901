import styled, { css } from "styled-components";
import { variables, media } from "../global-styles";

const Section = styled.section`
  /* Faded variant */

  ${(props) =>
    props.gray &&
    css`
      background: ${props.theme.colors.lightBeige};
    `};

  ${(props) =>
    props.lightestGray &&
    css`
      background: ${props.theme.colors.lightBeige};
    `};

  ${(props) =>
    props.outlined &&
    css`
      border-top: 1.5px solid ${props.theme.colors.fiber};
      border-bottom: 1.5px solid ${props.theme.colors.fiber};
    `};

  /* Footer grey variant */
  ${(props) =>
    props.lightBeige &&
    css`
      background: ${props.theme.colors.lightBeige};
    `};

  /* Primary primary variant */
  ${(props) =>
    props.primary &&
    css`
      background: ${props.theme.colors.primary};
      color: white !important;
    `};

  /* Sennep Sennep variant */
  ${(props) =>
    props.sennep &&
    css`
      background: ${props.theme.colors.sennep};
    `};

  ${(props) =>
    props.borderRadius &&
    css`
      border-radius: ${props.theme.general.cardBorderRadius};
    `};
  ${(props) =>
    props.minHeight &&
    css`
      min-height: 80vh:
      `};

  ${(props) =>
    props.backgroundImage &&
    css`
      background-image: url(${props.backgroundImage});
      background-size: cover;
      background-position: center;
    `};

  ${(props) =>
    props.gradient &&
    css`
      overflow: hidden;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        width: 100%;
        background: rgba(0, 0, 0, 0.6);
        height: 100%;
      }
    `};

  ${(props) =>
    props.sesam &&
    css`
      background-color: ${props.theme.colors.sesam};
    `};

  ${(props) =>
    props.fiber &&
    css`
      background-color: ${props.theme.colors.fiber};
    `};

  ${(props) =>
    props.gradientBg &&
    css`
      background: linear-gradient(
        180deg,
        #fafaf9 0%,
        #f4f4f1 43.52%,
        #e9e5e2 100%
      );
    `};

  ${(props) =>
    props.hideOverflow &&
    css`
      overflow: hidden;
    `};

  /* Top marign */
  ${(props) =>
    props.sectionSpacing &&
    css`
      margin-top: ${variables.spacingSectionMobile};
      ${media.teen`
          margin-top: ${variables.spacingSection};
        `};
    `};

  ${(props) =>
    props.light &&
    css`
      background-color: ${props.theme.colors.light};
    `};

  ${(props) =>
    props.borderedBoxShadow &&
    css`
      border: 1.5px solid ${props.theme.colors.kull};
      box-shadow: ${variables.boxShadow};
    `};
`;

export default Section;
