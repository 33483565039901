/* All themes */

const themes = {
  default: {
    general: {
      buttonBorderRadius: "30px",
      buttonBorderRadiusTiny: "2px",
      cardBorderRadius: "2px",
      selectBorderRadius: "2px",
      minButtonHeight: "58px",
      buttonTextAlign: "center",
      drawerBg: "#fff8f5",
      overlayBg: "#fff8f5",
      overlayFg: "#767271",
    },
    colors: {
      /* Black and white */
      black: "#000000",
      dark: "#1d1c1a",
      semiDark: "#6C6A68",
      somewhatDark: "#898989",
      lighterGrey: "#FAFAF9",
      light: "#ffffff",

      /* Main colors */
      primary: "#FF604B",
      primaryDark: "#A33729",
      primaryLight: "#FAFAFA",
      secondary: "#FFCC3B",

      /* Beiges */
      lightBeige: "#F8F5F2",
      darkBeige: "#72706E",

      sennep: "#FFCB2E",
      sesam: "#F4F4F1",
      kostfiber: "#C7C3BF",

      kull: "#898989",

      /* Extra colors */
      warning: "#d5294a",
      special: "#46A84F",
      error: "#D95240",

      /* Secondary colors */
      fiber: "#E9E5E2",

      /* Refrences */
      buttonText: "#000000",
      buttonTextPrimary: "#ffffff",
      headerBg: "transparent",
      switchActiveBg: "#23ca34",
    },
    typography: {
      fontSizeGlobalMin: "16px",
      fontSizeGlobalMax: "27px",
      fontSizeH1Min: "30px",
      fontSizeH1Huge: "58px",
      fontSizeH1Max: "42px",
      fontSizeH2Min: "20px",
      fontSizeH2Max: "33px",
      fontSizeH3Min: "16px",
      fontSizeH3Max: "20px",
      fontSizeTiny: "12px",
      fontSizeMini: "11px",
      fontSizeSmall: "14px",
      fontSizeMedium: "19px",
      fontSizeLarge: "30px",
      fontFamily: '"Brown", Helvetica,Arial',
      fontSizeGlobalMobile: "16px",
    },
    /* Good to use name of size as name so you dont get stuck not able to add variations,
    as that happens here a lot. */
    spacing: {
      eight: "8px",
      sixteen: "16px",
      twentyFour: "24px",
      thirtyTwo: "32px",
      forty: "40px",
      fiftySix: "56px",
    },
  },
};

export default themes;
