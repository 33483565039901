// - importing like this so jest can work
import {
  nb as nbLocale,
  sv as svLocale,
  da as daLocale,
} from "date-fns/locale";
import { BRAND_NAME } from "./misc";
import { PAYMENT_METHODS } from "./payment";
import { COMPANY_IDS } from "./companies";

const constants = {
  AMK: {
    auth: {
      cookieName: "amk_token",
    },
    companyId: COMPANY_IDS.AMK,
    defaultCoupon: "AMK-30prosent",
    defaultMealboxSlug: "ekspress",
    apiLanguage: "no",
    locale: nbLocale,
    dishCount: 60,
    productsDishCount: {}, // No products with uniqe dish count
    facebook: { appId: "1340763149401664" },
    order: {
      defaultPortions: 4,
      defaultMeals: 3,
      minMeals: 3,
      maxMeals: 5,
      defaultMealbox: {
        productId: "045e9205-acbe-436d-9c07-1629330afa99", // Sunn og lett
        variationId: "a3b24cf6-f830-44c0-88ca-bbb72b54fa95", // Sunn og lett 3 middager 4 personer
      },
      cutoff: {
        // tuesday 23:59:59
        changes: {
          cutOffday: 2,
          cutOffHour: 23,
          cutOffMinutes: 59,
          cutOffSeconds: 59,
        },
      },
    },
    sanity: {
      projectId: "xoat7lmv",
      dataset: "dev",
      token:
        "skgILEBGGjhZ6md4NWu5gopvYoYuq3Md8LTpzPeMa04ThikFlZr5vi4Adqy4ikDCcqVmc6I0hDhKTRRebfvhNi7q0Yb7tDfMvnyNkrxtGPOvKlR4gO4lhWn1inoSe823vvcclEFCZU0CgyZoVry4dreF6C65Tna9WWo7trLeaROeKHZ3bJd5",
    },
    sanityBlog: {
      projectId: "aaspn9pi",
      dataset: "production",
    },
    signup: {
      defaultBillingAddressAlias: "Fakturaadresse",
      defaultBasketAddressAlias: "Hjemmeadresse",
      paymentMethods: [PAYMENT_METHODS.CARD, PAYMENT_METHODS.INVOICE],
    },
    supportEmail: "post@adamsmatkasse.no",
    featureFlags: {
      cookieName: "amk_ff",
    },
    loyaltyClub: {
      PRODUCT_CATEGORY_ID: "da3162c3-8590-4fa8-bca4-eaa4cfd0c241",
    },
    vatRate: 0.15,
  },

  GL: {
    auth: {
      cookieName: "gl_token",
    },
    companyId: COMPANY_IDS.GL,
    defaultCoupon: "150kr-sommer",
    defaultMealboxSlug: "favorittkassen",
    apiLanguage: "no",
    locale: nbLocale,
    dishCount: 87,
    productsDishCount: {
      "48a73f69-8ac1-6e0c-8793-ff0200804eb3": 5, // Singlekassen
    },
    facebook: { appId: "155215201817116" },
    order: {
      defaultPortions: 4,
      defaultMeals: 3,
      minMeals: 3,
      maxMeals: 5,
      defaultMealbox: {
        productId: "90b56469-8ac1-6e0c-8793-ff0200804eb3", // Favorittkassen
        variationId: "aab56469-8ac1-6e0c-8793-ff0200804eb3", // Favoritt 3 middager 4 personer
      },
      cutoff: {
        // tuesday 23:59:59
        changes: {
          cutOffday: 2,
          cutOffHour: 23,
          cutOffMinutes: 59,
          cutOffSeconds: 59,
        },
      },
    },
    sanity: {
      projectId: "lr90oe8e",
      dataset: "dev",
      token:
        "sk0rJV4RuIw4iTAGGhBaeockJwdKjCrOMzPAGCVJUmuzUORnk2FWZ6Ck0YjOv1mo6YTUqhLJ1i1aWrr5mpmAmN5uc5QhUeGSsiRk9Q47Agq5Jm5KDnmTpdD8skXsgfHSjNG0UHf9491ayNBYuQ2hkinxIGql3oPPEHBhZK7Ho16izICJuf1O",
    },
    sanityBlog: {
      projectId: "ku6hnek4",
      dataset: "godtlevert",
    },
    signup: {
      defaultBillingAddressAlias: "Fakturaadresse",
      defaultBasketAddressAlias: "Hjemmeadresse",
      paymentMethods: [PAYMENT_METHODS.CARD, PAYMENT_METHODS.INVOICE],
    },
    supportEmail: "kundeservice@godtlevert.no",
    featureFlags: {
      cookieName: "gl_ff",
    },
    loyaltyClub: {
      PRODUCT_CATEGORY_ID: "da1b53c2-00d6-4695-b8aa-ff9ea076aa76",
    },
    vatRate: 0.15,
  },

  LMK: {
    auth: {
      cookieName: "lmk_token",
    },
    companyId: COMPANY_IDS.LMK,
    defaultCoupon: "NYKUNDST",
    defaultMealboxSlug: "klassiskt-och-inspirerande-4-personer",
    apiLanguage: "se",
    locale: svLocale,
    dishCount: 88,
    productsDishCount: {}, // No products with uniqe dish count
    facebook: { appId: "737684140303422" },
    order: {
      defaultPortions: 4,
      defaultMeals: 3,
      minMeals: 3,
      maxMeals: 5,
      defaultMealbox: {
        productId: "41f4d122-04e4-4d97-bed7-1d67281550e1", // Linas express
        variationId: "dfd5d399-b3f9-43c9-8232-25589e440ea9", // Linas Express 4 middagar 4 personer
      },
      cutoff: {
        // tuesday 23:59:59
        changes: {
          cutOffday: 2,
          cutOffHour: 23,
          cutOffMinutes: 59,
          cutOffSeconds: 59,
        },
      },
    },
    sanity: {
      projectId: "prcpx3do",
      dataset: "dev",
      token:
        "skpLU30yGqW73q1oxJMnmTzrqCaCxYN64NbLvyCdgRYXL89m8PUC6e1Js7XZzW7hzv6m8UehcBn0pZh5pLUBIW1YmOkOIQFichXwAMpp3vU0NYgvgvH33E4nRNJXz2pefCBzE5XQfU3qzPfLUMI9Q0Ibh3ca41vcYgLcOmG3t8PtDx5L43hk",
    },
    signup: {
      defaultBillingAddressAlias: "Fakturaadress",
      defaultBasketAddressAlias: "Hemadress",
      paymentMethods: [PAYMENT_METHODS.CARD, PAYMENT_METHODS.INVOICE],
    },
    supportEmail: "kundservice@linasmatkasse.se",
    featureFlags: {
      cookieName: "lmk_ff",
    },
    loyaltyClub: {
      PRODUCT_CATEGORY_ID: "58ab92e4-29bc-438a-8b03-4f91009ad1eb",
    },
    vatRate: 0.12,
  },

  RN: {
    auth: {
      cookieName: "rn_token",
    },
    companyId: COMPANY_IDS.RN,
    defaultCoupon: "NYKUNDE_2x20",
    defaultMealboxSlug: "favoritter",
    apiLanguage: "da",
    locale: daLocale,
    dishCount: 60,
    productsDishCount: {}, // No products with uniqe dish count
    facebook: { appId: "990722429033305" },
    order: {
      defaultPortions: 4,
      defaultMeals: 3,
      minMeals: 2,
      maxMeals: 5,
      defaultMealbox: {
        productId: "8a279815-6ee5-4a7f-922d-4a8f02317ef1", // Favoritter
        variationId: "2649bcb5-ccab-4e78-8f2f-116441b624e1", // Favoritter 3 dage for 4 personer
      },
      cutoff: {
        // thursday 23:59:59
        changes: {
          cutOffday: 4,
          cutOffHour: 23,
          cutOffMinutes: 59,
          cutOffSeconds: 59,
        },
      },
    },
    sanity: {
      projectId: "8r7j1x27",
      dataset: "production",
      token:
        "skQ28AW670rkWU25ZdTbQjLSY2wX35Lq7QAghAB8pUf6L7CdZnfyoZQietDL3UIxS6TctaYEnzFupQWE32f8doXQdip3X7htNgeTuL7ado7FwOlk5E2W4Hed7YVu40EHDpALk0K0q6T75WaXvZBldRIYLyNgPt2LYsYcMErHMmV5CldkbXJN",
    },
    signup: {
      defaultBillingAddressAlias: "Fakturaadresse",
      defaultBasketAddressAlias: "Hjemmeadresse",
      paymentMethods: [PAYMENT_METHODS.CARD],
    },
    supportEmail: "kundeservice@retnemt.dk",
    featureFlags: {
      cookieName: "rn_ff",
    },
    loyaltyClub: {
      PRODUCT_CATEGORY_ID: "fca219c7-4248-44f7-8078-3b4e66e3449a",
    },
    vatRate: 0.25,
  },
} as const;

export const {
  order,
  companyId,
  sanity,
  defaultCoupon,
  locale,
  apiLanguage,
  defaultMealboxSlug,
  dishCount,
  productsDishCount,
  loyaltyClub,
} = constants[BRAND_NAME];

export const config: typeof constants[keyof typeof constants] =
  constants[BRAND_NAME];

export * from "./billing";
export * from "./product";
export * from "./payment";
export * from "./timeblocks";
export * from "./misc";
export * from "./preference";
export * from "./order";
export * from "./media";
export * from "./trustpilot";
export * from "./contact";
export * from "./countries";
export * from "./delivery";
export * from "./faq";
export * from "./filters";
export * from "./formats";
export * from "./errorCodes";
export * from "./links";
export * from "./benefits";
export * from "./addonSubscriptions";
export * from "./paths";
export * from "./origin";
export * from "./companies";
export * from "./externalPartners";
export * from "./externalTools";
export * from "./dishCount";
