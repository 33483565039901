import { css } from "styled-components";
import media from "./media";

const imageSizes = css`
  //ImageSizes
  ${(props) =>
    props.size === "foodbox" &&
    css`
      ${media.teen`
        height: 420px;
       `};
    `};

  ${(props) =>
    props.size === "weekrow" &&
    css`
      height: 108px;
      ${media.teen`
        height: 330px;
      `};
    `};

  ${(props) =>
    props.size === "standalone" &&
    css`
      width: auto;
      max-width: 94px;
      height: auto !important;
      ${media.teen`
        max-width: 100%;
      `};
    `};

  ${(props) =>
    props.size === "standalonesimple" &&
    css`
      width: auto;
      max-width: 100%;
      height: auto !important;
    `};

  ${(props) =>
    props.size === "weekdetails" &&
    css`
      height: 260px;
      ${media.teen`
        height: 360px;
      `};
    `};
`;

export { imageSizes };
