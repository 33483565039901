import styled, { css } from "styled-components";
import { variables, media } from "../global-styles";
import { customBreakpoints } from "../constants/breakpoints";

// The size map decides what gutters/spacing the Block gets
const sizeMap = {
  smallMobile: {
    horizontal: {
      1: variables.gutter1SmallMobile,
      2: variables.gutter2SmallMobile,
      3: variables.gutter3SmallMobile,
    },
  },
  mobile: {
    horizontal: {
      1: variables.gutter1Mobile,
      2: variables.gutter2Mobile,
      3: variables.gutter3Mobile,
    },
    vertical: {
      1: variables.spacing1Mobile,
      2: variables.spacing2Mobile,
      3: variables.spacing3Mobile,
      4: variables.spacing4Mobile,
      5: variables.spacing5Mobile,
    },
  },
  desktop: {
    horizontal: {
      1: variables.gutter1,
      2: variables.gutter2,
      3: variables.gutter4,
    },
    vertical: {
      1: variables.spacing1,
      2: variables.spacing2,
      3: variables.spacing3,
      4: variables.spacing4,
      5: variables.spacing5,
    },
  },
};

function calcGutter(type, size = 0, breakpoint = "mobile") {
  switch (type) {
    case "left":
    case "right":
      return sizeMap[breakpoint].horizontal[size] || 0;
    case "top":
    case "bottom":
      return sizeMap[breakpoint].vertical[size] || 0;
    default:
      return 0;
  }
}

const Block = styled.div`
  /* Left margin */
  ${(props) =>
    props.left &&
    css`
      margin-left: ${calcGutter("left", props.left)};
      @media${customBreakpoints.onlySmallScreen} {
        margin-left: ${calcGutter("left", props.left, "smallMobile")};
      }
      ${media.teen`
        margin-left: ${calcGutter("left", props.left, "desktop")};
      `};
    `};

  /* Left padding */
  ${(props) =>
    props.leftPadding &&
    css`
      padding-left: ${calcGutter("left", props.leftPadding)};
      @media${customBreakpoints.onlySmallScreen} {
        padding-left: ${calcGutter("left", props.leftPadding, "smallMobile")};
      }
      ${media.teen`
        padding-left: ${calcGutter("left", props.leftPadding, "desktop")};
      `};
    `};

  /* Right margin */
  ${(props) =>
    props.right &&
    css`
      margin-right: ${calcGutter("right", props.right)};
      @media${customBreakpoints.onlySmallScreen} {
        margin-right: ${calcGutter("right", props.right, "smallMobile")};
      }
      ${media.teen`
        margin-right: ${calcGutter("right", props.right, "desktop")};
      `};
    `};

  /* Right padding */
  ${(props) =>
    props.rightPadding &&
    css`
      padding-right: ${calcGutter("right", props.rightPadding)};
      @media${customBreakpoints.onlySmallScreen} {
        padding-right: ${calcGutter(
          "right",
          props.rightPadding,
          "smallMobile",
        )};
      }
      ${media.teen`
        padding-right: ${calcGutter("right", props.rightPadding, "desktop")};
      `};
    `};

  /* Top padding */
  ${(props) =>
    props.top &&
    css`
      padding-top: ${calcGutter("top", props.top)};
      ${media.teen`
        padding-top: ${calcGutter("top", props.top, "desktop")};
      `};
    `};

  /* Contains warning text */
  ${(props) =>
    props.containsWarning &&
    css`
      p,
      span {
        color: ${props.theme.colors.warning};
      }
    `};

  /* Bottom padding */
  ${(props) =>
    props.bottom &&
    css`
      padding-bottom: ${calcGutter("bottom", props.bottom)};
      ${media.teen`
        padding-bottom: ${calcGutter("bottom", props.bottom, "desktop")};
      `};
    `};

  /* Override: Site Block (limits width) */
  ${(props) =>
    props.site &&
    css`
      margin: 0 auto;
      padding-left: ${variables.gutter2Mobile};
      padding-right: ${variables.gutter2Mobile};
      width: 100%;

      @media${customBreakpoints.onlySmallScreen} {
        padding-left: ${variables.gutter2SmallMobile};
        padding-right: ${variables.gutter2SmallMobile};
      }

      @media (min-width: 1024px) {
        padding-left: 60px;
        padding-right: 60px;
        max-width: 1440px;
      }
    `};

  ${(props) =>
    props.newSite &&
    css`
      width: 100%;
      margin: 0 auto;
      padding-left: ${variables.gutter2Mobile};
      padding-right: ${variables.gutter2Mobile};

      @media${customBreakpoints.onlySmallScreen} {
        padding-left: ${variables.gutter2SmallMobile};
        padding-right: ${variables.gutter2SmallMobile};
      }

      @media (min-width: 1024px) {
        padding-left: 60px;
        padding-right: 60px;
        max-width: 1680px;
      }
    `};

  /* Text align settings */
  ${(props) =>
    props.align &&
    css`
      text-align: ${props.align};
    `};

  /* Inline version */
  ${(props) =>
    props.inline &&
    css`
      display: inline-block;
    `};

  /* Inline version */
  ${(props) =>
    props.flex &&
    css`
      display: flex;
    `};

  /* Full width version */
  ${(props) =>
    props.full &&
    css`
      width: 100%;
    `};

  /* Centered version */
  ${(props) =>
    props.autocenter &&
    css`
      margin: 0 auto;
    `};

  /* Vertically centered version */
  ${(props) =>
    props.verticallyCenter &&
    css`
      margin-top: auto;
      margin-bottom: auto;
    `};

  /* Limited width version */
  ${(props) =>
    props.limitWidth &&
    css`
      max-width: ${props.limitWidth}px;
    `};

  /* Top layer version */
  ${(props) =>
    props.topLayer &&
    css`
      z-index: 2;
    `};

  /* White bg version */
  ${(props) =>
    props.white &&
    css`
      background-color: white;
    `};

  /* Light grey bg version */
  ${(props) =>
    props.lightgrey &&
    css`
      background-color: ${props.theme.colors.lightBeige};
    `};

  /* Lighter grey bg version */
  ${(props) =>
    props.lightergrey &&
    css`
      background-color: ${props.theme.colors.lighterGrey};
    `};

  /* White bg version */
  ${(props) =>
    props.primary &&
    css`
      color: white;
      background-color: ${props.theme.colors.primary};
    `};

  /* Sennep bg version */
  ${(props) =>
    props.sennep &&
    css`
      background-color: ${props.theme.colors.sennep};
    `};

  /* Light grey bg version */
  ${(props) =>
    props.red &&
    css`
      background-color: ${props.theme.colors.primary};
      color: white;
    `};

  /* White bg version */
  ${(props) =>
    props.hideOverflow &&
    css`
      overflow: hidden;
    `};

  /* flex-grow  */
  ${(props) =>
    props.flexGrow &&
    css`
      flex-grow: ${props.flexGrow};
    `};

  /* flexBasis version */
  ${(props) =>
    props.flexBasis &&
    css`
      flex-basis: "0";
    `};

  ${(props) =>
    props.float &&
    css`
      float: ${props.float};
    `};

  ${(props) =>
    props.lessPadding &&
    css`
      margin-bottom: -1rem;
    `};
  ${(props) =>
    props.opacity &&
    css`
      opacity: 0.2;
    `};

  /* Enable stickiness */
  ${(props) =>
    props.sticky &&
    css`
      position: sticky;
      top: 0px;
      backface-visibility: hidden;
    `};
  /* height adjustment */
  ${(props) =>
    props.heightAdjust &&
    css`
      ${media.teen`
        min-height: 80vh;
  `};
    `};
  /* background image */
  ${(props) =>
    props.img &&
    css`
      height: 500px;
      background-image: url("${props.img}");
      background-size: cover;
      ${media.infant`
        height: 500;
      `};
      ${media.teen`
        height: 600px;
      `};
      ${media.adult`
        height: 700px;
      `};
    `};
  /* full height */
  ${(props) =>
    props.fullHeight &&
    css`
      height: 100%;
    `};
  /* Faded variant */
  ${(props) =>
    props.faded &&
    css`
      color: ${props.theme.colors.dark};
      opacity: 0.6;
    `};

  ${(props) =>
    props.fitHeight &&
    css`
      height: fit-content;
    `};

  /* Sesam top border */
  ${(props) =>
    props.sesamTopBorder &&
    css`
      border-top: 1px solid ${props.theme.colors.sesam};
    `};

  /* Sesam bottom border */
  ${(props) =>
    props.sesamBottomBorder &&
    css`
      border-bottom: 1px solid ${props.theme.colors.sesam};
    `};

  ${(props) =>
    props.overlay &&
    css`
      position: relative;
      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(108, 106, 104, 0.7);
      }
    `};

  ${(props) =>
    props.whiteSpace &&
    css`
      white-space: ${props.whiteSpace};
    `};

  ${(props) =>
    props.maxContent &&
    css`
      width: max-content;
    `};

  /* primary border */
  ${(props) =>
    props.hasBorder &&
    css`
      border: 2px solid ${props.theme.colors.kull};
      border-radius: 4px;
    `};

  /* Sesam bottom border */
  ${(props) =>
    props.fiberBottomBorder &&
    css`
        border-bottom: 2px solid ${props.theme.colors.fiber};
        box-shadow: inset 0px -1px 0px rgba(29, 28, 26, 0.2):
      `};

  ${(props) =>
    props.inlineFlex &&
    css`
      display: inline-flex;
    `};
`;

export default Block;
