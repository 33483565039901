import styled from "styled-components";

const ImageWrapper = styled.div`
  position: relative;
  max-width: 30px;
  margin: 0 auto;
  height: 22px;
`;

export default ImageWrapper;
