import { RatingConsentDisclaimer as RatingConsentDisclaimerContainer } from "@chef/components";
import { BRAND_PATHS } from "@chef/constants";
import { Info } from "@chef/icons/small";
import { intl } from "./RatingConsentDisclaimer.Intl";
export const RATING_CONSENT_TYPE = {
  ORDER_DETAILS: "order-details",
  MY_RECIPES: "my-recipes",
  WEEK_EDITOR: "week-editor",
} as const;

interface RatingConsentDisclaimerProps {
  type: typeof RATING_CONSENT_TYPE[keyof typeof RATING_CONSENT_TYPE];
}

export const RatingConsentDisclaimer = ({
  type,
}: RatingConsentDisclaimerProps) => {
  // if we're not on the week editor, we're rating the dish(es)
  const ratingDish = type !== RATING_CONSENT_TYPE.WEEK_EDITOR;

  return (
    <RatingConsentDisclaimerContainer
      Title={() => (
        <span>
          {ratingDish ? intl.RATING_DISH_TITLE : intl.DISH_RATING_TITLE}
        </span>
      )}
      Icon={Info}
    >
      <p>{ratingDish ? intl.RATING_DISH_BODY : intl.DISH_RATING_BODY}</p>

      {ratingDish && (
        <div className="mt-4 underline">
          <a
            href={BRAND_PATHS.PRIVACY_POLICY_PATH}
            target="_blank"
            rel="noreferrer"
          >
            <strong>{intl.RATING_DISH_LINK_TEXT}</strong>
          </a>
        </div>
      )}
    </RatingConsentDisclaimerContainer>
  );
};
