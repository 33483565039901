import styled, { css } from "styled-components";
import fluidUnit from "../helpers/fluidUnit";
/* eslint-disable */
const H1 = styled.h1`
  ${(props) =>
    fluidUnit({
      unit: "font-size",
      minUnit: props.theme.typography.fontSizeH1Min,
      maxUnit: props.theme.typography.fontSizeH1Huge,
    })};
  font-weight: bold;
  line-height: 1.2;
  color: ${(props) => props.theme.colors.dark};

  /* Small version */
  ${(props) =>
    props.small &&
    css`
      ${fluidUnit({
        unit: "font-size",
        minUnit: "24px",
        maxUnit: "30px",
      })};
      line-height: 1.3;
    `};

  /* No-wrap version */
  ${(props) =>
    props.nowrap &&
    css`
      white-space: nowrap;
    `};

  /* primary version */
  ${(props) =>
    props.primary &&
    css`
      color: ${props.theme.colors.primary} !important;
    `};

  /* Custom color version */
  ${(props) =>
    props.customColor &&
    css`
      color: ${props.theme.colors[props.customColor]};
    `};

  /* Opaque version */
  ${(props) =>
    props.faded &&
    css`
      color: ${props.theme.colors.lightBeige};
    `};

  /* Inverted version */
  ${(props) =>
    props.inverted &&
    css`
      color: ${props.theme.colors.light};
    `};
`;

const H2 = styled.h2`
  ${fluidUnit({
    unit: "font-size",
    minUnit: "24px",
    maxUnit: "30px",
  })};
  font-weight: bold;
  line-height: 1.3;

  /* Large version */
  ${(props) =>
    props.large &&
    css`
      ${fluidUnit({
        unit: "font-size",
        minUnit: "24px",
        maxUnit: "42px",
      })};
      line-height: 1.2;
    `};

  /* Light version */
  ${(props) =>
    props.light &&
    css`
      font-weight: normal;
    `};

  /* No-wrap version */
  ${(props) =>
    props.nowrap &&
    css`
      white-space: nowrap;
    `};

  /* primary version */
  ${(props) =>
    props.primary &&
    css`
      color: ${props.theme.colors.primary} !important;
    `};

  /* Custom color version */
  ${(props) =>
    props.customColor &&
    css`
      color: ${props.theme.colors[props.color]};
    `};

  /* Opaque version */
  ${(props) =>
    props.faded &&
    css`
      color: ${props.theme.colors.lightBeige};
    `};

  /* Inverted version */
  ${(props) =>
    props.inverted &&
    css`
      color: ${props.theme.colors.light};
    `};
`;

const H3 = styled.h3`
  ${fluidUnit({
    unit: "font-size",
    minUnit: "20px",
    maxUnit: "25px",
  })};
  font-weight: bold;

  /* Light version */
  ${(props) =>
    props.light &&
    css`
      font-weight: normal;
    `};

  /* No-wrap version */
  ${(props) =>
    props.nowrap &&
    css`
      white-space: nowrap;
    `};

  /* primary version */
  ${(props) =>
    props.primary &&
    css`
      color: ${props.theme.colors.primary} !important;
    `};

  /* Custom color version */
  ${(props) =>
    props.customColor &&
    css`
      color: ${props.theme.colors[props.color]};
    `};

  /* Opaque version */
  ${(props) =>
    props.faded &&
    css`
      color: ${props.theme.colors.lightBeige};
    `};

  /* Inverted version */
  ${(props) =>
    props.inverted &&
    css`
      color: ${props.theme.colors.light};
    `};
`;

const H4 = styled.h4`
  font-weight: bold;

  /* Centered version */
  ${(props) =>
    props.centered &&
    css`
      text-align: center;
    `};

  /* Light color version */
  ${(props) =>
    props.light &&
    css`
      color: ${props.theme.colors.light};
    `};
`;
/* eslint-enable */
export { H1, H2, H3, H4 };
