import { PRODUCT_TYPE_IDS } from "@chef/constants";
import {
  isMealboxProduct,
  isFinancialProduct,
  isPickAndMixProduct,
} from "@chef/helpers";
import {
  OrderQuery,
  usePickAndMixQuery,
  useRecipesByProductIdListQuery,
} from "@chef/state-management";
import { getAttribute } from "@chef/state-management/helpers";
import { isEqualStrings } from "@chef/utils/equal";
import { nonNullable } from "@chef/utils/nonNullable";
import { Meals } from "../shared/Meals";

interface OrderMealsProps {
  order: OrderQuery["order"];
  initiallyExpanded?: boolean;
}

export const OrderMeals = ({ order, initiallyExpanded }: OrderMealsProps) => {
  const primaryProduct = order.orderLines.find(
    (ol) =>
      isMealboxProduct(ol.variation.product) ||
      isFinancialProduct(ol.variation.product),
  );

  const { data: recipesByProductIdListQuery } = useRecipesByProductIdListQuery(
    {
      week: order.week,
      year: order.year,
      productIds: [primaryProduct?.variation.productId || ""],
      range: 1,
    },
    {
      skip:
        primaryProduct && !isMealboxProduct(primaryProduct.variation.product),
    },
  );

  const { data: pickAndMixQuery } = usePickAndMixQuery(
    {
      week: order.week,
      year: order.year,
      productTypeId: PRODUCT_TYPE_IDS.PICKANDMIX,
    },
    {
      skip:
        primaryProduct && !isFinancialProduct(primaryProduct.variation.product),
    },
  );

  if (!primaryProduct) {
    return null;
  }

  const mealboxRecipes = recipesByProductIdListQuery?.recipesByProductIdList
    .flatMap((wy) => wy.products)
    .flatMap((p) => p.recipes)
    .map((r) => ({ recipe: r, price: 0 }));

  const pickAndMixProducts = order.orderLines.filter((ol) =>
    isPickAndMixProduct(ol.variation.product),
  );

  const pickAndMixRecipes = pickAndMixProducts
    .map((p) => {
      const product = pickAndMixQuery?.pickAndMix.find((pmx) =>
        isEqualStrings(pmx.productId, p.variation.productId),
      );

      if (!product) {
        return null;
      }

      const recipe = product.recipes[0];

      return {
        recipe,
        price: p.fullPrice,
      };
    })
    .filter(nonNullable);

  const recipes = isMealboxProduct(primaryProduct.variation.product)
    ? mealboxRecipes
    : pickAndMixRecipes;

  const portions = +(getAttribute("Portions", primaryProduct.variation) || "0");
  const meals = +(getAttribute("Meals", primaryProduct.variation) || "0");

  return (
    <Meals
      dishes={
        recipes?.slice(0, meals).map((recipe) => ({
          name: recipe.recipe.recipeName,
          extraPrice: recipe.price,
        })) || []
      }
      portions={portions}
      priceOfMeals={primaryProduct.fullPrice}
      initiallyExpanded={initiallyExpanded}
    />
  );
};
