import styled, { css } from "styled-components";
import { customBreakpoints, breakpoints } from "../constants/breakpoints";

const calc = (em, px, max = false) =>
  `${parseInt(em, 10) * parseInt(px, 10) - (max ? 1 : 0)}px`;

const MinWidthInfant = styled.div`
  display: none;

  ${(props) => css`
    @media screen and (min-width: ${calc(
        breakpoints.infant,
        props.theme.typography.fontSizeGlobalMin,
      )}) {
      display: initial;
    }
  `}

  ${(props) =>
    props.inlineBlock &&
    css`
      @media screen and (min-width: ${calc(
          breakpoints.infant,
          props.theme.typography.fontSizeGlobalMin,
        )}) {
        display: inline-block;
      }
    `}
`;

const MinWidthBaby = styled.div`
  display: none;

  ${(props) => css`
    @media screen and (min-width: ${calc(
        breakpoints.baby,
        props.theme.typography.fontSizeGlobalMin,
      )}) {
      display: initial;
    }
  `}

  ${(props) =>
    props.inlineBlock &&
    css`
      @media screen and (min-width: ${calc(
          breakpoints.baby,
          props.theme.typography.fontSizeGlobalMin,
        )}) {
        display: inline-block;
      }
    `}
`;

const MinWidthChild = styled.div`
  display: none;

  ${(props) => css`
    @media screen and (min-width: ${calc(
        breakpoints.child,
        props.theme.typography.fontSizeGlobalMin,
      )}) {
      display: initial;
    }
  `}

  ${(props) =>
    props.inlineBlock &&
    css`
      @media screen and (min-width: ${calc(
          breakpoints.child,
          props.theme.typography.fontSizeGlobalMin,
        )}) {
        display: inline-block;
      }
    `}
`;

const MinWidthTeen = styled.div`
  display: none;

  ${(props) => css`
    @media screen and (min-width: ${calc(
        breakpoints.teen,
        props.theme.typography.fontSizeGlobalMin,
      )}) {
      display: initial;
    }
  `}

  ${(props) =>
    props.inlineBlock &&
    css`
      @media screen and (min-width: ${calc(
          breakpoints.teen,
          props.theme.typography.fontSizeGlobalMin,
        )}) {
        display: inline-block;
      }
    `}
`;

const MinWidthAdult = styled.div`
  display: none;

  ${(props) => css`
    @media screen and (min-width: ${calc(
        breakpoints.adult,
        props.theme.typography.fontSizeGlobalMin,
      )}) {
      display: initial;
    }
  `}

  ${(props) =>
    props.inlineBlock &&
    css`
      @media screen and (min-width: ${calc(
          breakpoints.adult,
          props.theme.typography.fontSizeGlobalMin,
        )}) {
        display: inline-block;
      }
    `}
`;

const MinWidthElder = styled.div`
  display: none;

  ${(props) => css`
    @media screen and (min-width: ${calc(
        breakpoints.elder,
        props.theme.typography.fontSizeGlobalMin,
      )}) {
      display: initial;
    }
  `}

  ${(props) =>
    props.inlineBlock &&
    css`
      @media screen and (min-width: ${calc(
          breakpoints.elder,
          props.theme.typography.fontSizeGlobalMin,
        )}) {
        display: inline-block;
      }
    `}
`;

const MinWidthCustom = styled.div`
  display: none;

  ${(props) =>
    props.minWidth &&
    css`
      @media screen and (min-width: ${props.minWidth}) {
        display: initial;
      }
    `}

  ${(props) =>
    props.minWidth &&
    props.inlineBlock &&
    css`
      @media screen and (min-width: ${props.minWidth}) {
        display: inline-block;
      }
    `}
`;

const OnlyInfant = styled.div`
  display: none;

  ${css`
    @media screen and ${customBreakpoints.onlyInfant} {
      display: initial;
    }
  `}

  ${(props) =>
    props.inlineBlock &&
    css`
      @media screen and ${customBreakpoints.onlyInfant} {
        display: inline-block;
      }
    `}
`;

const OnlyBaby = styled.div`
  display: none;

  ${css`
    @media screen and ${customBreakpoints.onlyBaby} {
      display: initial;
    }
  `}

  ${(props) =>
    props.inlineBlock &&
    css`
      @media screen and ${customBreakpoints.onlyBaby} {
        display: inline-block;
      }
    `}
`;

const OnlyChild = styled.div`
  display: none;

  ${css`
    @media screen and ${customBreakpoints.onlyChild} {
      display: initial;
    }
  `}

  ${(props) =>
    props.inlineBlock &&
    css`
      @media screen and ${customBreakpoints.onlyChild} {
        display: inline-block;
      }
    `}
`;

const OnlyTeen = styled.div`
  display: none;

  ${css`
    @media screen and ${customBreakpoints.onlyTeen} {
      display: initial;
    }
  `}

  ${(props) =>
    props.inlineBlock &&
    css`
      @media screen and ${customBreakpoints.onlyTeen} {
        display: inline-block;
      }
    `}
`;

const OnlyAdult = styled.div`
  display: none;

  ${css`
    @media screen and ${customBreakpoints.onlyAdult} {
      display: initial;
    }
  `}

  ${(props) =>
    props.inlineBlock &&
    css`
      @media screen and ${customBreakpoints.onlyAdult} {
        display: inline-block;
      }
    `}
`;

const OnlyElder = styled.div`
  display: none;

  ${css`
    @media screen and ${customBreakpoints.onlyElder} {
      display: initial;
    }
  `}

  ${(props) =>
    props.inlineBlock &&
    css`
      @media screen and ${customBreakpoints.onlyElder} {
        display: inline-block;
      }
    `}
`;

const OnlyCustom = styled.div`
  display: none;

  ${(props) =>
    props.minWidth &&
    props.maxWidth &&
    css`
      @media screen and (min-width: ${props.minWidth}) and (max-width: ${props.maxWidth}) {
        display: initial;
      }
    `}

  ${(props) =>
    props.minWidth &&
    props.maxWidth &&
    props.inlineBlock &&
    css`
      @media screen and (min-width: ${props.minWidth}) and (max-width: ${props.maxWidth}) {
        display: inline-block;
      }
    `}
`;

const MaxWidthInfant = styled.div`
  display: none;

  ${(props) => css`
    @media screen and (max-width: ${calc(
        breakpoints.infant,
        props.theme.typography.fontSizeGlobalMin,
        true,
      )}) {
      display: initial;
    }
  `}

  ${(props) =>
    props.inlineBlock &&
    css`
      @media screen and (max-width: ${calc(
          breakpoints.infant,
          props.theme.typography.fontSizeGlobalMin,
          true,
        )}) {
        display: inline-block;
      }
    `}
`;

const MaxWidthBaby = styled.div`
  display: none;

  ${(props) => css`
    @media screen and (max-width: ${calc(
        breakpoints.baby,
        props.theme.typography.fontSizeGlobalMin,
        true,
      )}) {
      display: initial;
    }
  `}

  ${(props) =>
    props.inlineBlock &&
    css`
      @media screen and (max-width: ${calc(
          breakpoints.baby,
          props.theme.typography.fontSizeGlobalMin,
          true,
        )}) {
        display: inline-block;
      }
    `}
`;

const MaxWidthChild = styled.div`
  display: none;

  ${(props) => css`
    @media screen and (max-width: ${calc(
        breakpoints.child,
        props.theme.typography.fontSizeGlobalMin,
        true,
      )}) {
      display: initial;
    }
  `}

  ${(props) =>
    props.inlineBlock &&
    css`
      @media screen and (max-width: ${calc(
          breakpoints.child,
          props.theme.typography.fontSizeGlobalMin,
          true,
        )}) {
        display: inline-block;
      }
    `}
`;

const MaxWidthTeen = styled.div`
  display: none;

  ${(props) => css`
    @media screen and (max-width: ${calc(
        breakpoints.teen,
        props.theme.typography.fontSizeGlobalMin,
        true,
      )}) {
      display: initial;
    }
  `}

  ${(props) =>
    props.inlineBlock &&
    css`
      @media screen and (max-width: ${calc(
          breakpoints.teen,
          props.theme.typography.fontSizeGlobalMin,
          true,
        )}) {
        display: inline-block;
      }
    `}
`;

const MaxWidthAdult = styled.div`
  display: none;

  ${(props) => css`
    @media screen and (max-width: ${calc(
        breakpoints.adult,
        props.theme.typography.fontSizeGlobalMin,
        true,
      )}) {
      display: initial;
    }
  `}

  ${(props) =>
    props.inlineBlock &&
    css`
      @media screen and (max-width: ${calc(
          breakpoints.adult,
          props.theme.typography.fontSizeGlobalMin,
          true,
        )}) {
        display: inline-block;
      }
    `}
`;

const MaxWidthElder = styled.div`
  display: none;

  ${(props) => css`
    @media screen and (max-width: ${calc(
        breakpoints.elder,
        props.theme.typography.fontSizeGlobalMin,
        true,
      )}) {
      display: initial;
    }
  `}

  ${(props) =>
    props.inlineBlock &&
    css`
      @media screen and (max-width: ${calc(
          breakpoints.elder,
          props.theme.typography.fontSizeGlobalMin,
          true,
        )}) {
        display: inline-block;
      }
    `}
`;

const MaxWidthCustom = styled.div`
  display: none;

  ${(props) =>
    css`
      @media screen and (max-width: ${props.maxWidth}) {
        display: initial;
      }
    `}

  ${(props) =>
    props.inlineBlock &&
    props.maxWidth &&
    css`
      @media screen and (max-width: ${props.maxWidth}) {
        display: inline-block;
      }
    `}
`;

const MinWidth = {
  infant: MinWidthInfant,
  Baby: MinWidthBaby,
  Child: MinWidthChild,
  Teen: MinWidthTeen,
  Adult: MinWidthAdult,
  Elder: MinWidthElder,
  Custom: MinWidthCustom,
};

const MaxWidth = {
  Infant: MaxWidthInfant,
  Baby: MaxWidthBaby,
  Child: MaxWidthChild,
  Teen: MaxWidthTeen,
  Adult: MaxWidthAdult,
  Elder: MaxWidthElder,
  Custom: MaxWidthCustom,
};

const Only = {
  Infant: OnlyInfant,
  Baby: OnlyBaby,
  Child: OnlyChild,
  Teen: OnlyTeen,
  Adult: OnlyAdult,
  Elder: OnlyElder,
  Custom: OnlyCustom,
};

export default {
  MinWidth,
  MaxWidth,
  Only,
};
