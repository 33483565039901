import React from "react";
import Link from "next/link";
import { Button } from "@chef/components";

const MagicLink = (props) => {
  const { href = "", to = "" } = props;

  return (
    <Link href={to || href} passHref legacyBehavior>
      <Button {...props}>{props.children}</Button>
    </Link>
  );
};

export default MagicLink;
