import styled, { css } from "styled-components";

import { variables, media } from "../global-styles";
import { customBreakpoints } from "../constants/breakpoints";

const Grid = styled.div.attrs({
  role: "list",
})`
  list-style: none;
  margin: 0;
  padding: 0;
  margin-left: -${variables.gutter4Mobile};

  // @media${customBreakpoints.onlySmallScreen} {
  //   margin-left:  -${variables.gutter4SmallMobile};
  // }

  ${media.teen`
    margin-left: -${variables.gutter4};
  `};

  /* Reversed version */
  ${(props) =>
    props.rev &&
    css`
      direction: rtl;
      text-align: left;

      > * {
        direction: ltr !important;
        text-align: left !important;
      }
    `};

  /* Full version */
  ${(props) =>
    props.full &&
    css`
      margin-left: 0;

      > * {
        padding-left: 0 !important;
      }
    `};

  /* Vertical align: middle version */
  ${(props) =>
    props.middle &&
    css`
      > * {
        vertical-align: middle !important;
      }
    `};

  /* Vertical align: bottom version */
  ${(props) =>
    props.bottom &&
    css`
      > * {
        vertical-align: bottom !important;
      }
    `};

  /* Centered version */
  ${(props) =>
    props.center &&
    css`
      text-align: center;

      > * {
        text-align: left !important;
      }
    `};

  /* Narrow version */
  ${(props) =>
    props.narrow &&
    css`
      margin-left: -${variables.gutter1Mobile};
      > * {
        padding-left: ${variables.gutter1Mobile} !important;
      }

      ${media.teen`
        margin-left: -${variables.gutter1};

        > * {
          padding-left: ${variables.gutter1} !important;
        }
      `};
    `};

  ${(props) =>
    props.innerGrid &&
    css`
      margin-left: -${variables.gutter2Mobile};
      > * {
        padding-left: ${variables.gutter2Mobile} !important;
      }

      ${media.teen`
          margin-left: -${variables.gutter2};

          > * {
            padding-left: ${variables.gutter2} !important;
          }
        `};
    `};

  ${(props) =>
    props.flex &&
    css`
      display: flex;
    `};
  ${(props) =>
    props.flexEnd &&
    css`
      display: flex;
      justify-content: flex-end;
    `};

  ${(props) =>
    props.flexWrap &&
    css`
      display: flex;
      flex-flow: wrap;
    `};
  ${(props) =>
    props.flexCenter &&
    css`
      justify-content: center;
    `};

  /* full height */
  ${(props) =>
    props.fullHeight &&
    css`
      height: 100%;
    `};

  ${(props) =>
    props.flexColumnReverse &&
    css`
      display: flex;
      flex-direction: column;
      flex-flow: column-reverse;
    `};

  ${(props) =>
    props.morePadding &&
    css`
      ${media.teen`
        > * {
          padding-left: 60px !important;
        }`}
    `};
`;

export default Grid;
