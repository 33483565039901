import styled, { css } from "styled-components";

import { media, variables } from "../global-styles";

const Form = styled.form`
  display: block;

  ${(props) =>
    props.forms &&
    css`
      padding-right: 0;
      ${media.child`
        padding-right: ${variables.gutter3};
      `};
    `};
`;

export default Form;
