export const PREFERENCE_TYPE_IDS = {
  CONCEPT: "009cf63e-6e84-446c-9ce4-afdbb6bb9687",
  TASTE: "4c679266-7dc0-4a8e-b72d-e9bb8dadc7eb",
  OPTIMIZATION: "56275ca4-5ba6-49aa-8ad9-cd33a6f9564a",
  SELECTOR: "5F901982-12C7-4D44-9D50-F7D1F19BA09D",
} as const;

// TODO: Onesub migration: Does not need Onesub in the name
export const PREFERENCE_IDS = {
  SINGEL_ONESUB: "37ce056f-4779-4593-949a-42478734f747",
};

export const TASTE_PREFERENCE_SELETION_LIMITS = {
  // TODO: The cutoff for amount of taste preferences has not yet determined. So should be udpated later.
  SOFT_LIMIT: 7,
  STRONG_LIMIT: 8,
  MAX_LIMIT: 9,
};
