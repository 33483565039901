import clsx from "clsx";
import React, { PropsWithChildren } from "react";

interface TableProps {
  striped?: boolean;
  bordered?: boolean;
  children: React.ReactElement;
  className?: string;
  title?: React.ReactNode;
}

export const Table = ({
  striped = true,
  bordered = true,
  children,
  className,
  title,
}: TableProps) => {
  if (!children) {
    return null;
  }

  const tChildren = children["props"]["children"] as React.ReactElement[];

  const tHeadChildren = tChildren.find(
    (child) => child.type === "thead",
  ) as any as HTMLTableCellElement;

  const tBodyChildren = tChildren.filter(
    (child) => child.type !== "thead",
  ) as any as HTMLTableCellElement[];

  return (
    <table
      className={clsx(
        className,
        title && "relative",
        "table-fixed md:table-auto w-full",
      )}
    >
      {title && (
        <div className="absolute hidden pl-6 mt-6 text-lg text-left md:inline-block md:top-0 ">
          <strong>{title}</strong>
        </div>
      )}

      {React.Children.map(tHeadChildren, (child, index) => {
        const tHeadChild = (child as any)["props"]["children"];

        return (
          <THead key={index} bordered={bordered} title={title}>
            {tHeadChild}
          </THead>
        );
      })}

      <tbody>
        {React.Children.map(tBodyChildren, (child, index) => {
          if (!child) {
            return null;
          }
          const header = (child as any)["props"]["header"];
          const tBodyChild = (child as any)["props"][
            "children"
          ] as React.ReactNode[];

          const isEvenRow = (index: number) => index % 2 === 0;

          return (
            <Tr
              key={index}
              header={header}
              striped={striped && isEvenRow(index + 1)}
              bordered={bordered}
            >
              {tBodyChild?.map((child, index) => {
                return <Td key={index}>{child}</Td>;
              })}
            </Tr>
          );
        })}
      </tbody>
    </table>
  );
};

const THead = ({
  children,
  bordered,
  title,
}: PropsWithChildren<{
  bordered: boolean;
  title?: React.ReactNode;
}>) => {
  return (
    <thead>
      {title && (
        <div className="py-6 pl-6 text-lg md:hidden">
          <strong>{title}</strong>
        </div>
      )}

      <tr className="pt-4 text-center">
        <th className="hidden md:inline-block" />

        {React.Children.map(children, (child, index) => {
          const tHeadChild = (child as any)?.["props"]["children"];
          return (
            <Th key={index} bordered={bordered} hasTitle={!!title}>
              {tHeadChild}
            </Th>
          );
        })}
      </tr>
    </thead>
  );
};

const Th = ({
  children,

  hasTitle,
}: PropsWithChildren<{
  bordered: boolean;
  hasTitle?: boolean;
}>) => {
  return (
    <th
      className={clsx(
        hasTitle ? "md:pt-18" : "md:pt-8",

        "p-4 border-r-1.5 border-grey-3 md:border-none last:border-r-0",
      )}
    >
      {children}
    </th>
  );
};

const Tr = ({
  children,
  header,
  striped,
  bordered,
}: PropsWithChildren<{
  header: string;
  children: React.ReactNode[];
  striped?: boolean;
  bordered?: boolean;
}>) => {
  if ([...Array(children.length)].every((_, i) => !children[i])) {
    return null;
  }
  return (
    <>
      <tr className="w-full md:hidden">
        <td
          className="h-8 py-2 text-xs text-center border border-grey-3 bg-background border-x-0"
          colSpan={children.length}
        >
          {header}
        </td>
      </tr>

      <tr
        className={clsx(
          striped && "md:bg-background",
          bordered && "border-grey-3 border-b-1.5 last:border-b-0 h-11 md:h-16",
        )}
      >
        <td className="hidden w-2/5 px-6 py-3 text-sm text-left md:table-cell">
          {header}
        </td>

        {React.Children.map(children, (child, index) => {
          return <Td key={index}>{child}</Td>;
        })}
      </tr>
    </>
  );
};

const Td = ({ children }: PropsWithChildren) => {
  return (
    <td className="border-r-1.5 border-grey-3 md:border-none last:border-r-0 [&>td]:flex [&>td]:justify-center py-2">
      {children}
    </td>
  );
};

Table.THead = THead;
Table.Th = Th;
Table.Tr = Tr;
Table.Td = Td;
