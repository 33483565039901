import styled, { css } from "styled-components";

import { variables, media } from "../../../global-styles";

import Footer from "./Footer";
import Image from "./Image";
import Title from "./Title";

const Card = styled.div`
  background-color: ${(props) => props.theme.colors.light};
  box-shadow: ${variables.boxShadow};
  border-radius: ${variables.borderRadius};
  // overflow: hidden;

  ${(props) =>
    props.standAloneCard &&
    css`
      height: 100%;
      position: relative;
      overflow: hidden;
      display: flex;
      justify-content: flex-start;

      ${media.teen`
        flex-direction: column;

        ${(props) =>
          props.compact &&
          css`
            img {
              max-width: 94px;
            }
            flex-direction: row;
          `}
      `};

      ${(props) =>
        props.noStandaloneAction &&
        css`
          flex-direction: column;
        `}
    `};

  ${(props) =>
    props.overflow &&
    css`
      overflow: hidden;
    `};

  ${(props) =>
    props.disabled &&
    css`
      background-color: #f4f4f1;
    `};

  ${(props) =>
    props.marginBorderBottom &&
    css`
      border: 1px solid ${(props) => props.theme.colors.fiber};
      margin-bottom: 1px;
    `};

  ${(props) =>
    props.borderRadiusOverride &&
    css`
      * {
        border-radius: ${variables.borderRadius} !important;
      }
    `};
`;

Card.Footer = Footer;
Card.Image = Image;
Card.Title = Title;

export default Card;
