import { Ref, SetStateAction, useCallback, useState } from "react";
import { useLazyAddressValidationQuery } from "@chef/state-management";
import { IAddressValidationModel } from "@chef/state-management";
import { AsyncSelect } from "@chef/components";
import { LightBulb } from "@chef/icons/small";
import { intl } from "./StreetNameSelectInput.Intl";
import { IInputOption, QUERY_MODE } from "./AddressForm";

interface Props {
  selectedAddress: IAddressValidationModel;
  locked: boolean;
  queryText: string;
  onSelected: (value: IAddressValidationModel) => void;
  onInputChanged: (value: string) => void;
  customRef: Ref<HTMLInputElement>;
  mandatoryErrorText?: string;
  selectFromListErrorText?: string;
  className?: string;
  setNoAddressFound: (value: SetStateAction<boolean>) => void;
}

// debounce implementation from https://github.com/JedWatson/react-select/issues/614#issuecomment-1178792592
export const debounce = (
  fn: {
    (inputValue: string, callback: (options: IInputOption[]) => void): void;
  },
  delay = 250,
) => {
  let timeout: NodeJS.Timeout;

  return (inputValue: string, callback: (options: IInputOption[]) => void) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      fn(inputValue, callback);
    }, delay);
  };
};

const StreetNameSelectInput = ({
  locked,
  selectedAddress,
  queryText,
  onSelected,
  onInputChanged,
  setNoAddressFound,
  customRef,
  mandatoryErrorText,
  selectFromListErrorText,
  className,
}: Props) => {
  const [showSearchTip, setShowSearchTip] = useState(false);
  const [addressQuery] = useLazyAddressValidationQuery();

  const getStreetNameOptions = async (value: string) => {
    const { data } = await addressQuery({
      queryText: value,
      mode: QUERY_MODE.STREET_NAME,
    });
    return (
      data?.value.map((a) => ({
        label: a.streetName,
        value: a.streetName,
        data: a,
      })) || []
    );
  };

  const loadStreetAddressOptionsDebounced = useCallback(
    debounce(
      (inputValue: string, callback: (options: IInputOption[]) => void) => {
        getStreetNameOptions(inputValue)
          .then((options) => {
            const resultsHaveSameStreetName = options.every(
              (option) =>
                option.data.streetName.toLowerCase() ===
                inputValue.toLowerCase(),
            );
            if (options.length === 0) {
              setNoAddressFound(true);
            } else {
              setNoAddressFound(false);
            }
            if (options.length > 10 && resultsHaveSameStreetName) {
              setShowSearchTip(true);
            }
            if (inputValue.length < 3 || options.length < 20) {
              setShowSearchTip(false);
            }
            callback(options);
          })
          .catch(() => {
            console.error("Something went wrong");
            // handle what happens when the api is down
            // handle what happens when no addresses are found
          });
      },
      300,
    ),
    [],
  );

  return (
    <div
      className={className}
      onBlur={() => {
        setShowSearchTip(false);
      }}
    >
      <AsyncSelect
        locked={locked}
        customRef={customRef}
        name="street-name"
        value={selectedAddress.streetName}
        loadOptions={loadStreetAddressOptionsDebounced}
        placeholder={intl.ADDRESS_LABEL}
        mandatoryErrorText={mandatoryErrorText}
        selectFromListErrorText={selectFromListErrorText}
        onChange={(input) => {
          const { data } = input as IInputOption;
          if (data) {
            onSelected(data as IAddressValidationModel);
            setShowSearchTip(false);
          }
        }}
        searchIcon={true}
        onInputChange={onInputChanged}
        searchString={queryText}
        formatOptionLabel={(input) => {
          const _input = input as IInputOption;
          if (!_input.data) {
            return undefined;
          }
          const { streetName, postalArea } =
            _input.data as IAddressValidationModel;
          return (
            <div className="inline-block" id="street-name-list">
              <HighlightMatch mainString={streetName} queryText={queryText} />
              <div className="inline-block pl-2 text-grey-1">
                <HighlightMatch mainString={postalArea} queryText={queryText} />
              </div>
            </div>
          );
        }}
        showSearchTip={showSearchTip}
      />
      {showSearchTip && <FilterSearchTip />}
    </div>
  );
};

const HighlightMatch = ({
  mainString,
  queryText,
}: {
  mainString: string;
  queryText: string;
}) => {
  const indexOfMatch = mainString
    .toLowerCase()
    .indexOf(queryText.toLowerCase());

  if (indexOfMatch === -1) {
    return <span>{mainString}</span>;
  }

  const beforeMatch = mainString.substring(0, indexOfMatch);
  const match = mainString.substring(
    indexOfMatch,
    indexOfMatch + queryText.length,
  );
  const afterMatch = mainString.substring(indexOfMatch + queryText.length);

  return (
    <span>
      {beforeMatch}
      <strong>{match}</strong>
      {afterMatch}
    </span>
  );
};

const FilterSearchTip = () => {
  return (
    <div className="flex items-center text-sm">
      <LightBulb />
      <span className="pl-2">
        <strong>{intl.TIP}</strong>
        {intl.WRITE_STREET_NAME}
        <strong className="px-1 mx-1 rounded bg-grey-3">,</strong>
        {intl.AND}
        <strong className="px-1 mx-1 rounded bg-grey-3">{intl.CITY}</strong>
      </span>
    </div>
  );
};

export default StreetNameSelectInput;
