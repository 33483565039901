import { Ref, useState, useEffect, useId } from "react";
import clsx from "clsx";
import Select, { ActionMeta, StylesConfig } from "react-select";
import { default as ReactSelect } from "react-select/async";
import { Check, ChevronDown, Locked, Search } from "@chef/icons/small";
import SubText from "../../internals/SubText";

interface AsyncSelectProps {
  searchString?: string;
  loadOptions?: (
    value: string,
    callback: (data: { value: string; label: string; data: any }[]) => void,
  ) => void;
  options?: { value: string; label: string; data: any }[];
  defaultOptions?: { value: string; label: string; data: any }[];
  name?: string;
  placeholder?: string;
  onChange: (newValue: unknown, actionMeta: ActionMeta<unknown>) => void;
  onInputChange?: (value: string) => void;
  value: string;
  formatOptionLabel?: (option: unknown) => React.ReactNode;
  customRef?: Ref<any>;
  noOptionsMessage?: (obj: { inputValue: string }) => React.ReactNode | string;
  onFocus?: any;
  isLoading?: boolean | undefined;
  autofocus?: boolean;
  className?: string;
  mandatoryErrorText?: string;
  selectFromListErrorText?: string;
  isSearchable?: boolean;
  loadingMessage?: string;
  searchIcon?: boolean;
  disabled?: boolean;
  defaultLabel?: string;
  defaultValue?: string;
  locked?: boolean;
  showSearchTip?: boolean;
}

export const AsyncSelect = ({
  searchString,
  loadOptions,
  defaultOptions,
  name,
  placeholder,
  onChange,
  onInputChange,
  value,
  formatOptionLabel,
  customRef,
  noOptionsMessage = () => false,
  onFocus,
  isLoading,
  autofocus,
  className,
  mandatoryErrorText = "",
  selectFromListErrorText = "",
  loadingMessage = "",
  isSearchable = true,
  options,
  searchIcon,
  disabled,
  defaultValue,
  defaultLabel,
  locked,
  showSearchTip = false,
}: AsyncSelectProps) => {
  const [error, setError] = useState("");
  const id = useId();

  const defaultSelectedValue =
    defaultLabel && defaultValue
      ? { value: defaultValue, label: defaultLabel }
      : undefined;

  const Label = () => {
    return (
      <label
        className={clsx(
          " pointer-events-none opacity-0 absolute px-2 left-2 transition-all ease-in-out top-2.5 duration-75",
          hasValue && "!opacity-100 -top-2.5 bg-white text-xs text-black",
        )}
        htmlFor={name}
      >
        {placeholder}
      </label>
    );
  };

  const dynamicMenuStyle: StylesConfig = {
    menu: (provided) => ({
      ...provided,
      marginTop: showSearchTip ? "2rem" : "0.5rem",
      backgroundColor: "white",
      borderRadius: "0.125rem",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    }),
  };
  const styles: StylesConfig = { ...customStyles, ...dynamicMenuStyle };

  const _disabled = locked ? locked : disabled;

  const selectProps = {
    className: clsx(
      "a11y-focus-within:focus-ring focus-within:!border-black rounded border-1.5 border-black",
      value && !_disabled && "!border-information",
      _disabled && "!border-grey-1",
      error && "!border-error",
    ),
    classNamePrefix: "address-select",
    name,
    inputId: `test-${name}`,
    unstyled: true,
    styles,
    openMenuOnFocus: true,
    autoFocus: autofocus,
    placeholder,
    ref: customRef,
    noOptionsMessage,
    onChange: (newValue: unknown, actionMeta: ActionMeta<unknown>) => {
      onChange(newValue, actionMeta);
      setError("");
    },
    isDisabled: _disabled,
    formatOptionLabel,
    components: {
      DropdownIndicator: () =>
        // DropdownIndicator is the "placement" of the icon, the icon itself is customizable
        {
          if (locked) {
            return (
              <div className="pr-4 text-grey-1">
                <Locked />
              </div>
            );
          }
          if (value) {
            return (
              <div className="pr-4 text-information">
                <Check />
              </div>
            );
          }
          if (searchIcon) {
            return (
              <div className="pr-4 text-grey-1">
                <Search />
              </div>
            );
          }
          if (!isSearchable) {
            return (
              <div className="pr-4 text-grey-1">
                <ChevronDown />
              </div>
            );
          }
          return null;
        },
    },
  };

  const asyncSelectProps = {
    loadOptions,
    defaultOptions,
    inputValue: value || searchString,
    loadingMessage: () => loadingMessage,
    controlShouldRenderValue: false,
    openMenuOnClick: true,

    isLoading,
  };

  const syncSelectProps = {
    isSearchable,
    options,
    defaultValue: defaultSelectedValue,
  };

  useEffect(() => {
    if (value) {
      setError("");
    }
  }, [value]);

  const hasValue = searchString || value;

  // To make it async both loadOptions and onInputChange must be provided
  if (loadOptions && onInputChange) {
    return (
      <div
        className={clsx(
          "relative",
          className,
          _disabled && "!cursor-not-allowed",
        )}
      >
        <ReactSelect
          {...selectProps}
          {...asyncSelectProps}
          instanceId={id}
          onInputChange={(input, { action }) => {
            if (action === "input-change") {
              onInputChange(input);
              setError("");
            }
            if (action === "input-blur" && value) {
              setError("");
            } else if (action === "input-blur" && !searchString) {
              setError(mandatoryErrorText);
            } else if (action === "input-blur" && !value && searchString) {
              setError(selectFromListErrorText);
            }
          }}
          onFocus={(e) => {
            if (typeof customRef !== "function" && customRef?.current) {
              customRef.current.onInputChange(searchString, {
                prevInputValue: "",
                action: "set-value",
              });
            }
            if (searchString) {
              setError("");
            }
            if (onFocus) {
              onFocus(e);
            }
          }}
        />
        {placeholder && <Label />}
        <SubText error={error} htmlFor={name} />
      </div>
    );
  }

  return (
    <div
      className={clsx(
        "relative",
        className,
        _disabled && "!cursor-not-allowed",
      )}
    >
      <Select {...selectProps} {...syncSelectProps} />
      {placeholder && <Label />}
      <SubText error={error} htmlFor={name} />
    </div>
  );
};

const customStyles: StylesConfig = {
  control: (provided, state) => ({
    ...provided,
    height: 48,
    boxShadow: "none",
    borderRadius: "0.25rem",
    backgroundColor: state.isDisabled ? "rgba(var(--background))" : "white",
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingLeft: "1rem",
  }),
  input: (provided) => ({
    ...provided,
    visibility: "visible",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "rgba(var(--grey1))",
  }),
  loadingIndicator: (provided) => ({
    ...provided,
    paddingRight: "1rem",
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "white",
    marginTop: "0.5rem",
    borderRadius: "0.125rem",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "rgba(var(--highlight))" : "white",
    padding: "0.5rem",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    paddingRight: "0.875rem",
    color: "grey",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
  }),
};
