import { useState } from "react";
import type {
  UseFormReturn,
  FieldValues,
  UseFormRegisterReturn,
  UseFormSetValue,
  UseFormReset,
} from "react-hook-form";
import { useForm } from "react-hook-form";
import { DeepPartial } from "utility-types";
import { useCallbackRef } from "./useCallbackRef";

export interface UseExtendedFormReturn<T extends FieldValues> {
  formValues: DeepPartial<T>;
}

export const useExtendedForm = <T extends FieldValues>(
  props: Parameters<typeof useForm<T>>[0],
): UseFormReturn<T, any> & UseExtendedFormReturn<T> => {
  const form = useForm<T>(props);

  const [formValues, setFormValues] = useState<DeepPartial<T>>(
    props?.defaultValues || ({} as any),
  );

  const setValue = useCallbackRef<UseFormSetValue<T>>(
    (name, value, options) => {
      setFormValues((prev) => ({ ...prev, [name]: value }));

      return form.setValue(name, value, options);
    },
  );

  const register = useCallbackRef<UseFormReturn<T, any>["register"]>(
    (name, options) => {
      const originalProps = form.register(name, options);

      // re-create onChange & onBlur so we can have a controlled value
      const onChange: UseFormRegisterReturn["onChange"] = (event) => {
        const type = event.target.type;

        if (type === "checkbox") {
          setFormValues((prev) => ({
            ...prev,
            [name]: event.target.checked,
          }));
        } else {
          setFormValues((prev) => ({
            ...prev,
            [name]: event.target.value,
          }));
        }

        return originalProps.onChange(event);
      };

      const onBlur: UseFormRegisterReturn["onBlur"] = (event) => {
        setFormValues((prev) => ({
          ...prev,
          [name]:
            event.target.type === "checkbox"
              ? event.target.checked
              : event.target.value,
        }));

        return originalProps.onBlur(event);
      };

      const value = formValues?.[name];

      const extendedProps = {
        error:
          form.formState.touchedFields[name] &&
          form.formState.errors[name]?.message,
        valid: form.formState.dirtyFields[name] && !form.formState.errors[name],
        onChange,
        onBlur,
        ...(typeof value === "boolean" ? { checked: value } : { value }),
      };

      const props = { ...originalProps, ...extendedProps };
      return props;
    },
  );

  const reset = useCallbackRef<UseFormReset<T>>((values) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setFormValues(values as any); // I don't think this should cause any issues, but look into it if there's issues with form state and resetting
    return form.reset(values);
  });

  return {
    ...form,
    register,
    formValues,
    setValue,
    reset,
  };
};
