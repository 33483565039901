import clsx from "clsx";
import { HTMLAttributes } from "react";

interface ChatBoxProps extends HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  noPadding?: boolean;
  background?: "information" | "highlightt" | "error";
}

export const ChatBox = ({
  noPadding = false,
  background = "information",
  className,
  children,
  id,
  ...props
}: ChatBoxProps) => {
  return (
    <div
      className={clsx(
        {
          "bg-informationBG": background === "information",
          "bg-highlightBG": background === "highlightt",
          "bg-errorBG": background === "error",
        },
        !noPadding && "p-4",
        className,
        "triangle rounded w-full mt-6 text-center relative",
      )}
      id={id}
      {...props}
    >
      {children}
    </div>
  );
};
