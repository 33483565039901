export const PAYMENT_PARTNER_IDS = {
  ADYEN: "0B25518E-4871-46FE-9494-036D6F64A6D6",
  PAYEX: "4501DDEB-8036-4CF4-87B2-53348DC847CE",
  COLLECTOR: "815224F7-071E-40FB-A9D0-BEC6E233E190",
  AVIDA: "3E495CC9-7985-43DA-9ED5-E8139867E1C3",
  VISMA: "277C1D57-DFE3-4374-91B8-F097FB5BE51E",
  VIPPS_MOBILEPAY: "E391158A-9BA3-478A-A83D-C8C5A3CA86C9",
} as const;

export const PAYMENT_METHODS = {
  INVOICE: "Invoice",
  CARD: "CC",
} as const;

export type PaymentMethod =
  typeof PAYMENT_METHODS[keyof typeof PAYMENT_METHODS];

export const CC_TYPES = {
  CARD: "card",
  SCHEME: "scheme",
} as const;

export const PAYMENT_STATUS_IDS = {
  GUARDED: "1",
  NOT_FINISHED: "2",
  DEBT_COLLECTION: "3",
  REPURCHASED: "4",
  CANCELED: "5",
  REPAID: "6",
  PARTIALLY_REPAID: "7",
  NOT_PAID: "8",
  REMINDER_GENERATED: "9",
  CREDITED: "10",
  REJECTED: "11",
  PAID: "12",
  PARTIALLY_CREDITED: "13",
  PORTFOLIO: "14",
} as const;

export const CREDIT_TYPE_IDS = {
  LOYALTY_CARD_CREDIT: "5C839146-6E96-4CE0-B04E-49B53B620713",
  PRP: "5BF0EA67-8BDC-4EA3-82E0-5F027018F4C9",
  CREDIT: "1CB859E8-CD01-4FFC-A742-6B5F8A8F6953",
  DEBT_TO_CUSTOMER_CREDITS: "23CAA548-4A74-47A2-8714-A98D4EF0C323",
  DEBT_TO_CUSTOMER_EXTRA_PAYMENT: "B734AF01-A79D-4549-87CB-B8BF96F6C681",
  GIFT_CARD_CREDIT: "3791400E-E799-443E-A833-F632A5A91E6D",
} as const;
