/**
 * Turns the first character of a string to uppercase.
 * @param {String} str string to capitalize
 * @returns {String}
 * @example capitalize("foo")); // returns "Foo"
 */
const capitalize = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

/**
 *  Turns the first character of a string to lowercase.
 * @param {String} str  string to decapitalize
 * @returns {String}
 * @example decapitalize("Foo")); // returns "foo"
 */
const decapitalize = (str: string): string => {
  return str.charAt(0).toLowerCase() + str.slice(1);
};

export { capitalize, decapitalize };
