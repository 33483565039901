import { AnimatePresence, SVGMotionProps, motion } from "framer-motion";

interface AnimatedHeartProps extends SVGMotionProps<SVGSVGElement> {
  isFilled: boolean;
}

export const AnimatedHeart = ({ isFilled, ...props }: AnimatedHeartProps) => {
  return (
    <AnimatePresence>
      <motion.svg
        width="100%"
        height="100%"
        viewBox="0 0 16 16"
        stroke="none"
        fill={isFilled ? "currentColor" : "none"}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        {isFilled && (
          <motion.circle
            key="favorite-circle"
            initial={{
              scale: 0,
              opacity: 1,
            }}
            animate={{
              scale: isFilled ? [0, 1] : 0,
              opacity: isFilled ? [1, 0] : 0,
            }}
            transition={{
              duration: 0.4,
            }}
            opacity="1"
            cx="7"
            cy="7"
            r="7"
            fill="inherit"
            fillRule="evenodd"
            clipRule="evenodd"
          />
        )}

        {isFilled && (
          <motion.path
            layoutId="heart-icon"
            d="M11.5969 1C10.0369 1 8.50087 2.02667 7.99687 3.44444C7.49287 2.02667 5.95687 1 4.39687 1C3.4421 1 2.52642 1.38631 1.85129 2.07394C1.17616 2.76158 0.796875 3.69421 0.796875 4.66667C0.796875 7.84444 3.43687 11.0222 7.99687 14.2C12.5569 11.0222 15.1969 7.84444 15.1969 4.66667C15.1969 3.69421 14.8176 2.76158 14.1425 2.07394C13.4673 1.38631 12.5517 1 11.5969 1V1Z"
            strokeMiterlimit="5"
            fill="currentColor"
            fillRule="evenodd"
            clipRule="evenodd"
            initial={{ scale: 0 }}
            animate={{
              scale: isFilled ? 0.75 : 0,
              transition: { type: "spring", stiffness: 350, damping: 12 },
            }}
          />
        )}
        <motion.path
          d="M11.5969 1C10.0369 1 8.50087 2.02667 7.99687 3.44444C7.49287 2.02667 5.95687 1 4.39687 1C3.4421 1 2.52642 1.38631 1.85129 2.07394C1.17616 2.76158 0.796875 3.69421 0.796875 4.66667C0.796875 7.84444 3.43687 11.0222 7.99687 14.2C12.5569 11.0222 15.1969 7.84444 15.1969 4.66667C15.1969 3.69421 14.8176 2.76158 14.1425 2.07394C13.4673 1.38631 12.5517 1 11.5969 1V1Z"
          strokeMiterlimit="5"
          fill="none"
          stroke="#1D1C1A"
          fillRule="evenodd"
          clipRule="evenodd"
          initial={{ strokeWidth: 1.25, scale: 0.75 }}
          animate={{
            opacity: isFilled ? 0 : 1,
            transition: { type: "linear", duration: 0.15 },
          }}
        />
      </motion.svg>
    </AnimatePresence>
  );
};
