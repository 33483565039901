import {
  createApi,
  fetchBaseQuery,
  BaseQueryFn,
  FetchArgs,
} from "@reduxjs/toolkit/query/react";
import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";
import { GraphQLClient } from "graphql-request";
import { DocumentNode } from "graphql";
import { HYDRATE } from "next-redux-wrapper";

import { isBrowser, isNode } from "@chef/utils/runtime";

import { getAgreementId } from "./user";
import { getLoginCookieToken } from "../util";

interface Headers {
  Authorization?: string;
  "x-current-url"?: string;
  "x-agreement-id"?: string;
  "apollographql-client-name"?: string;
  "apollographql-client-version"?: string;
}

const getAuthorization = () => {
  const token = getLoginCookieToken();

  if (!token) {
    return "";
  }

  return `Bearer ${token}`;
};

const getGraphQLUrl = () => {
  if (isBrowser()) {
    try {
      const url = new URL(window.location.origin);
      url.pathname = "/api/graphql";

      return url.toString();
    } catch {
      return process.env.REACT_APP_GRAPHV2_URL;
    }
  } else {
    return process.env.REACT_APP_GRAPHV2_URL;
  }
};

export const client = new GraphQLClient(getGraphQLUrl(), {
  // it complains about graphClientHeaders since it's custom keys -- suppressing it here
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  headers: {
    Accept: "*/*, application/json",
    Authorization: getAuthorization(),
  },
});

export interface GraphQLClientQuery {
  document: string | DocumentNode;
  variables?: any;
}

export interface ExtraOptionsArgs {
  companyId?: string;
  language?: string;
  agreementId?: boolean;
}

const customBaseQuery = (): BaseQueryFn<
  GraphQLClientQuery | string | FetchArgs,
  unknown,
  unknown,
  ExtraOptionsArgs
> => {
  const graphClient = graphqlRequestBaseQuery({
    client,
    /*
      supressing here because it expects a `new Headers` class/object here, but we're not using it
      due to it making keys lower case. The library itself does not care if it's a Header class or a raw object.
    */
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    prepareHeaders: () => {
      // ignoring the argument passed because we don't give it any headers, so it'll be an empty object

      const headers: Headers = {};

      if (typeof window !== "undefined") {
        headers["x-current-url"] = window.location.href;
      }

      const agreementId = getAgreementId();
      if (agreementId) {
        headers["x-agreement-id"] = agreementId.toString();
      }

      headers["apollographql-client-name"] = process.env.NX_APP_NAME;
      headers["apollographql-client-version"] = `${
        process.env.NX_APP_VERSION
      }-${isNode() ? "server" : "client"}`;

      /*
        this is needed because on the initial page load, there is a chance that the cookie
        hasn't gotten `httpOnly` removed yet. If this happens, it means
        that all requests will fail. The workaround is to have this function get called
        for all requests, it will fail the first time around. But because we use "amountOfRetries"
        it will succeed on the second attempt, because the cookie will be set by that point.
      */
      const auth = getAuthorization();

      if (auth) {
        headers.Authorization = auth;
      }

      return headers;
    },
  });

  const fetchClient = fetchBaseQuery({ baseUrl: "/api" });

  const createFetchHeaders = () => ({ Authorization: getAuthorization() });

  return async (args, api, extraOptions) => {
    if (typeof args === "string") {
      if (isNode()) {
        console.warn(
          "fetchClient was initiated on the server, but this is not supported because it does not know the base URL",
        );
      }

      return fetchClient(
        { url: args, headers: createFetchHeaders() },
        api,
        extraOptions,
      );
    }

    if ("url" in args && args.url) {
      if (isNode()) {
        console.warn(
          "fetchClient was initiated on the server, but this is not supported because it does not know the base URL",
        );
      }

      return fetchClient(
        { ...args, headers: createFetchHeaders() },
        api,
        extraOptions,
      );
    }

    // this case should be impossible -- just doing it so TS doesn't complain
    if (!("document" in args)) {
      throw new Error("Missing document");
    }

    // we supply companyId here to the variables so we don't need to pass it for every request
    if (extraOptions?.companyId) {
      args.variables = {
        ...args.variables,
        companyId: extraOptions.companyId,
      };
    }

    // we supply language here to the variables so we don't need to pass it for every request
    if (extraOptions?.language) {
      args.variables = {
        ...args.variables,
        language: extraOptions.language,
      };
    }

    // we supply agreementId here to the variables so we don't need to pass it for every request
    if (extraOptions?.agreementId) {
      const agreementId = getAgreementId();

      if (!agreementId && process.env.NODE_ENV === "development") {
        console.warn(
          "agreementId is not set when trying to call an endpoint requiring it",
        );
      }

      args.variables = {
        ...args.variables,
        agreementId,
      };
    }

    const amountOfRetries = isNode() ? 3 : 2;
    for (let retry = 0; retry < amountOfRetries; retry++) {
      const res = await graphClient(
        args,
        api,
        extraOptions as Parameters<typeof graphClient>[2],
      );

      // check if we didn't get any error
      if (!res.error) {
        return res;
      }

      // check if last one
      if (retry === amountOfRetries - 1) {
        return res;
      }
    }

    throw new Error("Unreachable reached");
  };
};

const hydrateListeners: ((data: any) => void)[] = [];
export const listenToHydrate = (cb: (data: any) => void) => {
  hydrateListeners.push(cb);
};

export const query = customBaseQuery();
export const api = createApi({
  baseQuery: query,
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      for (const cb of hydrateListeners) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        cb(action.payload[reducerPath]);
      }

      // not sure why "payload" doesn't exist on the type, but it exists in reality
      // https://redux-toolkit.js.org/rtk-query/usage/server-side-rendering
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return action.payload[reducerPath];
    }
  },
  endpoints: () => ({}),
  keepUnusedDataFor: 3600,
});
