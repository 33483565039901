import { useEffect, useRef, useState } from "react";
import clsx from "clsx";

import { isEqualStrings } from "@chef/utils/equal";

import { ChevronLeft, ChevronRight } from "@chef/icons/small";

import { Chip } from "../components";
import { Language } from "../../types";

interface FilterNavigationProps<T extends number | string> {
  language: Language;
  items: {
    value: T;
    label: string;
    count: number;
    type: "default" | "highlighted" | "secondary";
    isAll: boolean;
  }[];
  value: T;
  onChange: (newValue: T) => void;

  showGradients?: boolean;
  leftClassName?: string;
  rightClassName?: string;
  className?: string;
}

const FilterNavigation = <T extends number | string>({
  language = "no",
  items = [],
  value,
  onChange = () => null,
  showGradients = true,
  leftClassName = "bg-gradient-to-r from-white via-white",
  rightClassName = "bg-gradient-to-l from-white via-white",
  className,
}: FilterNavigationProps<T>) => {
  const [showControls, setShowControls] = useState(false);
  const [stepLeftDisabled, setStepLeftDisabled] = useState(true);
  const [stepRightDisabled, setStepRightDisabled] = useState(false);

  const intl = {
    no: {
      LEFT: "venstre",
      RIGHT: "høyre",
    },
    se: {
      LEFT: "vänster",
      RIGHT: "höger",
    },
    dk: {
      LEFT: "venstre",
      RIGHT: "højre",
    },
  };

  const { LEFT, RIGHT } = intl[language];

  const wrapper = useRef<HTMLUListElement | null>(null);

  const handleResize = () => {
    if (!wrapper.current) {
      return;
    }
    setShowControls(wrapper.current.scrollWidth > wrapper.current.clientWidth);
  };

  useEffect(() => {
    if (!wrapper.current) {
      return;
    }

    handleResize();
    window.addEventListener("resize", handleResize);

    wrapper.current.addEventListener("wheel", (evt) => {
      if (!wrapper.current) {
        return;
      }

      evt.preventDefault();
      wrapper.current.scrollLeft += evt.deltaX;
      wrapper.current.scrollLeft += evt.deltaY;
    });

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(handleResize, [items.length]);

  const handleStepLeft = () => {
    if (!wrapper.current) {
      return;
    }

    wrapper.current.scrollTo({
      left:
        wrapper.current.scrollLeft - wrapper.current.children[0].clientWidth,
      behavior: "smooth",
    });
  };

  const handleStepRight = () => {
    if (!wrapper.current) {
      return;
    }

    wrapper.current.scrollTo({
      left:
        wrapper.current.scrollLeft + wrapper.current.children[0].clientWidth,
      behavior: "smooth",
    });
  };

  const handleScroll = (e: React.UIEvent<HTMLUListElement, UIEvent>) => {
    const target = e.target as HTMLUListElement;

    setStepLeftDisabled(target.scrollLeft === 0);
    //Adding 1 pixel margin error to target.scrollWidth because the scrollWidth is updating after interraction with the elements in the list
    setStepRightDisabled(
      target.scrollLeft + target.offsetWidth >= target.scrollWidth - 1,
    );
  };

  const handleClick = (value: T) => {
    onChange(value);
  };

  if (!items || items.length === 0) {
    return (
      <div className="flex space-x-2">
        {[...Array(3)].map((i) => (
          <Chip className="w-20 animate-pulse" key={i} />
        ))}
      </div>
    );
  }

  const vals = value
    .toString()
    .split(",")
    .filter((v) => v !== "");

  return (
    <div className="relative">
      <div
        className={clsx(
          "hidden md:flex absolute top-0 bottom-0 left-0 z-5 items-center w-24 pointer-events-none",
          showGradients && leftClassName,
          (!showControls || stepLeftDisabled) && "!hidden",
        )}
      >
        <button
          className="w-12 h-full pointer-events-auto"
          aria-label={LEFT}
          onClick={handleStepLeft}
          title={LEFT}
        >
          <ChevronLeft className="h-full" />
        </button>
      </div>

      <ul
        className={clsx(
          "relative flex gap-2 overflow-x-scroll scrollbar-hidden",
          className,
        )}
        id="filter-navigate"
        ref={wrapper}
        onScroll={handleScroll}
      >
        {items.map((item) => {
          const isHighlighted = item.type === "highlighted";

          const isSelected = vals.some((v) =>
            isEqualStrings(v, item.value.toString()),
          );

          return (
            <li key={item.value}>
              <Chip
                onClick={() => handleClick(item.value)}
                selected={isSelected}
                highlighted={isHighlighted}
                className="tabular-nums"
                id={item.value.toString()}
              >
                <span>{item.label}</span>
                {(!isSelected || item.isAll) && (
                  <span className="ml-2">({item.count})</span>
                )}
              </Chip>
            </li>
          );
        })}
      </ul>

      <div
        className={clsx(
          "hidden absolute top-0 bottom-0 right-0 md:flex items-center justify-end w-24 pointer-events-none z-1",
          showGradients && rightClassName,
          (!showControls || stepRightDisabled) && "!hidden",
        )}
      >
        <button
          title={RIGHT}
          className="w-12 h-full pointer-events-auto "
          onClick={handleStepRight}
          aria-label={RIGHT}
        >
          <ChevronRight className="h-full" />
        </button>
      </div>
    </div>
  );
};

export default FilterNavigation;
