import styled from "styled-components";
import { scaleInCenter } from "../../../helpers/animations";

const activeClassName = "nav-item-active";

/* TODO: Change to use another SVG for more on point color? */
const Item = styled("a")`
  flex: 1;
  position: relative;
  padding-bottom: 0.3rem;
  text-align: center;
  color: ${(props) => props.theme.colors.black};
  text-decoration: none;
  font-size: ${(props) => props.theme.typography.fontSizeTiny};
  border: 0;

  transition: opacity 300ms ease-in-out;
  will-change: opacity;
  opacity: 0.5;

  /* Active version */
  &.${activeClassName} {
    opacity: 1;
  }

  /* Active version
  &.${activeClassName} span:after {
    content: '';
    display: block;
    width: 30%;
    height: 3px;
    border-radius: 2px;
    background: ${(props) => props.theme.colors.primary};
    position: absolute;
    left: calc(35%);
    transform: translateX(-50%);
    transform-origin: center;
    margin: 5px 0;
    animation: ${scaleInCenter} 0.5s cubic-bezier(0.18, 0.89, 0.32, 1.28) both;
  }
  */
`;

export default Item;
