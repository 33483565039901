import { useLoyalty } from "@chef/feature-flags";
import { calculateOrderPoints } from "@chef/helpers";
import { intl } from "./LoyaltyPointsNotification.Intl";
import { BrandSpecificCoin } from "./BrandSpecificCoin";

export type PointsOrTotalCostOfOrder =
  | {
      totalCostOfOrder: number;
      points?: never;
    }
  | {
      totalCostOfOrder?: never;
      points: number;
    };

type LoyaltyPointsNotificationProps = {
  hasBeenDelivered?: boolean;
} & PointsOrTotalCostOfOrder;

export const LoyaltyPointsNotification = ({
  totalCostOfOrder,
  points,
  hasBeenDelivered,
}: LoyaltyPointsNotificationProps) => {
  const { data: loyaltyData } = useLoyalty();

  if (!loyaltyData) {
    return null;
  }

  const orderPoints = totalCostOfOrder
    ? calculateOrderPoints({
        totalSum: totalCostOfOrder,
        multiplier: loyaltyData.multiplier,
      })
    : points;

  return (
    <div className="flex items-center mt-4.5">
      <BrandSpecificCoin />

      <p className="ml-2 text-sm">
        {intl.YOUR_ORDER} {hasBeenDelivered ? intl.GAVE : intl.GIVES} {intl.YOU}{" "}
        <strong>
          {orderPoints} {intl.POINTS}
        </strong>
      </p>
    </div>
  );
};
