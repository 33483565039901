import styled, { css } from "styled-components";
import { media } from "../global-styles";

// The size map decides what gutters/spacing the Block gets
const spaces = {
  1: "8px",
  2: "16px",
  3: "24px",
  4: "32px",
  5: "40px",
  6: "56px",
  7: "60px",
  8: "120px",
  10: "130px",
};

const getSpacing = (space = 1) => spaces[space];

const SpaceBlock = styled.div`
  /* Left margin */
  ${(props) =>
    props.left &&
    css`
      margin-left: ${getSpacing(props.left)};
    `};
  ${(props) =>
    props.leftDesktop &&
    css`
      ${media.teen`
        margin-left: ${getSpacing(props.leftDesktop)};
      `};
    `};

  /* Right margin */
  ${(props) =>
    props.right &&
    css`
      margin-right: ${getSpacing(props.right)};
    `};
  ${(props) =>
    props.rightDesktop &&
    css`
      ${media.teen`
        margin-right: ${getSpacing(props.rightDesktop)};
        `};
    `};

  /* Top padding */
  ${(props) =>
    props.top &&
    css`
      padding-top: ${getSpacing(props.top)};
    `};
  ${(props) =>
    props.topDesktop &&
    css`
      ${media.teen`
            padding-top: ${getSpacing(props.topDesktop)};
          `};
    `};

  /* Bottom padding */
  ${(props) =>
    props.bottom &&
    css`
      padding-bottom: ${getSpacing(props.bottom)};
    `};
  ${(props) =>
    props.bottomDesktop &&
    css`
      ${media.teen`
            padding-bottom: ${getSpacing(props.bottomDesktop)};
          `};
    `};
`;

export default SpaceBlock;
