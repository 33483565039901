import styled, { css } from "styled-components";
import fluidUnit from "../helpers/fluidUnit";

const Large = styled.strong`
  ${fluidUnit({
    unit: "font-size",
    minUnit: "24px",
    maxUnit: "42px",
  })};
  font-weight: bold;
  line-height: 1.2;
  color: ${(props) => props.theme.colors.dark};

  ${(props) =>
    props.white &&
    css`
      color: white;
    `};

  ${(props) =>
    props.h2Size &&
    css`
      ${fluidUnit({
        unit: "font-size",
        minUnit: "24px",
        maxUnit: "30px",
      })};
      font-weight: bold;
      line-height: 1.3;
    `};

  ${(props) =>
    props.strongSize &&
    css`
      ${fluidUnit({
        unit: "font-size",
        minUnit: "20px",
        maxUnit: "25px",
      })};
      font-weight: normal;
      line-height: 1.3;
    `};
`;

const LargeUnderline = styled(Large)`
  padding: 0 5px;
  border-bottom: 3px solid ${(props) => props.theme.colors.primary};
`;

const Medium = styled.span`
  font-size: 20px;
  line-height: 1.3;
  color: ${(props) => props.theme.colors.dark};

  ${(props) =>
    props.white &&
    css`
      color: white;
    `};

  ${(props) =>
    props.capitalize &&
    css`
      text-transform: Capitalize;
    `};
`;

const NoWrap = styled.span`
  white-space: nowrap;
`;

const StrikeThrough = styled.span`
  text-decoration: line-through;
`;

const OpacityText = styled.span`
  color: ${(props) => props.theme.colors.dark};
  opacity: 0.6;

  ${(props) =>
    props.block &&
    css`
      display: block;
    `};
`;

const Light = styled.span`
  color: ${(props) => props.theme.colors.light};
`;

const Lowercase = styled.span`
  text-transform: lowercase;
`;

const ColorText = styled.span`
  ${(props) =>
    props.dark &&
    css`
      color: ${props.theme.colors.shades.black.base};
    `};

  ${(props) =>
    props.light &&
    css`
      color: ${props.theme.colors.light};
    `};

  ${(props) =>
    props.primary &&
    css`
      color: ${props.theme.colors.primary};
    `};

  ${(props) =>
    props.secondary &&
    css`
      color: ${props.theme.colors.secondary};
    `};

  ${(props) =>
    props.special &&
    css`
      color: ${props.theme.colors.special};
    `};

  > * {
    color: inherit !important;
  }
`;

const Capitalize = styled.span`
  text-transform: capitalize;
`;

const Excerpt = styled.span`
  font-size: ${(props) => props.theme.typography.fontSizeMedium};
  line-height: 155%;
`;

// Overpowered component, sorry about this...
const CustomText = styled.span`
  line-height: ${(props) => props.lineHeight} !important;
  font-size: ${(props) => props.fontSize} !important;
  font-weight: ${(props) => props.fontWeight} !important;
  width: ${(props) => props.limitWidth} !important;
  text-align: ${(props) => props.textAlign} !important;
  padding-bottom: ${(props) => props.paddingBottom} !important;
  padding-top: ${(props) => props.paddingTop} !important;
`;

export {
  CustomText,
  Large,
  LargeUnderline,
  Light,
  Lowercase,
  Medium,
  NoWrap,
  OpacityText,
  StrikeThrough,
  ColorText,
  Capitalize,
  Excerpt,
};
