import styled, { css } from "styled-components";

const Dot = styled.span`
  display: inline-block;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: ${(props) => props.theme.colors.primary};

  /* Special dot color */
  ${(props) =>
    props.special &&
    css`
      background: ${props.theme.colors.special} !important;
    `};

  /* Margin right (if in front of text) */
  ${(props) =>
    props.beforeText &&
    css`
      flex-shrink: 0;
      margin-top: 5px;
      margin-right: 10px;
    `};
`;

export default Dot;
