import styled from "styled-components";

const Text = styled.span`
  display: block;
  position: relative;
  font-size: 14px;

  margin-top: 10px;
`;

export default Text;
