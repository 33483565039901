import styled from "styled-components";
import { media } from "../../../global-styles";

import Tabs from "./Tabs";
import Item from "./Item";
import Text from "./Text";
import ImageWrapper from "./ImageWrapper";
import NotificationDot from "./NotificationDot";
import Spacer from "./Spacer";

const TabBar = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  will-change: top;

  ${media.adult`
    display: none;
  `};
`;

TabBar.Tabs = Tabs;
TabBar.Item = Item;
TabBar.Text = Text;
TabBar.ImageWrapper = ImageWrapper;
TabBar.NotificationDot = NotificationDot;
TabBar.Spacer = Spacer;

export default TabBar;
