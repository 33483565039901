import { PAYMENT_METHODS, PAYMENT_PARTNER_IDS } from "@chef/constants";
import { isEqualStrings } from "@chef/utils/equal";

export const getPaymentMethod = (
  paymentPartnerId: string,
): typeof PAYMENT_METHODS[keyof typeof PAYMENT_METHODS] => {
  return isEqualStrings(paymentPartnerId, PAYMENT_PARTNER_IDS.COLLECTOR)
    ? PAYMENT_METHODS.INVOICE
    : PAYMENT_METHODS.CARD;
};
