/* eslint-disable unused-imports/no-unused-vars */
import React from "react";
import styled, { css } from "styled-components";

import { variables } from "../../global-styles";

const activeClassName = "nav-item-active";

const aFilter = ({
  noUnderline,
  isPrimary,
  small,
  activeClassName,
  blackUnderline,
  block,
  white,
  primary,
  ...props
  // eslint-disable-next-line jsx-a11y/anchor-has-content
}) => <a {...props} />;

const buttonFilter = ({
  noUnderline,
  isPrimary,
  small,
  activeClassName,
  blackUnderline,
  block,
  ...props
}) => <button {...props} />;

const spanFilter = ({
  noUnderline,
  isPrimary,
  small,
  activeClassName,
  blackUnderline,
  block,
  ...props
}) => <span {...props} />;

/*eslint-enable*/

const LinkComponent = styled.div.attrs({
  activeClassName,
  tabIndex: "0",
})`
  font-size: ${(props) => props.theme.typography.fontSizeGlobalMobile};
  font-family: ${(props) => props.theme.typography.fontFamily};
  display: inline;
  color: currentColor;
  text-decoration: none;
  cursor: pointer;
  border-bottom: 1.5px solid ${(props) => props.theme.colors.somewhatDark};

  &:hover {
    border-color: ${(props) => props.theme.colors.primary};
  }

  border-bottom: ${variables.borderWidth} solid
    ${(props) => props.theme.colors.darkBeige};
  // border-bottom: ${variables.borderWidth} solid white;

  /* Active version */
  &.${activeClassName} {
    font-weight: 600;
  }

  /* No underline variant */
  ${(props) =>
    props.noUnderline &&
    css`
      border: 0;
    `};

  /* Black underline variant
  ${(props) =>
    props.blackUnderline &&
    css`
      border-bottom: ${variables.borderWidth} solid black;
    `};*/

  /* Display block variant */
  ${(props) =>
    props.block &&
    css`
      display: block;
    `};

  /* Small variant
  ${(props) =>
    props.small &&
    css`
      font-size: ${props.theme.typography.fontSizeSmall} !important;
    `}; */

  ${(props) =>
    props.inheritfontsize &&
    css`
      font-size: inherit;
    `};

  ${(props) =>
    props.inlineBlock &&
    css`
      display: inline-block;
    `};

  ${(props) =>
    props.primaryUnderline &&
    css`
      border-bottom: ${variables.borderWidth} solid
        ${props.theme.colors.primary};
    `};
`;

const FakeLink = LinkComponent.withComponent(spanFilter);
const LinkButton = LinkComponent.withComponent(buttonFilter);
const ExternalLink = LinkComponent.withComponent(aFilter);

export { ExternalLink, FakeLink, LinkButton };
