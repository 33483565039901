import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";

import {
  isConceptPreference,
  isMealboxProduct,
  isTastePreference,
} from "@chef/helpers";
import { PREFERENCE_TYPE_IDS, PRODUCT_TYPE_IDS } from "@chef/constants";
import { difference, isNotEmptyArray } from "@chef/utils/array";
import { isEqualStrings, isNotEqualStrings } from "@chef/utils/equal";
import { poll } from "@chef/utils/poll";

import {
  convertFromBillingToUpdateBillingAgreement,
  useLazyPreselectorJobStatusQuery,
  useGeneratePersonalizedDeviationsMutation,
  useUpdateBillingAgreementMutation,
  useUpdatePreferencesMutation,
  useUpdateUserConsentsMutation,
} from "../features";
import { useBillingQuery } from "../graphql/generated";
import { api, tags } from "../graphql/api";

import type { AppDispatch } from "..";

type TriggerArgs = {
  week: number;
  year: number;
  variationId?: string;
  conceptPreferenceIds?: string[];
  tastePreferenceIds?: string[];
  requiredConsentIds?: string[];
};

export enum STAGE {
  "IDLE",
  "UPDATING_CONSENTS",
  "UPDATING_PREFERENCES",
  "UPDATING_BILLING_AGREEMENT",
  "GENERATING_DEVIATIONS",
  "WAITING_FOR_COMPLETION",
  "DONE",
}

export const usePreselector = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { data: billingQuery } = useBillingQuery();

  const [stage, setStage] = useState<STAGE>(STAGE.IDLE);

  const [preselectorJobStatusTrigger] = useLazyPreselectorJobStatusQuery();

  const currentPreferences = billingQuery?.billing.preferences || [];
  const currentVariationId = billingQuery?.billing.baskets
    .find((b) => b.default)
    ?.basketProducts.find((p) =>
      isMealboxProduct(p.variation.product),
    )?.variationId;

  const currentConsentIds =
    billingQuery?.billing.consents
      .filter((c) => c.isAccepted)
      .map((c) => c.consentId) || [];

  const currentConceptPreferenceIds = currentPreferences
    .filter(isConceptPreference)
    .map((p) => p.preferenceId);

  const currentTastePreferenceIds = currentPreferences
    .filter(isTastePreference)
    .map((p) => p.preferenceId);

  const [generatePersonalizedDeviations] =
    useGeneratePersonalizedDeviationsMutation();

  const [updateBillingAgreement] = useUpdateBillingAgreementMutation();
  const [updatePreferences] = useUpdatePreferencesMutation();
  const [updateUserConsentsMutation] = useUpdateUserConsentsMutation();

  const trigger = useCallback(
    async (args: TriggerArgs) => {
      const {
        week,
        year,
        variationId,
        conceptPreferenceIds,
        tastePreferenceIds,
        requiredConsentIds,
      } = args;

      if (!billingQuery) {
        setStage(STAGE.DONE);
        return "FAILED";
      }

      const consentDifferences =
        requiredConsentIds &&
        difference(requiredConsentIds, currentConsentIds, isEqualStrings);

      const consentsAreMissing = !consentDifferences?.every(
        (diff) => !requiredConsentIds?.some((c) => isEqualStrings(c, diff)),
      );

      if (consentsAreMissing && requiredConsentIds) {
        setStage(STAGE.UPDATING_CONSENTS);

        await updateUserConsentsMutation(
          requiredConsentIds.map((id) => ({ consentId: id, isAccepted: true })),
        ).unwrap();
      }

      const preferencesHaveChanged =
        conceptPreferenceIds &&
        tastePreferenceIds &&
        isNotEmptyArray(
          difference(
            [...conceptPreferenceIds, ...tastePreferenceIds],
            [...currentConceptPreferenceIds, ...currentTastePreferenceIds],
            isEqualStrings,
          ),
        );

      if (preferencesHaveChanged) {
        setStage(STAGE.UPDATING_PREFERENCES);

        await updatePreferences([
          {
            preferenceTypeId: PREFERENCE_TYPE_IDS.CONCEPT,
            activePreferences: conceptPreferenceIds,
          },
          {
            preferenceTypeId: PREFERENCE_TYPE_IDS.TASTE,
            activePreferences: tastePreferenceIds,
          },
        ]).unwrap();
      }

      if (isNotEqualStrings(variationId, currentVariationId) && variationId) {
        setStage(STAGE.UPDATING_BILLING_AGREEMENT);

        await updateBillingAgreement({
          _suppressNotification: true,
          ...convertFromBillingToUpdateBillingAgreement({
            ...billingQuery.billing,
            baskets: billingQuery.billing.baskets.map((basket) => {
              if (!basket.default) {
                return basket;
              }

              const addonProducts = basket.basketProducts.filter((bp) => {
                const isMealbox =
                  bp.variation.product.productTypeId ===
                  PRODUCT_TYPE_IDS.MEALBOX;

                return !isMealbox;
              });

              return {
                ...basket,
                basketProducts: [
                  ...addonProducts,
                  {
                    quantity: 1,
                    variationId: variationId,
                  } as any,
                ],
              };
            }),
          }),
        }).unwrap();
      }

      setStage(STAGE.GENERATING_DEVIATIONS);

      const { CorrelationId } = await generatePersonalizedDeviations({
        startWeek: week,
        startYear: year,
        range: 5,
      }).unwrap();

      setStage(STAGE.WAITING_FOR_COMPLETION);

      const checkForCompletion = async () => {
        const { data } = await preselectorJobStatusTrigger({
          correlationId: CorrelationId,
        });
        if (data.requestStatus === "COMPLETED") {
          return;
        } else {
          throw new Error(data.requestStatus);
        }
      };
      const status = await poll(
        checkForCompletion,
        2000,
        (message, retries) => {
          if (retries > 30) {
            return true;
          }

          if (
            message === "FAILED" ||
            message === "NONE" ||
            message === "NOT_FOUND"
          ) {
            return true;
          }

          return false;
        },
      )
        .then(() => {
          // This will bust the cache for the calendar, forcing a refetch
          dispatch(api.util.invalidateTags([tags.calendar]));

          return "COMPLETED" as const;
        })
        .catch(() => {
          return "FAILED" as const;
        });

      setStage(STAGE.DONE);

      return status;
    },
    [generatePersonalizedDeviations, updateBillingAgreement, updatePreferences],
  );

  return { trigger, stage };
};
