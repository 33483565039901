/**
 * @module equal
 * @category utils
 */

/**
 * Check to test if to strings are the same
 * @param {String} a
 * @param {String} b
 * @returns {Boolean}
 * @example isEqualStrings("foo", "Foo"); // returns true
 * @example isEqualStrings("foo", "bar"); // returns false
 */
function isEqualStrings(a = "", b = "") {
  if (!a || !b) {
    return false;
  }
  return a.toLowerCase() === b.toLowerCase();
}

function isNotEqualStrings(a = "", b = "") {
  return !isEqualStrings(a, b);
}

function isDeepEquals(a: any, b: any) {
  if (a === b) {
    return true;
  }
  if (a === null || b === null) {
    return false;
  }
  if (typeof a !== "object" || typeof b !== "object") {
    return false;
  }

  const aKeys = Object.keys(a);
  const bKeys = Object.keys(b);

  if (aKeys.length !== bKeys.length) {
    return false;
  }

  for (let i = 0; i < aKeys.length; i++) {
    if (!bKeys.includes(aKeys[i])) {
      return false;
    }
    if (!isDeepEquals(a[aKeys[i]], b[aKeys[i]])) {
      return false;
    }
  }

  return true;
}

export { isEqualStrings, isNotEqualStrings, isDeepEquals };
