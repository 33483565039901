import { ButtonCircle } from "@chef/components";
import { ChevronDown, ChevronUp } from "@chef/icons/small";

import { intl } from "./PortionsSelector.Intl";
import { getPlusPortionString } from "../../utils/recipe";

interface PortionsSelectorProps {
  portionSizes: string[];
  setSelectedPortionSizeIndex: (newValue: number) => void;
  selectedPortionSizeIndex: number;
  disableSelectPortions: boolean;
}

export const PortionsSelector = ({
  portionSizes,
  setSelectedPortionSizeIndex,
  selectedPortionSizeIndex,
  disableSelectPortions,
}: PortionsSelectorProps) => {
  const currentPortionSize = portionSizes[selectedPortionSizeIndex];

  const createPortionSizeString = () => {
    const portionSize = currentPortionSize.replace("+", "");

    if (disableSelectPortions) {
      return `${portionSize} ${intl.PORTIONS}`;
    }

    return `${portionSize} pers${getPlusPortionString(currentPortionSize)}`;
  };

  const portionSizeString = createPortionSizeString();

  const isMinPortionSize = selectedPortionSizeIndex === 0;
  const isMaxPortionSize = selectedPortionSizeIndex === portionSizes.length - 1;

  return (
    <div className="flex items-center justify-between w-full">
      <h2 className="text-xl">
        <strong>Ingredienser</strong>
      </h2>

      <div className="print:hidden">
        {disableSelectPortions ? (
          <div>{portionSizeString}</div>
        ) : (
          <div className="flex items-center gap-3 mr-0 md:mr-4">
            <ButtonCircle
              onClick={() =>
                setSelectedPortionSizeIndex(selectedPortionSizeIndex - 1)
              }
              Icon={ChevronDown}
              className="bg-grey-2"
              disabled={isMinPortionSize}
            />
            <div>{portionSizeString}</div>
            <ButtonCircle
              onClick={() =>
                setSelectedPortionSizeIndex(selectedPortionSizeIndex + 1)
              }
              Icon={ChevronUp}
              className="bg-grey-2"
              disabled={isMaxPortionSize}
            />
          </div>
        )}
      </div>

      <div className="hidden print:block">{portionSizeString}</div>
    </div>
  );
};
