import clsx from "clsx";
import Image from "next/legacy/image";

import { Container } from "@chef/components";
import { BRAND_NAME_FULL, LOGO } from "@chef/constants";

interface LogoImageProps {
  className?: string;
}

interface PageLoaderProps {
  wholeScreen?: boolean;
}

const LogoImage = ({ className }: LogoImageProps) => (
  <>
    <div className={clsx("hidden md:block", className || "w-36")}>
      <Image
        src={LOGO.DEFAULT}
        alt={`${BRAND_NAME_FULL} logo`}
        width="148"
        height="32"
        layout="responsive"
        priority
      />
    </div>
    <div className={clsx("md:hidden", className || "w-10")}>
      <Image
        src={LOGO.EMBLEM}
        alt={`${BRAND_NAME_FULL} logo`}
        width="32"
        height="32"
        layout="responsive"
        priority
      />
    </div>
  </>
);

export const PageLoader = ({ wholeScreen }: PageLoaderProps) => {
  if (wholeScreen) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-background/50 backdrop-blur-lg z-max">
        <LogoImage className="w-32 animate-bounce md:w-64" />
      </div>
    );
  }

  return (
    <Container outerClassName="flex-grow flex items-center justify-center bg-background">
      <LogoImage className="w-32 animate-bounce md:w-64" />
    </Container>
  );
};
