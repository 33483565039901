import React from "react";

import { Loader, LoaderIcon, Block } from "../../primitives";

function LoaderContainer(props) {
  const { isFullPage, isWholeScreen } = props;

  return (
    <div key="loading">
      <Loader isFullPage={isFullPage} isWholeScreen={isWholeScreen}>
        <LoaderIcon>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 283.4 283.4">
            <title>loader</title>
            <g id="Layer_2" data-name="Layer 2">
              <g id="Layer_1-2" data-name="Layer 1">
                <g id="Layer_1-3" data-name="Layer 1">
                  <circle cx="141.7" cy="141.7" r="141.7" />
                  <path
                    className="loader-path"
                    d="M85,198l24-72S73,116,83,90s39-17,39-17,24.4,12.35,20,27c-6,20-35,4-4-23,0,0,27-15,42,12s-12,31-15,25,9.29-40,45-17c28,18-1,68-33,50l-16,69Z"
                  />
                </g>
              </g>
            </g>
          </svg>
        </LoaderIcon>

        {props.text && (
          <Block top={1}>
            <span>{props.text}</span>
          </Block>
        )}
      </Loader>
    </div>
  );
}

LoaderContainer.defaultProps = {
  text: "",
  isFullPage: false,
  isWholeScreen: false,
};

export default LoaderContainer;
