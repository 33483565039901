import { Tag } from "@chef/components";
import { Check, Squares } from "@chef/icons/small";
import { intl } from "./ImageTags.Intl";
import { CurrencyWrapper as Currency } from "./CurrencyWrapper";

interface ThriftyTagProps {
  price: number;
}

interface PlusProteinTagProps {
  hasPlusProtein?: boolean;
  inBasket?: boolean;
}

const PlusProteinTag = ({
  hasPlusProtein = false,
  inBasket = false,
}: PlusProteinTagProps) => {
  if (!hasPlusProtein) {
    return null;
  }

  return (
    <Tag white>
      {inBasket ? (
        <>
          <Check className="mr-1 shrink-0" />
          <span>{intl.EXTRA_PROTEIN_ADDED}</span>
        </>
      ) : (
        <>
          <Squares className="mr-1 shrink-0" />
          <span>{intl.EXTRA_PROTEIN_POSSIBLE}</span>
        </>
      )}
    </Tag>
  );
};

const ThriftyTag = ({ price }: ThriftyTagProps) => {
  if (price >= 0) {
    return null;
  }

  return (
    <Tag casing="normal-case" highlight>
      <div>{intl.GOOD_AND_AFFORDABLE}</div>
      <Currency prefix="-" className="ml-1">
        {price}
      </Currency>
    </Tag>
  );
};

export const ImageTags = ({
  price,
  hasPlusProtein,
  inBasket,
}: ThriftyTagProps & PlusProteinTagProps) => {
  return (
    <div className="absolute flex items-center h-5 p-1 text-xs left-1 bottom-2 md:bottom-3">
      <ThriftyTag price={price} />
      <PlusProteinTag hasPlusProtein={hasPlusProtein} inBasket={inBasket} />
    </div>
  );
};
