const keys = {
  SIGNUP: (id: string) => `signup:session:${id}` as const,
  CHANGE_PAYMENT_METHOD: (id: string) => `billing:session:${id}` as const,
  DIRECT_PAYMENT: (id: string) => `payment:session:${id}` as const,
  SHOW_VIPPS_AGREEMENT: (id: string) =>
    `show_vipps_agreement:session:${id}` as const,
} as const;

export type RedisOP = keyof typeof keys;

export const getRedisSessionKey = (op: RedisOP, id: string) => {
  if (!Object.prototype.hasOwnProperty.call(keys, op)) {
    throw new Error(`Invalid op: ${op}`);
  }

  const method = keys[op];

  if (!(typeof method === "function")) {
    throw new Error(`Invalid op: ${op}`);
  }

  return method(id) as ReturnType<typeof keys[RedisOP]>;
};
