import { useState, useRef, useEffect, useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";

import {
  IAddressValidationModel,
  IApartmentModel,
  useAddressValidationHealthCheckQuery,
  useLazyAddressValidationQuery,
} from "@chef/state-management";
import { Input, Skeleton } from "@chef/components";
import { BRAND_NAME } from "@chef/constants";
import { Locked } from "@chef/icons/small";

import { intl } from "./AddressForm.Intl";
import StreetNameSelectInput from "./StreetNameSelectInput";
import StreetNumberSelectInput from "./StreetNumberSelectInput";
import FloorNumberSelectInput from "./FloorNumberSelectInput";
import DoorNumberSelectInput from "./DoorNumberSelectInput";
import { AddressNotFoundDisclaimer } from "./AddressNotFoundDisclaimer";
import { DoNotDeliverDisclaimer } from "./DoNotDeliverDisclaimer";
import "./address-form.css";

export const QUERY_MODE = {
  STREET_NAME: 1, // auto suggest addresses by street name and postal area
  STREET_NUMBER: 2, // auto suggest address numbers by street name
  APARTMENT: 3, // auto suggest apartments and floors by street name and number
} as const;

export interface IInputOption {
  value: string;
  label: string;
  data: IAddressValidationModel | IApartmentModel | undefined;
}

interface IApartmentOption {
  value: string;
  label: string;
  data: IApartmentModel;
}

type InitialAddress = {
  streetName: string;
  streetNumber: string;
  letterExtra?: string;
  postalCode: string;
  postalArea: string;
  floorNumber?: string;
  door?: string | null;
  apartmentName?: string;
  validated: string;
};

interface AddressFormProps {
  doNotDeliver?: boolean;
  city?: string;
  initialAddress?: InitialAddress;
  locked?: boolean;
}

export const AddressForm = ({
  doNotDeliver,
  city,
  initialAddress,
  locked = false,
}: AddressFormProps) => {
  const [addressQuery] = useLazyAddressValidationQuery();

  const { isSuccess: isHealthCheckSuccess, isLoading: isHealthCheckLoading } =
    useAddressValidationHealthCheckQuery();

  const [streetNameQuery, setStreetNameQuery] = useState("");
  const [streetNumberQuery, setStreetNumberQuery] = useState("");
  const [noAddressFound, setNoAddressFound] = useState(false);

  const streetNameRef = useRef<HTMLInputElement>(null);
  const streetNumberRef = useRef<HTMLInputElement>(null);
  const floorNumberRef = useRef<HTMLInputElement>(null);
  const doorNumberRef = useRef<HTMLInputElement>(null);

  const { register, setValue } = useFormContext();

  const defaultAddress = useMemo(() => {
    return {
      searchText: "",
      streetName: initialAddress?.streetName || "",
      postalCode: initialAddress?.postalCode || "",
      postalArea: initialAddress?.postalArea || "",
      streetNumber: initialAddress?.streetNumber || "",
      letterExtra: initialAddress?.letterExtra || "",
      mode: 1,
      addressName: "",
      apartments: [],
      digitalKeyProvider: null,
    };
  }, [initialAddress]);

  const defaultApartment = {
    door: initialAddress?.door || "",
    floor: initialAddress?.floorNumber || "",
    name: initialAddress?.apartmentName || "",
  };

  // This should get and fetch values from the useExtendedForm context
  const [selectedAddress, setSelectedAddress] =
    useState<IAddressValidationModel>(defaultAddress);

  // This should get and fetch values from the useExtendedForm context
  const [selectedApartment, setSelectedApartment] =
    useState<IApartmentModel>(defaultApartment);

  const [floorOptions, setFloorOptions] = useState<IApartmentOption[]>([]);
  const [doorOptions, setDoorOptions] = useState<IApartmentOption[]>([]);
  const [filteredDoorOptions, setFilteredDoorOptions] = useState<
    IApartmentOption[]
  >([]);

  const emptyAddress = useMemo(() => {
    return {
      searchText: "",
      streetName: "",
      postalCode: "",
      postalArea: "",
      streetNumber: "",
      letterExtra: "",
      mode: 1,
      addressName: "",
      apartments: [],
      digitalKeyProvider: null,
    };
  }, []);

  const emptyApartment = {
    door: "",
    floor: "",
    name: "",
  };

  const handleStreetNameQueryChanged = (value: string) => {
    setSelectedAddress(emptyAddress);
    setStreetNameQuery(value);
    setSelectedApartment(emptyApartment);
    setFloorOptions([]);
    setDoorOptions([]);
    setStreetNumberQuery("");
    setValue("postalCode", "");
  };

  const handleStreetNumberQueryChanged = (value: string) => {
    setSelectedAddress((address) => ({
      ...address,
      streetNumber: "",
      letterExtra: "",
    }));
    setSelectedApartment(emptyApartment);
    setFloorOptions([]);
    setDoorOptions([]);
    setStreetNumberQuery(value);
    setValue("postalCode", "");
  };

  const getCoordinatesAndApartments = useCallback(
    async ({
      streetName,
      streetNumber,
      letterExtra,
      postalArea,
      postalCode,
    }: Partial<IAddressValidationModel>) => {
      const isReadyToFetchApartmentOptions = !!(
        streetName &&
        postalArea &&
        postalCode
      );

      if (!isReadyToFetchApartmentOptions) {
        return;
      }

      const fetchCoordinates = BRAND_NAME === "LMK" ? true : false;

      const { data } = await addressQuery({
        mode: QUERY_MODE.APARTMENT,
        streetName,
        streetNumber,
        letterExtra,
        postalArea,
        postalCode,
        fetchCoordinates,
      });

      if (!data) {
        return;
      }
      if (data.value.length !== 1) {
        return;
      }

      const newAddress = { ...emptyAddress, ...data.value[0] };
      setSelectedAddress(newAddress);

      const _apartmentOptions = newAddress.apartments || [];

      if (_apartmentOptions.length === 0) {
        setDoorOptions([]);
        setFloorOptions([]);
        return;
      }

      const doorOptions =
        _apartmentOptions.map((apartment) => ({
          label: apartment.name,
          value: apartment.door || "",
          data: apartment,
        })) || [];
      setDoorOptions(doorOptions);

      const floorOptions = _apartmentOptions
        .filter(
          (floor, index, self) =>
            index === self.findIndex((t) => t.floor === floor.floor),
        )
        .map((apartment) => ({
          label: apartment.floor || intl.UNKNOWN,
          value: apartment.floor,
          data: apartment,
        }));
      setFloorOptions(floorOptions);
    },
    [addressQuery, emptyAddress],
  );

  useEffect(() => {
    setValue("isHealthCheckSuccess", isHealthCheckSuccess);
  }, [isHealthCheckSuccess]);

  useEffect(() => {
    if (!initialAddress) {
      return;
    }
    if (!initialAddress.validated) {
      return;
    }
    if (!initialAddress.streetName) {
      return;
    }
    if (!initialAddress.streetNumber) {
      return;
    }
    if (initialAddress.streetNumber === null) {
      return;
    }
    const args = {
      streetName: initialAddress.streetName,
      streetNumber: initialAddress.streetNumber,
      letterExtra: initialAddress.letterExtra || "",
      postalCode: initialAddress.postalCode,
      postalArea: initialAddress.postalArea,
    };
    getCoordinatesAndApartments(args);
  }, []);

  useEffect(() => {
    if (selectedApartment.floor === "") {
      return;
    }
    const filteredDoorOptions = doorOptions.filter(
      (apartment) => apartment.data.floor === selectedApartment.floor,
    );
    setFilteredDoorOptions(filteredDoorOptions);
  }, [doorOptions, selectedApartment]); // filters the doors to match selected floor

  useEffect(() => {
    setValue("validatedAddress", selectedAddress);
  }, [selectedAddress, setValue]);

  useEffect(() => {
    setValue("apartment", selectedApartment);
  }, [selectedApartment, setValue]);

  if (isHealthCheckLoading) {
    return (
      <>
        <Skeleton
          animation="pulse"
          width="100%"
          height={10}
          className="col-span-7"
        />
        <Skeleton
          animation="pulse"
          width="100%"
          height={10}
          className="col-span-3"
        />
        <Skeleton
          animation="pulse"
          width="100%"
          height={10}
          className="col-span-full"
        />
        <Skeleton
          animation="pulse"
          width="100%"
          height={10}
          className="col-span-full"
        />
      </>
    );
  }

  if (!isHealthCheckSuccess) {
    return (
      <>
        <Input
          {...register("address")}
          label={intl.ADDRESS_LABEL}
          className="col-span-full"
        />
        <Input
          {...register("postalCode")}
          label={intl.POSTAL_CODE_LABEL}
          className="md:col-span-5 col-span-full"
        />
        <Input
          disabled
          value={city}
          label={intl.POSTAL_AREA_LABEL}
          className="col-span-full md:col-span-5"
        />
      </>
    );
  }

  return (
    <div className="grid grid-cols-10 gap-4 col-span-full" id="addressGrid">
      <StreetNameSelectInput
        locked={locked}
        className="order-1 col-span-full md:col-span-6 lg:col-span-7"
        selectedAddress={selectedAddress}
        queryText={streetNameQuery}
        mandatoryErrorText={intl.MANDATORY_FIELD}
        selectFromListErrorText={intl.SELECT_FROM_LIST}
        setNoAddressFound={setNoAddressFound}
        onSelected={(data: IAddressValidationModel) => {
          setSelectedAddress({
            ...emptyAddress,
            ...data,
          });
        }}
        onInputChanged={handleStreetNameQueryChanged}
        customRef={streetNameRef}
      />

      <AddressNotFoundDisclaimer
        className="order-2 md:order-3"
        id="addressNotFound"
        noAddressFound={noAddressFound}
      />
      <DoNotDeliverDisclaimer
        className="order-2 md:order-3"
        id="doNotDeliver"
        cannotDeliver={doNotDeliver || false}
      />

      <StreetNumberSelectInput
        locked={locked}
        autofocus={!initialAddress}
        className="order-3 md:order-2 col-span-full md:col-span-4 lg:col-span-3"
        selectedAddress={selectedAddress}
        queryText={streetNumberQuery}
        mandatoryErrorText={intl.MANDATORY_FIELD}
        selectFromListErrorText={intl.SELECT_FROM_LIST}
        disabled={!selectedAddress.streetName}
        onSelected={(data) => {
          handleStreetNumberQueryChanged("");
          const newAddress = {
            ...emptyAddress,
            ...data,
          };
          setSelectedAddress(newAddress);
          getCoordinatesAndApartments(newAddress);
          setValue("postalCode", newAddress.postalCode);
          setValue("streetAddress", newAddress.addressName);
        }}
        onInputChanged={handleStreetNumberQueryChanged}
        customRef={streetNumberRef}
        // Ensures that the StreetNumberSelectInput is re-rendered when the streetName changes
        key={`${selectedAddress.streetName}-${selectedAddress.postalArea}-number`}
      />

      {floorOptions.length > 0 && (
        <FloorNumberSelectInput
          autofocus={!initialAddress}
          className="order-4 col-span-full md:col-span-5"
          selectedApartment={selectedApartment}
          onSelected={(data: IApartmentModel) => {
            setSelectedApartment({
              floor: data.floor,
              door: "",
              name: "",
            });
            doorNumberRef.current?.focus();
          }}
          defaultOptions={floorOptions}
          customRef={floorNumberRef}
        />
      )}

      {doorOptions.length > 0 && (
        <DoorNumberSelectInput
          className="order-5 col-span-full md:col-span-5"
          selectedApartment={selectedApartment}
          onSelected={(data: IApartmentModel) => {
            setSelectedApartment({
              ...data,
            });
          }}
          defaultOptions={
            filteredDoorOptions.length > 0 ? filteredDoorOptions : doorOptions
          }
          customRef={doorNumberRef}
        />
      )}

      <Input
        disabled
        className="order-6 col-span-full"
        value={selectedAddress.postalArea}
        name="postalArea"
        label={intl.POSTAL_AREA_LABEL}
        {...(initialAddress && { IconRight: Locked })}
      />

      <Input
        disabled
        className="order-7 col-span-full"
        value={selectedAddress.postalCode}
        name="postalCode"
        id="postalCode"
        label={intl.POSTAL_CODE_LABEL}
        {...(initialAddress && { IconRight: Locked })}
      />

      <input type="hidden" {...register("validatedAddress")} />
      <input type="hidden" {...register("apartment")} />
    </div>
  );
};
