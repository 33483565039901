import {
  isPickAndMixProduct,
  isMealboxProduct,
  isFinancialProduct,
} from "@chef/helpers";
import { CalendarQuery } from "@chef/state-management";
import {
  getRecipeFromPickAndMix,
  getAttribute,
} from "@chef/state-management/helpers";
import { useCalendarData } from "@chef/state-management/hooks";
import { Meals } from "../shared/Meals";
import { useFinancesForCalendarWeek } from "./useFinancesForCalendarWeek";

interface CalendarWeekMealsProps {
  calendarWeek: CalendarQuery["calendar"][number];
  initiallyExpanded?: boolean;
}

export const CalendarWeekMeals = ({
  calendarWeek,
  initiallyExpanded,
}: CalendarWeekMealsProps) => {
  const { basket, pickAndMix } = useCalendarData({
    week: calendarWeek.week,
    year: calendarWeek.year,
  });

  const calendarBasket = calendarWeek.baskets.find(
    (basket) =>
      basket.year === calendarWeek.year && basket.week === calendarWeek.week,
  );

  const finances = useFinancesForCalendarWeek({ calendarWeek });

  if (!pickAndMix) {
    return null;
  }

  const pickAndMixProductsWithRecipes = basket
    .filter((p) => isPickAndMixProduct(p))
    .map((bp) => ({
      ...bp,
      recipe: getRecipeFromPickAndMix({
        pickAndMix,
        productId: bp.productId,
        variationId: bp.variationId,
      }),
    }));

  // We have to use the basket from calendar because the basket from useCalendarData does not have the full product info
  const basketFinancialOrMealbox = calendarBasket?.basketDetails.products.find(
    (p) =>
      isMealboxProduct(p.variation.product) ||
      isFinancialProduct(p.variation.product),
  );
  const portions = +(
    getAttribute(
      "Portions",
      basketFinancialOrMealbox?.variation || { attributes: [] },
    ) || 0
  );

  const extraPrice = pickAndMixProductsWithRecipes.reduce((acc, product) => {
    return acc + product.price;
  }, 0);

  return (
    <Meals
      dishes={pickAndMixProductsWithRecipes.map((product) => ({
        name: product.recipe?.recipeName,
      }))}
      // We will keep finances.financil to make the price logic compatible with the old one, but can be removed when we replace it with OneSub
      priceOfMeals={(finances.financial || finances.mealbox) + extraPrice}
      portions={portions}
      initiallyExpanded={initiallyExpanded}
    />
  );
};
