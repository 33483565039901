import { Controller, useFormContext } from "react-hook-form";
import clsx from "clsx";
import { useEffect } from "react";

import { ChatBox, Currency, RadioButtonBar } from "@chef/components";
import { ProductsByCategoriesQuery } from "@chef/state-management";
import {
  getAttribute,
  getMealsFromVariations,
  getPortionsFromVariations,
  getPriceOfProductByVariation,
} from "@chef/state-management/helpers";
import { Discount } from "@chef/icons/small";

import { intl } from "./PortionsMealsSelect.Intl";

interface PortionsMealsSelectProps {
  mealboxProduct:
    | ProductsByCategoriesQuery["productsByCategories"][number]["products"][number]
    | undefined;
  week: number;
  year: number;
  showPriceOverview?: boolean;
  couponAmount?: number;
}

export const PortionsMealsSelect = ({
  mealboxProduct,
  showPriceOverview = true,
  couponAmount = 0,
  week,
  year,
}: PortionsMealsSelectProps) => {
  const { control, watch, setValue } = useFormContext();

  const [selectedPortions, selectedMeals] = watch(["portions", "meals"]);

  const portionOptions = getPortionsFromVariations({
    variations: mealboxProduct?.variations,
  });

  const mealsOptions = getMealsFromVariations({
    variations: mealboxProduct?.variations,
    portions: selectedPortions,
  });

  useEffect(() => {
    if (!mealsOptions.includes(selectedMeals)) {
      setValue("meals", mealsOptions[0]);
    }
  }, [selectedMeals, mealsOptions, setValue]);

  if (!mealboxProduct) {
    return null;
  }

  const selectedVariation = mealboxProduct.variations.find(
    (v) =>
      getAttribute("Portions", v) === selectedPortions.toString() &&
      getAttribute("Meals", v) === selectedMeals.toString(),
  );

  const variationPrice =
    (selectedVariation &&
      getPriceOfProductByVariation({
        variation: selectedVariation,
        week,
        year,
      })) ||
    0;

  const totalAmountOfPortions = selectedPortions * selectedMeals;
  const discountAmount = variationPrice * (couponAmount / 100);
  const regularPricePerPortion = variationPrice / totalAmountOfPortions;
  const flexiblePriceWithDiscount = variationPrice - discountAmount;
  const discountPricePerPortion =
    flexiblePriceWithDiscount / totalAmountOfPortions;

  const isPriceDiscounted = discountAmount > 0;

  const finalPrice = selectedVariation?.finalPrice;

  return (
    <div className="lg:grid lg:grid-cols-2">
      <div className="flex flex-col gap-8 lg:pr-2">
        <div className="flex flex-col gap-4">
          <p className="text-sm">
            <strong>{intl.NUMBER_OF_PORTIONS}</strong>
          </p>
          <Controller
            control={control}
            name="portions"
            render={({ field }) => (
              <RadioButtonBar
                hasCheckmark
                animation="none"
                options={portionOptions.map((p) => ({
                  value: p,
                  name: `${p} ${field.value === p ? intl.PORTIONS : ""}`,
                }))}
                onChange={(value) => {
                  field.onChange(value);
                }}
                value={field.value}
                name="portions"
              />
            )}
          />
          <p className="text-xs">{intl.NUMBER_OF_PORTIONS_DESCRIPTION}</p>
        </div>
        <div className="flex flex-col gap-4">
          <p className="text-sm">
            <strong>{intl.NUMBER_OF_MEALS}</strong>
          </p>
          <Controller
            control={control}
            name="meals"
            render={({ field }) => (
              <RadioButtonBar
                animation="none"
                hasCheckmark
                options={mealsOptions.map((m) => ({
                  value: m,
                  name: `${m} ${field.value === m ? intl.MEALS : ""}`,
                }))}
                onChange={field.onChange}
                value={field.value}
                name="meals"
              />
            )}
          />
          <p className="text-xs">{intl.NUMBER_OF_MEALS_DESCRIPTION}</p>
        </div>
      </div>
      {showPriceOverview && (
        <div className="lg:pl-4 lg:pt-5 mb-4 lg:border-l-1.5 lg:border-grey-3">
          <ChatBox
            id="price-overview-card"
            className="flex !bg-grey-3 flex-col gap-1"
          >
            {isPriceDiscounted && (
              <Discount className="absolute text-white -top-2 -right-2 md:-top-3 md:-right-3 w-9 h-9 fill-error" />
            )}
            <p className="mb-1 text-sm text-left" id="price-overview-card">
              <strong>{intl.MEALKIT_SUBSCRIPTION}</strong>
            </p>
            <p className="text-sm text-left">
              {selectedMeals} {intl.MEALS} {intl.FOR} {selectedPortions}{" "}
              {intl.PEOPLE_PER_WEEK}
            </p>
            <p className="text-sm text-left">
              {totalAmountOfPortions} {intl.TOTAL_PORTIONS}
            </p>
            <hr className="my-2 text-grey-1" />
            <div className="flex justify-between text-sm">
              <p>{intl.REGULAR_PRICE}</p>
              <Currency>{selectedVariation?.finalPrice}</Currency>
            </div>
            <div className="flex justify-between text-sm">
              <p>{intl.PRICE_PER_PORTION}</p>
              <span className="flex gap-4">
                {isPriceDiscounted && (
                  <Currency className="line-through text-grey-1">
                    {regularPricePerPortion}
                  </Currency>
                )}
                <Currency
                  className={clsx(isPriceDiscounted && "text-discount")}
                >
                  {discountPricePerPortion}
                </Currency>
              </span>
            </div>
          </ChatBox>

          <div className="flex justify-between px-4 py-2 text-sm bg-grey-2">
            <p>
              <strong>{intl.TOTAL}</strong>
            </p>
            <span className="flex gap-4">
              {isPriceDiscounted && (
                <Currency className="line-through text-grey-1">
                  {finalPrice}
                </Currency>
              )}
              <Currency className={clsx(isPriceDiscounted && "text-discount")}>
                {flexiblePriceWithDiscount}
              </Currency>
            </span>
          </div>
          {!isPriceDiscounted && (
            <p className="pt-4 text-xs">
              {intl.TOTAL_PRICE} <strong>{intl.BEFORE_DISCOUNTS}</strong>{" "}
              {intl.DEDUCTED_YOU_CAN_SEE_DISCOUNTS_ON_ACCOUNT}
            </p>
          )}
        </div>
      )}
    </div>
  );
};
