import styled, { css } from "styled-components";

const AbsoluteBlock = styled.div`
  ${(props) => {
    if (props.absolutePos) {
      switch (props.absolutePos) {
        case "topLeft":
          return css`
            position: absolute;
            top: 0;
            left: 0;
          `;
        case "topRight":
          return css`
            position: absolute;
            top: 0;
            right: 0;
          `;
        case "bottomLeft":
          return css`
            position: absolute;
            bottom: 0;
            left: 0;
          `;
        case "bottomRight":
          return css`
            position: absolute;
            bottom: 0;
            right: 0;
          `;
        default:
          break;
      }
    }
    return "";
  }};
`;
export default AbsoluteBlock;
