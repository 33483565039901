import {
  formatDate,
  formatRealtimeEstimateDate,
  isMealboxProduct,
} from "@chef/helpers";
import { isNotEmptyArray } from "@chef/utils/array";
import { language } from "@chef/constants";

import { OrdersQuery } from "../graphql/generated";

export const getCampaignDetails = ({
  orders,
  dayFormat = "eee",
}: {
  orders: OrdersQuery["orders"] | undefined;
  dayFormat?: string;
}) => {
  if (!orders || !isNotEmptyArray(orders)) {
    return {
      isCampaignOrderPresent: false,
      campaignName: "",
      campaignDateTimeDeliveryString: "",
    };
  }

  const campaignOrder = orders[0];

  const campaignName =
    campaignOrder.orderLines
      .find((ol) => isMealboxProduct(ol.variation.product))
      ?.variation.product.name?.split("-")[0]
      .trim() || "";

  const deliveryDateAndTime = formatRealtimeEstimateDate({
    confirmedDeliveryTime: campaignOrder.realtimeEstimate.confirmedDeliveryTime,
    estimatedTimeFrom: campaignOrder.realtimeEstimate.estimatedTimeFrom,
    estimatedTimeTo: campaignOrder.realtimeEstimate.estimatedTimeTo,
    deliveryDate: campaignOrder.deliveryDate,
    showTimePrefix: true,
  });

  const campaignDeliveryDate = campaignOrder.deliveryDate || "";

  const campaignDateTimeDeliveryString = `${formatDate(
    new Date(campaignDeliveryDate),
    dayFormat,
  )} ${intl.TIME_DEFINITIVE_FORM} ${deliveryDateAndTime}
  `;

  return {
    isCampaignOrderPresent: true,
    campaignName,
    campaignDateTimeDeliveryString,
  };
};

const intl = (
  {
    no: {
      TIME_DEFINITIVE_FORM: "",
    },
    se: {
      TIME_DEFINITIVE_FORM: "den",
    },
    dk: {
      TIME_DEFINITIVE_FORM: "",
    },
  } as const
)[language];
