import styled, { css } from "styled-components";
import { media } from "../global-styles";

const Strong = styled.strong`
  font-weight: bold;

  /* Primary text color variant */
  ${(props) =>
    props.primary &&
    css`
      color: ${props.theme.colors.primary};
      border-color: ${props.theme.colors.primary};
    `};

  /* Light text color variant */
  ${(props) =>
    props.light &&
    css`
      color: ${props.theme.colors.light};
    `};

  /* Bigger variant */
  ${(props) =>
    props.stronger &&
    css`
      font-size: 20px;

      ${media.adult`
        font-size: 25px;
      `};
    `};

  /* Bigger variant */
  ${(props) =>
    props.bottomPadding &&
    css`
      padding-bottom: 10px;
    `};

  /* Block variant */
  ${(props) =>
    props.block &&
    css`
      display: block;
    `};
`;

export default Strong;
