import { useRouter } from "next/router";
import {
  useCreateOrUpdateMultipleSchedulersMutation,
  useUpdateBillingAgreementMutation,
} from "@chef/state-management";
import { Button, Card, Modal } from "@chef/components";
import { Delivery } from "@chef/icons/symbol";
import { ACCOUNT_STATUSES, BRAND_PATHS } from "@chef/constants";
import { isNotEmptyArray } from "@chef/utils/array";
import { brand, intl } from "./AccountReactivationModal.Intl";

interface AccountReactivationModalProps {
  show: boolean;
  onClose: () => void;
  handleConfirm: () => void;
  redirectUrl: string;
  schedulerUpdates?: {
    week: number;
    year: number;
    delivery: boolean;
  }[];
}

export const AccountReactivationModal = ({
  show,
  onClose,
  handleConfirm,
  redirectUrl,
  schedulerUpdates = [],
}: AccountReactivationModalProps) => {
  const router = useRouter();

  const [
    updateBillingAgreement,
    { isLoading: updateBillingAgreementIsLoading },
  ] = useUpdateBillingAgreementMutation();

  const [
    createOrUpdateMultipleSchedulers,
    { isLoading: createOrUpdateMultipleSchedulersisLoading },
  ] = useCreateOrUpdateMultipleSchedulersMutation();

  const isLoading =
    updateBillingAgreementIsLoading ||
    createOrUpdateMultipleSchedulersisLoading;

  const handleReactivate = async () => {
    if (isNotEmptyArray(schedulerUpdates)) {
      await Promise.all([
        updateBillingAgreement({
          status: ACCOUNT_STATUSES.ACTIVE,
        }).unwrap(),
        createOrUpdateMultipleSchedulers({
          schedulers: schedulerUpdates,
          _suppressNotifications: true,
        }).unwrap(),
      ]);
    } else {
      await updateBillingAgreement({
        status: ACCOUNT_STATUSES.ACTIVE,
      }).unwrap();
    }

    handleConfirm();

    router.push(redirectUrl);
  };

  return (
    <Modal
      show={show}
      onClose={onClose}
      showClose={false}
      outerClassName="h-full flex flex-col justify-center"
      innerClassName="px-4 max-w-2xl mx-auto"
    >
      <Card noPadding className="justify-center p-6 text-center md:p-20 ">
        <div className="flex flex-col items-center justify-center text-center xl:col-start-5 col-span-full xl:col-span-4">
          <div className="flex items-center justify-center w-20 h-20 p-2 mb-6 rounded-full  bg-[#F4F4F1]">
            <Delivery className="w-20 h-20" />
          </div>
        </div>
        <h2>{intl.WELCOME_BACK}</h2>
        <p className="mt-2 md:text-l">{brand.NICE_THAT_YOU_ARE_BACK}</p>

        <div className="mt-10">
          <Button
            loading={isLoading}
            onClick={handleReactivate}
            className="w-full md:w-72 md:mb-2"
            primary
          >
            {intl.RESUME_SUBSCRIPTION}
          </Button>
        </div>
        <div className="mt-2">
          <Button
            disabled={isLoading}
            onClick={onClose}
            className="w-full md:w-72"
            outlined
          >
            {intl.CANCEL}
          </Button>

          <div className="mt-8 text-sm mt- text-grey-1">
            <p>
              {brand.BY_RESUMING_I_ACCEPT_THE_CURRENT}
              <a className="underline" href={BRAND_PATHS.TOS_PATH}>
                <strong>{brand.TERMS_AND_CONDITIONS}</strong>
              </a>
            </p>
          </div>
        </div>
      </Card>
    </Modal>
  );
};
