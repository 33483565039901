import styled, { css } from "styled-components";
import { variables, media } from "../global-styles";

const checkMark = "/assets/icons/check-simple-green.svg";

const Ul = styled.ul`
  margin: 0;

  > li {
    list-style-type: none;
  }

  /* Vspaced variant */
  ${(props) =>
    props.vspaced &&
    css`
      > li {
        margin-top: 5px;
      }
    `};

  /* Small variant */
  ${(props) =>
    props.small &&
    css`
      font-size: ${props.theme.typography.fontSizeGlobalMin};
    `};

  /* Bulleted variant */
  ${(props) =>
    props.bulleted &&
    css`
      > li {
        list-style-type: disc;
      }
    `};

  /* Indented variant */
  ${(props) =>
    props.indented &&
    css`
      padding-left: ${variables.gutter1Mobile};

      ${media.teen`
        padding-left: ${variables.gutter1};
      `};
    `};

  /* Underline variant */
  ${(props) =>
    props.underLine &&
    css`
      > li {
        border-bottom: ${variables.borderWidth} solid
          ${props.theme.colors.lightBeige};
      }
    `};

  ${(props) =>
    props.check &&
    css`
      > li {
        position: relative;
        padding-left: 20px;
        margin-bottom: 10px;
        // Drop this
        // list-style-image: url(${checkMark});
        &::before {
          content: "";
          display: block;
          width: 13px;
          height: 9.5px;
          position: absolute;
          left: 0;
          top: 5px;
          background-image: url(${checkMark});
        }
      }
    `};
`;

export default Ul;
