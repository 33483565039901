import React from "react";
import clsx from "clsx";

import { PlusMinusIcon } from "@chef/components";
import { BillingQuery, PreferencesQuery } from "@chef/state-management";

type Preference =
  | BillingQuery["billing"]["preferences"][number]
  | PreferencesQuery["preferences"][number];

interface PreferencesListBoxProps {
  preferences: Preference[];
  type?: "concept" | "taste";
  className?: string;
}

export const PreferencesListBox = ({
  preferences,
  type = "concept",
  className,
}: PreferencesListBoxProps) => {
  const isConeptPreference = type === "concept";

  return (
    <div
      className={clsx(
        className,
        type === "concept"
          ? "bg-informationBG border-information"
          : "bg-errorBG border-error",
        "p-4 border-t-2 rounded-sm text-sm",
      )}
    >
      <ul className="list-inside">
        {preferences?.map((preference) => (
          <li key={preference.preferenceId}>
            <PlusMinusIcon
              className={clsx(
                isConeptPreference ? "text-information" : "text-error",
                "w-5 h-5",
              )}
              isPlus={isConeptPreference}
            />{" "}
            {preference.preferenceName}
          </li>
        ))}
      </ul>
    </div>
  );
};
