import { createClient, SanityClient } from "next-sanity";
import { dataset, projectId, readToken, apiVersion } from "../api";

export const getClient = (args?: { preview?: boolean }): SanityClient => {
  if (args?.preview) {
    return createClient({
      projectId,
      dataset,
      apiVersion,
      studioUrl: "/studio",
      token: readToken,
      useCdn: false,
      ignoreBrowserTokenWarning: true,
      encodeSourceMap: false,
      perspective: "previewDrafts",
    });
  }

  return createClient({
    projectId,
    dataset,
    apiVersion,
    useCdn: false,
    perspective: "published",
  });
};
