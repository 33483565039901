import clsx from "clsx";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/router";

import { Button, Card, Checkbox, Modal, Switch } from "@chef/components";
import { Setting } from "@chef/icons/large";
import {
  acceptAllConsents,
  hideDialog,
  openDialog,
  rejectAllConsents,
  selectTrackingConsents,
  selectTrackingDialogOpen,
  selectTrackingIsConfigured,
  setConsent,
} from "@chef/state-management/features/tracking/consents";

import {
  ICategories,
  useGetTrackingDestinationsQuery,
} from "@chef/state-management";

import { noop } from "@chef/utils/noop";
import { isNotEmptyArray } from "@chef/utils/array";
import { BRAND_PATHS } from "@chef/constants";

import { CATEGORIES } from "../categories";
import { intl } from "../utils";

const WRITE_KEY = process.env["NX_SEGMENT_WRITE_KEY"] as string;

interface ViewProps {
  consents: ICategories;
  onAcceptAll: () => void;
  onRejectAll?: () => void;
  onClose: () => void;
  onToggleCategory: (category: string) => void;
  onToggleView?: () => void;
}

interface CategoryProps {
  consents: ICategories;
  category: typeof CATEGORIES[number];
  onToggle: () => void;
}

const isEnabled = (consents: ICategories, category: string) => {
  if (category in consents) {
    return consents[category];
  }

  return consents.All;
};

const Category = ({ consents, category, onToggle }: CategoryProps) => {
  const { data: destinations } = useGetTrackingDestinationsQuery({
    writeKey: WRITE_KEY,
  });

  const categoryDestinations = destinations
    ?.filter((d) => category.types.includes(d.category))
    // Filter out custom Godtlevert destinations - these are custom
    // segment destinations that are essentially just aliases for
    // other destinations, and should not be informed of
    .filter(
      (d) => !(d.category === "Other" && d.name.includes("(Godtlevert)")),
    );

  return (
    <li key={category.key} className="mb-4 group">
      <div>
        <div className="flex items-end justify-between mb-2">
          <strong>{category.name}</strong>
          <Switch
            label={category.name}
            disabled={category.forced}
            checked={category.forced || isEnabled(consents, category.key)}
            onChange={onToggle}
            small
          />
        </div>

        <div className="mb-4">{category.description}</div>

        {isNotEmptyArray(categoryDestinations) && (
          <ul className="mb-4 text-sm list-disc list-inside">
            {categoryDestinations?.map((d) => (
              <li key={d.name}>{d.name}</li>
            ))}
          </ul>
        )}
      </div>

      <hr className="my-4 border-t-1.5 border-grey-3 group-last:hidden" />
    </li>
  );
};

const DetailedView = ({
  consents,
  onToggleCategory,
  onAcceptAll,
  onRejectAll,
  onClose,
}: ViewProps) => {
  return (
    <Card className="flex flex-col max-h-[calc(100vh-4rem)]" noPadding>
      <div className="h-full px-4 pt-6 overflow-auto">
        <h1 className="mb-4 text-xl">
          <strong>{intl.CONSENT_SETTINGS_TITLE}</strong>
        </h1>

        <p className="mb-4 text-sm">
          {intl.CONSENT_SETTINGS_DESCRIPTION}{" "}
          <strong>
            <a
              href={intl.CONSENT_SETTINGS_COOKIE_POLICY_LINK_URL}
              rel="noopener noreferrer"
              target="_blank"
              className="underline"
            >
              {intl.CONSENT_SETTINGS_COOKIE_POLICY_LINK_TEXT}
            </a>
          </strong>
        </p>

        <hr className="mb-4 border-t-2" />

        <div className="mb-4">
          <strong>{intl.CONSENT_SETTINGS_COOKIE_DECLARATION_TITLE}</strong>
        </div>

        <ul className="mb-4 text-sm">
          {CATEGORIES.map((category) => (
            <Category
              category={category}
              consents={consents}
              onToggle={() => onToggleCategory(category.key)}
            />
          ))}
        </ul>
      </div>

      <div className="flex flex-wrap items-stretch gap-2 px-4 py-3 shadow-fab md:gap-4">
        <Button outlined onClick={onRejectAll} className="grow">
          {intl.CONSENT_SETTINGS_REJECT_ALL}
        </Button>
        <div className="shrink md:grow" />
        <Button outlined onClick={onClose} className="grow">
          {intl.CONSENT_SETTINGS_SAVE_AND_CLOSE}
        </Button>
        <Button primary onClick={onAcceptAll} className="w-full md:w-min grow">
          {intl.CONSENT_SETTINGS_ACCEPT_ALL_COOKIES}
        </Button>
      </div>
    </Card>
  );
};

const SimpleView = ({
  consents,
  onToggleCategory,
  onAcceptAll,
  onToggleView,
  onClose,
}: ViewProps) => {
  return (
    <Card className="flex flex-col">
      <h1 className="mb-4 text-xl">
        <strong>{intl.CONSENT_SETTINGS_TITLE}</strong>
      </h1>

      <p className="mb-4 text-sm">
        {intl.CONSENT_SETTINGS_DESCRIPTION}{" "}
        <strong>
          <a
            href={intl.CONSENT_SETTINGS_COOKIE_POLICY_LINK_URL}
            rel="noopener noreferrer"
            target="_blank"
            className="underline"
          >
            {intl.CONSENT_SETTINGS_COOKIE_POLICY_LINK_TEXT}
          </a>
        </strong>
      </p>

      <ul className="mb-4">
        {CATEGORIES.map((category) => (
          <li key={category.key} className="flex mb-4">
            <Checkbox
              disabled={category.forced}
              checked={category.forced || isEnabled(consents, category.key)}
              onChange={() => onToggleCategory(category.key)}
            />
            <strong>{category.name}</strong>
          </li>
        ))}
      </ul>

      <div
        className="flex flex-col items-center gap-2 md:gap-4 md:flex-nowrap"
        id="cookies"
      >
        <Button primary onClick={onAcceptAll} full id="accept-all-cookies-btn">
          {intl.CONSENT_SETTINGS_ACCEPT_ALL_COOKIES}
        </Button>

        <Button
          outlined
          onClick={onClose}
          full
          className="mb-8"
          id="accept-selected-cookies-btn"
        >
          {intl.CONSENT_SETTINGS_ACCEPT_SELECTED_COOKIES}
        </Button>

        <button onClick={onToggleView}>
          <Setting />{" "}
          <span className="underline">
            {intl.CONSENT_SETTINGS_SHOW_DETAILS}
          </span>
        </button>
      </div>
    </Card>
  );
};

const ConsentSettings = () => {
  const { asPath } = useRouter();
  const dispatch = useDispatch();

  const consents = useSelector(selectTrackingConsents);
  const isShowing = useSelector(selectTrackingDialogOpen);
  const isConfigured = useSelector(selectTrackingIsConfigured);
  const [view, setView] = useState<"simple" | "detailed">("simple");

  useEffect(() => {
    if (isConfigured) {
      return;
    }

    if (asPath.includes(BRAND_PATHS.INFORMATION_COOKIES_PATH)) {
      return;
    }

    dispatch(openDialog());
  }, [dispatch, isConfigured, asPath]);

  const handleToggleCategory = (category: string) => {
    dispatch(
      setConsent({ name: category, enabled: !isEnabled(consents, category) }),
    );
  };

  const handleAcceptAll = () => {
    dispatch(acceptAllConsents());
  };

  const handleRejectAll = () => {
    dispatch(rejectAllConsents());
  };

  const handleClose = () => {
    dispatch(hideDialog());
    setTimeout(() => setView("simple"), 500);
  };

  const handleToggleView = () => {
    setView(view === "simple" ? "detailed" : "simple");
  };

  return (
    <Modal
      show={isShowing}
      onClose={() => noop()}
      showClose={false}
      outerClassName="py-6 px-4 print:hidden"
    >
      <div
        className={clsx(
          view === "simple" ? "max-w-md" : "max-w-2xl",
          "mx-auto",
        )}
      >
        {view === "simple" ? (
          <SimpleView
            consents={consents}
            onAcceptAll={handleAcceptAll}
            onToggleCategory={handleToggleCategory}
            onClose={handleClose}
            onToggleView={handleToggleView}
          />
        ) : (
          <DetailedView
            consents={consents}
            onAcceptAll={handleAcceptAll}
            onRejectAll={handleRejectAll}
            onClose={handleClose}
            onToggleCategory={handleToggleCategory}
          />
        )}
      </div>
    </Modal>
  );
};

export default ConsentSettings;
