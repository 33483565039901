import styled, { css } from "styled-components";
import fluidUnit from "../helpers/fluidUnit";

const Small = styled.span`
  display: inline-block;
  ${(props) =>
    fluidUnit({
      unit: "font-size",
      minUnit: props.theme.typography.fontSizeSmall,
      maxUnit: props.theme.typography.fontSizeSmall,
    })};
  font-weight: ${(props) => (props.strong ? 600 : 400)};
  text-transform: ${(props) => (props.uppercase ? "uppercase" : "inherit")};

  ${(props) =>
    props.light
      ? css`
          color: ${props.theme.colors.light};
        `
      : css`
          color: ${props.theme.colors.dark};
        `};

  /* Faded variant */
  ${(props) =>
    props.faded &&
    css`
      color: ${props.theme.colors.dark};
      opacity: 0.6;
    `};

  ${(props) =>
    props.tiny &&
    css`
      font-size: ${props.theme.typography.fontSizeTiny} !important;
    `};
`;

export default Small;
