// Our base pixel is 16px
// TODO: it might not be enough to say that our base is 16px, the user can change this and many do.
// We might have to look into taking the document fontsize in and setting the base from that.
// Atm, in chrome, the user can vary the base size from 9px to 24px, which wont play nice with this system.
// At a screenWidth of 840px, that will give us a em width of 93em (9px) to 35em (24px)...

// All sizes min-width
const breakpoints = {
  infant: 20,
  baby: 30,
  child: 37.5,
  teen: 48,
  adult: 67.5,
  elder: 83,

  // Change some up
  smallScreen: 23.438, // 375px bigger than iPhone 5
};

const customBreakpoints = {
  onlyBase: "(min-width: 0em) and (max-width: 20em)",
  onlyInfant: "(min-width: 20em) and (max-width: 30em)",
  onlyBaby: "(min-width: 30em) and (max-width: 37.5em)",
  onlyChild: "(min-width: 37.5em) and (max-width: 48em)",
  onlyTeen: "(min-width: 48em) and (max-width: 67.5em)",
  onlyAdult: "(min-width: 67.5em) and (max-width: 83em) )",
  onlyElder: "(min-width: 83em)",

  // Change things up
  onlySmallScreen: `(min-width: 0em) and (max-width: ${breakpoints.smallScreen}em)`,
};

export { breakpoints, customBreakpoints };
