import { useEffect, useState } from "react";

import { language, TIMEBLOCK_TYPE_IDS } from "@chef/constants";
import { isValidPostalCode } from "@chef/helpers";

import { useLazyTimeblocksByRangeQuery } from "..";

export const useCityForPostalCode = (
  postalCode?: string,
  timeblockTypeId?: typeof TIMEBLOCK_TYPE_IDS[keyof typeof TIMEBLOCK_TYPE_IDS],
  range?: number,
) => {
  const [getTimeblocksByRange] = useLazyTimeblocksByRangeQuery();

  const [state, setState] = useState<
    | { type: "no-city-found"; message: string }
    | { type: "success"; city: string; message: string }
    | { type: "loading"; message?: string }
    | { type: "invalid-postal-code"; message: string }
  >();

  const _TIMEBLOCK_TYPE_ID = timeblockTypeId
    ? timeblockTypeId
    : TIMEBLOCK_TYPE_IDS.NORMAL;
  const _range = range || 4;

  useEffect(() => {
    if (!isValidPostalCode(postalCode)) {
      setState({
        type: "invalid-postal-code",
        message: intl.INVALID_POSTAL_CODE,
      });

      return;
    }

    getTimeblocksByRange({
      postalCode: postalCode || "",
      timeblockTypeId: _TIMEBLOCK_TYPE_ID,
      range: _range,
      variationIds: [],
    })
      .unwrap()
      .then((data) => {
        const city = data.timeblocksByRange
          .find(() => true)
          ?.timeblocks.find(() => true)?.city;

        if (!city) {
          setState({
            type: "no-city-found",
            message: intl.POSTNUMBER_DOES_NOT_EXIST,
          });

          return;
        }

        setState({
          type: "success",
          city,
          message: intl.WE_DELIVER_TO_YOUR_CITY(city),
        });
      });
  }, [postalCode]);

  return state;
};

const intl = (
  {
    no: {
      INVALID_POSTAL_CODE: "Ugyldig postnummer",
      POSTNUMBER_DOES_NOT_EXIST: "Postnummeret finnes ikke",
      WE_DELIVER_TO_YOUR_CITY: (city: string) =>
        `Selvfølgelig leverer vi til ${city}`,
    },
    se: {
      INVALID_POSTAL_CODE: "Ogiltig postnummer",
      POSTNUMBER_DOES_NOT_EXIST: "Postnumret finns inte",
      WE_DELIVER_TO_YOUR_CITY: (city: string) =>
        `Självklart levererar vi till ${city}`,
    },
    dk: {
      INVALID_POSTAL_CODE: "Ugyldigt postnummer",
      POSTNUMBER_DOES_NOT_EXIST: "Postnummeret findes ikke",
      WE_DELIVER_TO_YOUR_CITY: (city: string) =>
        `Selvfølgelig leverer vi til ${city}`,
    },
  } as const
)[language];
