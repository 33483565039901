import styled from "styled-components";
import { variables } from "../../../global-styles";

const Tabs = styled.nav`
  display: flex;
  align-items: center;
  height: ${variables.headerHeightMobile};
  background-color: white;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: -10px;
    width: 100%;
    height: 10px;
    opacity: 1;
    will-change: opacity;
    background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.03) 100%);
    transition: opacity 0.3s ease;
  }
`;

export default Tabs;
