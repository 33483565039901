import Image from "next/image";
import { useDispatch, useSelector } from "react-redux";

import { findFeaturedPortraitImage } from "@chef/utils/helpers/recipes";
import { isEmptyArray } from "@chef/utils/array";
import { Card, ReadMoreWrapper, Skeleton } from "@chef/components";
import {
  RecipesByIdsQuery,
  resetPreselectedRecipesModal,
  selectPreselectedRecipesModal,
  useMeQuery,
  usePreferencesQuery,
  usePreselectorPreviewQuery,
  useRecipesByIdsQuery,
} from "@chef/state-management";
import { isEqualStrings } from "@chef/utils/equal";
import { getDishCount } from "@chef/constants";

import { Modal } from "../modal/Modal";
import { intl } from "./PreselectedRecipesModal.Intl";

interface PreselectedRecipeProps {
  recipe: RecipesByIdsQuery["recipesByIds"][number];
}

interface PreselectedRecipesModalProps {
  portions: number;
  week: number;
  year: number;
}

const PreselectedRecipe = ({ recipe }: PreselectedRecipeProps) => {
  return (
    <li className="flex items-center gap-3">
      <div className="relative w-15 h-15 shrink-0">
        <Image
          src={findFeaturedPortraitImage(recipe).url}
          fill
          alt={recipe.recipeName}
          className="object-cover rounded"
          sizes="60px"
        />
      </div>

      <div className="flex items-center text-sm text-left">
        {recipe.recipeName}
      </div>
    </li>
  );
};

const RecipesSkeleton = () => {
  return (
    <ul className="grid md:grid-cols-2 gap-x-6 gap-y-3">
      {[...Array(6)].map((_, i) => {
        return (
          <li className="flex items-center gap-3" key={i}>
            <Skeleton width={12} height={12} />
            <Skeleton width={80} height={12} />
          </li>
        );
      })}
    </ul>
  );
};

export const PreselectedRecipesModal = ({
  portions,
  week,
  year,
}: PreselectedRecipesModalProps) => {
  const dispatch = useDispatch();
  const { open, preferenceId } = useSelector(selectPreselectedRecipesModal);

  const { data: user } = useMeQuery();
  const isLoggedIn = !!user;

  const onClose = () => {
    dispatch(resetPreselectedRecipesModal());
  };

  const {
    data: preselectorPreviewQuery,
    isFetching: isPreselectorPreviewLoading,
  } = usePreselectorPreviewQuery(
    {
      week,
      year,
      attributeIds: preferenceId,
      portions,
    },
    {
      skip: !preferenceId,
    },
  );

  const recipeIds = preselectorPreviewQuery?.preselectorPreview.recipeIds;

  const { data: recipesByIdsQuery, isFetching: isRecipesByIdsLoading } =
    useRecipesByIdsQuery(
      { recipeIds: recipeIds ?? [] },
      { skip: !recipeIds || isEmptyArray(recipeIds) },
    );

  const recipes = recipesByIdsQuery?.recipesByIds;

  const isLoading = isPreselectorPreviewLoading || isRecipesByIdsLoading;

  const { data: preferencesQuery } = usePreferencesQuery();

  const selectedPreference = preferencesQuery?.preferences.find((preference) =>
    isEqualStrings(
      preference.preferenceId,
      preferenceId?.find((id) => id),
    ),
  );

  if (!selectedPreference || !recipes || isEmptyArray(recipeIds)) {
    return null;
  }

  const isRecipesLoading = isLoading || isEmptyArray(recipes);

  // const remainingDishCount =
  //   getDishCount({ week, year, portions }) - recipes.length;

  const dishCount = getDishCount({ week, year, portions });

  return (
    <Modal
      name="preselected-recipes-modal"
      open={open}
      onClose={onClose}
      size="md"
    >
      <Card className="w-full min-h-screen text-center md:min-h-fit">
        <div className="mx-4 my-8 md:mx-8">
          <h2 className="my-2 text-xl">
            <strong>{selectedPreference.preferenceName}</strong>
          </h2>

          <div>{selectedPreference.description}</div>

          <hr className="my-6 border-t-1.5 border-grey-1" />

          {isRecipesLoading ? (
            <RecipesSkeleton />
          ) : (
            <ReadMoreWrapper
              variant="minimal"
              height="long"
              disabled={recipes.length < 7}
            >
              <ul className="grid md:grid-cols-2 gap-x-6 gap-y-3">
                {recipes.map((recipe) => (
                  <PreselectedRecipe key={recipe.recipeId} recipe={recipe} />
                ))}
              </ul>
            </ReadMoreWrapper>
          )}

          <div className="mt-6 ">
            {isRecipesLoading ? (
              <Skeleton width={40} height={6} className="w-full mx-auto" />
            ) : (
              <h3 className="text-lg">
                <strong>{intl.CHOOSE_BETWEEN_N_DISHES(dishCount)}</strong>
                {/* NOTE: Can be re-implemented with preference-dish-filtering */}
                {/* <strong>{intl.MORE_DISHES(remainingDishCount)}</strong> */}
              </h3>
            )}

            <div className="mt-2">
              {isLoggedIn
                ? intl.ENJOY_OUR_FULL_RANGE_OF_DELICIOUS_MEALS_EACH_WEEK
                : intl.SIGN_UP_AND_ENJOY_OUR_FULL_RANGE_OF_DELICIOUS_MEALS_EACH_WEEK}
            </div>
          </div>
        </div>
      </Card>
    </Modal>
  );
};
