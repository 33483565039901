import { Ref } from "react";
import { IApartmentModel } from "@chef/state-management";
import { AsyncSelect } from "@chef/components";
import { intl } from "./DoorNumberSelectInput.Intl";
import { IInputOption } from "./AddressForm";

interface Props {
  selectedApartment: IApartmentModel;
  onSelected: (value: IApartmentModel) => void;
  customRef?: Ref<HTMLInputElement>;
  defaultOptions: IInputOption[];
  className?: string;
}

const DoorNumberSelectInput = ({
  selectedApartment,
  onSelected,
  customRef,
  defaultOptions,
  className,
}: Props) => {
  return (
    <AsyncSelect
      customRef={customRef}
      className={className}
      name="door-number"
      isSearchable={false}
      value={selectedApartment?.name}
      defaultLabel={selectedApartment?.name}
      defaultValue={selectedApartment?.name}
      options={defaultOptions}
      placeholder={intl.DOOR_LABEL}
      onChange={(input) => {
        const { data } = input as IInputOption;
        if (data) {
          onSelected(data as IApartmentModel);
        }
      }}
    />
  );
};

export default DoorNumberSelectInput;
