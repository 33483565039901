import React from "react";
import clsx from "clsx";

import { Container } from "../Container";
import { PropsOf } from "../../../types";

interface FloatingActionBarProps extends PropsOf<typeof Container> {
  className?: string;
  children: React.ReactNode;
  id?: string;
}

export const FloatingActionBar = ({
  children,
  className,
  id,
  ...props
}: FloatingActionBarProps) => {
  return (
    <Container
      as="footer"
      outerClassName={clsx(
        "fixed bottom-0 w-full py-5 bg-white shadow-fab z-20 h-20",
        className,
      )}
      grid
      hasPadding
      id={id}
      {...props}
    >
      {children}
    </Container>
  );
};
