import { forwardRef } from "react";
import clsx from "clsx";

import { Check } from "@chef/icons/small";

import SubText from "../../internals/SubText";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  Icon?: (props: React.SVGProps<SVGSVGElement>) => React.ReactElement | null;
  IconRight?: (
    props: React.SVGProps<SVGSVGElement>,
  ) => React.ReactElement | null;
  placeholder?: string;
  name?: string;
  value?: string;
  maxLength?: number;
  label?: string;
  error?: string;
  warning?: string;
  helperText?: string;
  valid?: boolean;
  showLengthCounter?: boolean;
  id?: string;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      Icon,
      IconRight,
      placeholder,
      disabled,
      value = "",
      onChange,
      name,
      type = "text",
      maxLength,
      label = "",
      error = "",
      warning = "",
      helperText = "",
      valid = false,
      className,
      showLengthCounter = true,
      id,
      ...props
    },
    ref,
  ) => {
    const hasValue = value !== "" && value !== undefined;

    return (
      <div className={className} id={id}>
        <div
          {...props}
          className={clsx(
            "flex items-center border-1.5 border-black rounded h-12 relative a11y-focus-within:focus-ring focus-within:!border-black",
            disabled ? "!border-grey-1 bg-background" : "bg-white",
            valid && "!border-information",
            error && "!border-error",
          )}
        >
          {Icon && <Icon className="ml-4" />}
          <input
            {...props}
            id={`${id}-input`}
            ref={ref}
            name={name}
            type={type}
            placeholder={placeholder || label}
            disabled={disabled}
            value={value}
            onChange={onChange}
            maxLength={maxLength && maxLength > 0 ? maxLength : undefined}
            className={clsx(
              "flex-grow w-full h-full pl-4 pr-12 focus:outline-none rounded",
              disabled ? "cursor-not-allowed bg-background" : "bg-transparent",
            )}
          />
          {valid && !Icon && !error && (
            <Check className="absolute right-4 text-information" />
          )}
          {IconRight && (
            <IconRight
              className={clsx("mr-4 min-w-min", disabled && "text-grey-1")}
            />
          )}
          {label && !Icon && (
            <label
              className={clsx(
                "pointer-events-none opacity-0 absolute px-2 left-2 transition-all ease-in-out top-2.5 duration-75",
                hasValue && "!opacity-100 -top-2.5 bg-white text-xs text-black",
              )}
              htmlFor={name}
            >
              {label}
            </label>
          )}
        </div>
        <SubText
          error={error}
          warning={warning}
          helperText={helperText}
          maxLength={maxLength}
          value={value}
          showLengthCounter={showLengthCounter}
          htmlFor={name}
        />
      </div>
    );
  },
);
