import styled, { css } from "styled-components";
import { variables } from "../global-styles";

const Hr = styled.hr`
  display: block;
  height: ${variables.borderWidth};
  margin: 0;
  background-color: ${(props) => props.theme.colors.lightBeige};
  border: 0;

  /* special color version */
  ${(props) =>
    props.special &&
    css`
      background-color: ${props.theme.colors.special};
    `};

  /* lightBeige color version */
  ${(props) =>
    props.weak &&
    css`
      background-color: ${props.theme.colors.lightBeige};
    `};

  /* slightly darkBeige color version */
  ${(props) =>
    props.dark &&
    css`
      background-color: ${props.theme.colors.darkBeige};
    `};

  /* slightly darkBeige color version */
  ${(props) =>
    props.short &&
    css`
      max-width: 50px;
      margin: 0 auto;
    `};

  /* sesam color version */
  ${(props) =>
    props.sesam &&
    css`
      background-color: ${props.theme.colors.sesam};
    `};

  /* half width */
  ${(props) =>
    props.half &&
    css`
      max-width: 50%;
      margin: 0 auto;
    `};

  /* fiber color version */
  ${(props) =>
    props.fiber &&
    css`
      background-color: ${props.theme.colors.fiber};
    `};

  ${(props) =>
    props.kull &&
    css`
      background-color: ${props.theme.colors.kull};
    `};

  ${(props) =>
    props.kostfiber &&
    css`
      background-color: ${props.theme.colors.kostfiber};
    `};

  ${(props) =>
    props.black &&
    css`
      background-color: ${props.theme.colors.dark};
    `};

  /* special color version */
  ${(props) =>
    props.thin &&
    css`
      height: 1px;
    `};
`;

export default Hr;
